import React, { Fragment } from 'react'
import { Translate } from 'react-localize-redux'
import { Field, reduxForm } from 'redux-form'

import formConfig from '@form-config'
import history from '@browser-history'
import { required, requiredSelect } from '@utils/validation'
import { sortByTitle } from '@utils/helper'

import { Select, Text, Form, Button, Steps, Card } from '@components'

const Step2Form = reduxForm({
	form: 'Form',
	destroyOnUnmount: false,
})(({ handleSubmit, submitting, error, ...props }) => {
	if (!props.activeLanguage || !props.activeLanguage.code) return null

	const industry = sortByTitle(
		formConfig.industry.options,
		props.activeLanguage.code
	)

	return (
		<Translate>
			{({ translate }) => (
				<Form
					onSubmit={handleSubmit}
					actions={
						<Fragment>
							<a
								className={'ButtonBack'}
								href="#a"
								onClick={(e) => {
									e.preventDefault()

									history.push(`/`)
									window.scrollTo(0, 0)
								}}
							>
								{translate('back')}
							</a>
							<Button
								label={translate('nextStep')}
								onClick={handleSubmit}
								disabled={submitting}
								type="button"
							/>
						</Fragment>
					}
					error={error}
				>
					<Steps label={translate('employerData')} count="2" />
					<Card>
						<Field
							component={Text}
							name={formConfig.company.id}
							label={formConfig.company.label}
							validate={
								!formConfig.company.optional ? [required] : null
							}
							optional={formConfig.company.optional}
							placeholder={formConfig.company.placeholder}
						/>
						<Field
							component={Select}
							name={formConfig.industry.id}
							label={formConfig.industry.label}
							validate={[requiredSelect]}
							options={industry}
						/>
						<Field
							component={Select}
							name={formConfig.companySize.id}
							label={formConfig.companySize.label}
							validate={[requiredSelect]}
							options={formConfig.companySize.options}
						/>
						<Field
							component={Select}
							name={formConfig.companyOrientation.id}
							label={formConfig.companyOrientation.label}
							validate={[requiredSelect]}
							options={formConfig.companyOrientation.options}
						/>
						<Field
							component={Select}
							name={formConfig.companyType.id}
							label={formConfig.companyType.label}
							validate={[requiredSelect]}
							options={formConfig.companyType.options}
						/>
					</Card>
				</Form>
			)}
		</Translate>
	)
})

export default Step2Form
