import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import salarayCalculation from './salary-calculation'
import currencies from './currencies'

export default combineReducers({
	salarayCalculation,
	currencies,
	form: formReducer,
})
