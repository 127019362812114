import React from 'react'
import { withLocalize } from 'react-localize-redux'
import './FormField.css'

const createRenderer = (render) =>
	withLocalize(({ activeLanguage, input, meta, label, ...rest }) => {
		if (!(activeLanguage && activeLanguage.code)) return null

		return (
			<div
				className={[
					'fieldGroup hideBorderOnFocus',
					meta.error && meta.touched && meta.submitFailed
						? ' hasError'
						: '',
					meta.active ? 'isActive' : '',
				].join(' ')}
			>
				{meta.error && meta.touched && meta.submitFailed && (
					<span className="formError">{meta.error}</span>
				)}
				{render(input, label[activeLanguage.code], rest)}
			</div>
		)
	})

export default createRenderer
