import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { Translate } from 'react-localize-redux'

import history from '@browser-history'
import formConfig from '@form-config'
import { debug } from '@config'
import ApiService from '@api'

import { required, requiredSelect } from '@utils/validation'
import { parseQueryString } from '@utils/helper'

import {
	Select,
	Text,
	RadioGroup,
	Form,
	Button,
	Card,
	Steps,
} from '@components'

let located = false
let debugLoc = false

const { search } = history.location

// Check debug param
if (search) {
	const params = parseQueryString(search)

	if (params['debug']) {
		debugLoc = true
	}
}

// Get users geolocation data
// If country is not Switzerland and known, set currency and `country_id`
const setLocaleData = ({ setCurrency, change: changeFormField }) => {
	if (located) return true

	located = true

	ApiService.ipGeolocation().then((data) => {
		if (data && data.country) {
			const c = formConfig.country.options.find(
				(country) => country.code === data.country
			)

			if (c) {
				setCurrency(data.currency)
				changeFormField('country_id', c.value)

				// ?
				if (data.country !== 'CH' && (debug || debugLoc)) {
					changeFormField('workplace', data.city)
				}
			}
		}
	})
}

const Step1Form = reduxForm({
	form: 'Form',
	destroyOnUnmount: false,
})(({ handleSubmit, submitting, error, ...rest }) => {
	setLocaleData(rest)

	return (
		<Translate>
			{({ translate }) => (
				<Form
					onSubmit={handleSubmit}
					actions={
						<Button
							label={translate('nextStep')}
							onClick={handleSubmit}
							disabled={submitting}
							type="button"
						/>
					}
					error={error}
				>
					{/* <h1 className="formHeadline">Fast geschafft!</h1>
                    <p className="formCopy">Für eine präzise Analyse deines Lohns benötigen wir grundlegende Informationen zu deinem aktuellen Job. Deine Daten werden sicher verschlüsselt übertragen.</p> */}
					<Steps label={translate('qualification')} count="1" />
					<Card>
						<Field
							component={Select}
							name={formConfig.careerLevel.id}
							label={formConfig.careerLevel.label}
							validate={[requiredSelect]}
							options={formConfig.careerLevel.options}
						/>
						<Field
							component={Select}
							name={formConfig.education.id}
							label={formConfig.education.label}
							validate={[requiredSelect]}
							tooltipText={formConfig.education.tooltipText}
							options={formConfig.education.options}
						/>
						<Field
							component={Text}
							name={formConfig.educationName.id}
							label={formConfig.educationName.label}
							validate={
								!formConfig.educationName.optional
									? [required]
									: null
							}
							optional={formConfig.educationName.optional}
							placeholder={formConfig.educationName.placeholder}
						/>
						<Field
							component={Text}
							name={formConfig.university.id}
							label={formConfig.university.label}
							validate={
								!formConfig.university.optional
									? [required]
									: null
							}
							optional={formConfig.university.optional}
							placeholder={formConfig.university.placeholder}
						/>
					</Card>
					<Card>
						<Field
							component={RadioGroup}
							name={formConfig.searchingForOther.id}
							label={formConfig.searchingForOther.label}
							validate={[required]}
							options={[
								{
									name: formConfig.searchingForOther.radios
										.radio1.id,
									value: formConfig.searchingForOther.radios
										.radio1.value,
									label: formConfig.searchingForOther.radios
										.radio1.label,
								},
								{
									name: formConfig.searchingForOther.radios
										.radio2.id,
									value: formConfig.searchingForOther.radios
										.radio2.value,
									label: formConfig.searchingForOther.radios
										.radio2.label,
								},
							]}
						/>
					</Card>
				</Form>
			)}
		</Translate>
	)
})

export default Step1Form
