import React, { Fragment, Component } from 'react'
import * as Sentry from '@sentry/browser'

class ErrorBoundary extends Component {
	componentDidCatch(err, info) {
		Sentry.captureException(err)
	}

	render() {
		return <Fragment>{this.props.children}</Fragment>
	}
}

export default ErrorBoundary
