import { create } from 'apisauce'

import {
	apiRoute,
	currenciesRoute,
	pingRoute,
	ipGeolocationRoute,
	searchRoute,
} from '@config'

const api = create({
	timeout: 90000,
})

const calculate = (data) =>
	api.post(apiRoute, data).then((response) => {
		if (response.problem) {
			if (response.problem === 'NETWORK_ERROR') {
				console.warn('Unhandled request without connection')
			} else {
				throw new Error(response?.data?.message)
			}
		}

		if (response.ok) {
			return response.data
		}

		return null
	})

const search = (lang, payload) =>
	api.post(searchRoute, { ...payload, lang }).then((response) => {
		if (response.problem) {
			if (response.problem === 'NETWORK_ERROR') {
				console.warn('Unhandled request without connection')
			} else {
				throw new Error(response?.data?.message)
			}
		}

		if (response.ok) {
			return response.data
		}

		return null
	})

const fetchCurrencies = () =>
	api.get(currenciesRoute).then((response) => {
		if (response.problem) {
			if (response.problem === 'NETWORK_ERROR') {
				console.warn('Unhandled request without connection')
			} else {
				throw new Error(response?.data?.message)
			}
		}

		if (response.ok) {
			return response.data
		}

		return null
	})

const ipGeolocation = () =>
	api.get(ipGeolocationRoute).then((response) => {
		if (response.ok) {
			return response.data
		}

		return null
	})

const ping = () =>
	api.get(pingRoute).then((response) => {
		if (response.problem) {
			if (response.problem === 'NETWORK_ERROR') {
				console.warn('Unhandled request without connection')
			} else {
				throw new Error(response?.data?.message)
			}
		}
	})

const ApiService = {
	calculate,
	fetchCurrencies,
	ipGeolocation,
	ping,
	search,
}

export default ApiService
