import React from 'react'
import './Form.css'

const Form = ({ actions, children, error, onSubmit, ...rest }) => (
	<form className="form" onSubmit={onSubmit} {...rest}>
		{children}
		<div className="formActions">{actions}</div>
		{error && <div className="formError">{error}</div>}
	</form>
)

export default Form
