import React, { Fragment, Component } from 'react'
import { withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import TagManager from 'react-gtm-module-custom-domain/dist/TagManager'

import history from '@browser-history'
import { setOriginCurrency } from '@redux/currencies'
import { handleFormError } from '@utils/form-error-handling'

import Step2Form from './Form'

export class Step2 extends Component {
	componentDidMount() {
		const {
			forms: { Form },
		} = this.props

		if (!Form) {
			history.replace(`/${history.location.search}`)
		}
	}

	handleSubmit = () => {
		const { setOriginCurrency } = this.props

		setOriginCurrency()

		history.push(`/eigene-daten${history.location.search}`)
		window.scrollTo(0, 0)
	}

	render() {
		const { currency } = this.props

		TagManager.initialize({
			gtmId: 'GTM-NDG8H5M',
			customURL: 'https://cc.lohncheck.ch/gtm.js',
		})

		return (
			<Fragment>
				<Step2Form
					onSubmit={this.handleSubmit}
					onSubmitFail={handleFormError}
					activeLanguage={this.props.activeLanguage}
					forms={this.props.forms.Form}
					currency={currency.toUpperCase()}
				/>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	forms: state.form,
	currency: state.currencies.currency,
})

const mapDispatchToProps = {
	setOriginCurrency,
}

export default withLocalize(connect(mapStateToProps, mapDispatchToProps)(Step2))
