import React from 'react'
import createRenderer from '@components/FormField/CheckFormField'
import './Check.css'

const Check = createRenderer((input, label, rest) => (
	<label className="FieldCheckLabel">
		<input
			className="FieldCheckInput"
			type="checkbox"
			checked={input.value}
			{...input}
		/>
		<span
			className="FieldCheckDescription"
			dangerouslySetInnerHTML={{ __html: label }}
		/>
	</label>
))

export default Check
