export const handleFormError = (errors) => {
	if (errors) {
		const key = Object.keys(errors).reduce((k, l) =>
			document
				.getElementsByName(k)[0]
				?.parentElement?.getBoundingClientRect()?.top <
			document
				.getElementsByName(l)[0]
				?.parentElement?.getBoundingClientRect()?.top
				? k
				: l
		)

		console.log(`[name="${key}"]`)

		const offsetTop =
			document
				.querySelector(`[name="${key}"]`)
				?.parentElement?.getBoundingClientRect()?.top +
				(window.pageYOffset || document.documentElement.scrollTop) ||
			100

		window.scrollTo({
			top: offsetTop - 100,
			left: 0,
			behavior: 'smooth',
		})

		// let key = Object.keys(errors).reduce((k, l) => (document.getElementById(k).offsetTop < document.getElementById(l).offsetTop) ? k : l);

		// window.scrollTo(0, document.getElementById(key).offsetTop - 100);
	}
}
