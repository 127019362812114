import React, { Component } from 'react'
import { Translate, withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import { Chart } from 'react-google-charts'
import accounting from 'accounting'
import TagManager from 'react-gtm-module-custom-domain/dist/TagManager'

import history from '@browser-history'
import { Card, Section } from '@components'

import {
	industries as industriesChartData,
	workplaces as workplacesChartData,
} from './chart-data'

import './Result.css'

let ratio = 1
const format = (value) =>
	parseInt(accounting.formatMoney(value, '', 0, '', '').toString().trim(), 10)

export class Result extends Component {
	state = {
		expandIndustries: false,
		expandCantons: false,
		expandCantonsWithAllowance: false,
	}

	componentDidMount() {
		const {
			forms: { Form },
		} = this.props

		if (!Form) {
			history.replace(`/${history.location.search}`)

			return
		}
	}

	renderOtherCantonRow = (workplace, index) =>
		this.state.expandCantons || index < 5 ? (
			<tr key={workplace[0]}>
				<td>{workplace[0]}</td>
				<td>
					<strong>
						{accounting.formatMoney(format(workplace[1] / ratio))}
					</strong>
					<small>
						<Translate id="perMonth" />
					</small>
				</td>
			</tr>
		) : null

	renderOtherCantonRowWithAllowance = (workplace, index) => {
		let totalChildAllowance = 0
		let totalBirthAllowance = 0

		workplace[2].forEach((wp) => {
			totalChildAllowance += wp.child_allowance
			totalBirthAllowance += wp.birth_allowance
		})

		totalChildAllowance = totalChildAllowance / ratio
		totalBirthAllowance =
			totalBirthAllowance > 0 ? totalBirthAllowance / ratio : 0

		return this.state.expandCantonsWithAllowance || index < 5 ? (
			<tr key={workplace[0]}>
				<td>{workplace[0]}</td>
				<td>
					<div>
						<strong>
							{totalBirthAllowance > 0 && (
								<span className="inlineSmallText">
									{' '}
									{accounting.formatMoney(
										totalBirthAllowance,
										''
									)}{' '}
									<Translate id="birthAllowance" /> +
								</span>
							)}
							{accounting.formatMoney(
								format(
									workplace[1] / ratio + totalChildAllowance
								)
							)}
						</strong>
					</div>
					<small>
						<Translate id="perMonth" />
					</small>
				</td>
			</tr>
		) : null
	}

	renderOtherIndustryRow = (industry, index) =>
		this.state.expandIndustries || index < 5 ? (
			<tr key={industry[0]}>
				<td>{industry[0]}</td>
				<td>
					<strong>
						{accounting.formatMoney(format(industry[1] / ratio))}
					</strong>
					<small>
						<Translate id="perMonth" />
					</small>
				</td>
			</tr>
		) : null

	renderJobRow = (job, index, target = '_blank') => (
		<tr key={index}>
			<td>
				<a
					href={job.link}
					target={target}
					// onClick={() => { window.ga('send', 'event', { eventCategory: 'jobs', eventAction: 'stellentitel', eventLabel: 'clicked'}) }}
				>
					{job.title}
					<br />
					<span className="textMuted">{job.company}</span>
				</a>
			</td>
		</tr>
	)

	generateWorkplacesData = (data, language) => {
		const d = []

		data.forEach((item) => {
			const workplace = workplacesChartData.find(
				(option) => option.value === item[0]
			).title[language]

			d.push([workplace, item[1], item[2]])
		})

		return d
	}

	generateIndustriesData = (data, language) => {
		const d = []

		data.forEach((item) => {
			const workplace = industriesChartData.find(
				(option) => option.value === item[0]
			).title[language]

			d.push([workplace, item[1], item[2]])
		})

		return d
	}

	generateYearlyData = (data, language) => {
		const d = []

		data.forEach((item) => {
			d.push([
				item[0],
				item[1] / ratio,
				accounting.formatMoney(format(item[1] / ratio)),
				item[2],
			])
		})

		return d
	}

	generateAgeData = (data, language) => {
		const d = []

		data.forEach((item) => {
			d.push([
				item[0],
				item[1] / ratio,
				accounting.formatMoney(format(item[1] / ratio)),
				null,
				null,
				null,
				null,
			])
		})

		return d
	}

	generateGeochartWorkplacesData = (data, language) => {
		const d = []

		data.forEach((item) => {
			const workplace = workplacesChartData.find(
				(option) => option.value === item[0]
			)
			const workplaceGerman = workplace.title['de']
			const workplaceTranslated = workplace.title[language]

			d.push([
				workplaceGerman,
				item[1] / ratio,
				workplaceTranslated +
					' ' +
					accounting.formatMoney(format(item[1] / ratio)),
			])
		})

		return d
	}

	render() {
		const {
			currencies,
			data,
			forms: { Form },
			activeLanguage,
		} = this.props

		TagManager.initialize({
			gtmId: 'GTM-NDG8H5M',
			customURL: 'https://cc.lohncheck.ch/gtm.js',
		})

		if (!Form || !data) return null
		if (!(activeLanguage && activeLanguage.code)) return null

		accounting.settings = {
			currency: {
				symbol: currencies.currency.toUpperCase(),
				format: '%v %s',
				decimal: ', ',
				thousand: '.',
				precision: 0,
			},
			number: {
				precision: 2,
				thousand: '.',
				decimal: ', ',
			},
		}

		const { values } = Form
		const d = Object.values(currencies.data)

		let usersMonthlySalaryInCHF = +values.monthly_salary

		// Convert origin to CHF
		if (currencies.originCurrency !== 'chf') {
			const r = d.find(
				(c) => c.currency === currencies.originCurrency.toUpperCase()
			).ratio

			usersMonthlySalaryInCHF = +values.monthly_salary * r
		}

		if (currencies.currency !== 'chf') {
			ratio = d.find(
				(c) => c.currency === currencies.currency.toUpperCase()
			).ratio
		} else {
			ratio = 1
		}

		const usersMonthlySalary = usersMonthlySalaryInCHF / ratio
		const othersMonthlySalary = data.average / ratio

		const income = accounting.formatMoney(usersMonthlySalary)
		const average = accounting.formatMoney(othersMonthlySalary)

		let othersPercentage = null
		let usersPercentage = null
		let isLess = null

		if (usersMonthlySalary > othersMonthlySalary) {
			usersPercentage = Math.round(
				((usersMonthlySalary * 12) / (othersMonthlySalary * 12) - 1) *
					100
			)
			othersPercentage = 100 - usersPercentage
			isLess = false
		} else {
			usersPercentage = Math.round(
				(1 - (usersMonthlySalary * 12) / (othersMonthlySalary * 12)) *
					100
			)
			othersPercentage = 100
			isLess = true
		}

		// const mailSubject = 'Soviel könnte ich mehr verdienen'
		// const mailText = `Hier folgt Text`

		const industries = this.generateIndustriesData(
			data.industries,
			activeLanguage.code
		)

		const workplaces = this.generateWorkplacesData(
			data.workplaces,
			activeLanguage.code
		)
		const yearly = this.generateYearlyData(data.yearly, activeLanguage.code)
		// const age = this.generateAgeData(data.age, activeLanguage.code);
		const geochartWorkplaces = this.generateGeochartWorkplacesData(
			data.workplaces,
			activeLanguage.code
		)
		const { expandIndustries, expandCantons, expandCantonsWithAllowance } =
			this.state

		const cantonWithHighestSalary = [...data.workplaces].sort(
			(a, b) => b[1] - a[1]
		)[0]
		const highestSalary = cantonWithHighestSalary[1] / ratio
		const cantonWithHighestSalaryName = workplacesChartData.find(
			(option) => option.value === cantonWithHighestSalary[0]
		).title[activeLanguage.code]
		const userCantonDifference = highestSalary - usersMonthlySalary

		const hasFamilyAllowance = !!data.workplace_1?.children

		return (
			<Translate>
				{({ translate }) => (
					<article className="result">
						{/* {JSON.stringify(currencies)} */}
						<Section>
							<Card highlight>
								<h2 className="Large">
									<Translate
										id="salaryComparison"
										data={{
											percentage: usersPercentage,
											growth: isLess
												? translate('less')
												: translate('more'),
										}}
										options={{ renderInnerHtml: true }}
									/>
								</h2>
								<div className="barChart">
									<div className="barChartHeader">
										<div className="barChartLabel">
											<Translate id="yourSalary" />
										</div>
										<div className="barChartValue">
											{income}
										</div>
									</div>
									<div className="barChartHandleBackground">
										<div
											className="barChartHandle"
											style={{
												width: `${
													isLess
														? 100 - usersPercentage
														: 100
												}%`,
											}}
										></div>
									</div>
								</div>
								<div className="barChart">
									<div className="barChartHeader">
										<div className="barChartLabel">
											<Translate id="competitorsSalary" />
										</div>
										<div className="barChartValueHighlight">
											{average}
										</div>
									</div>
									<div className="barChartHandleBackground">
										<div
											className="barChartHandleHighlight"
											style={{
												width: `${othersPercentage}%`,
											}}
										></div>
									</div>
								</div>
							</Card>
						</Section>
						<Section>
							<h2>
								<Translate id="salaryOtherIndustry" />
							</h2>
							<Card>
								<table className="resultTable">
									<tbody>
										{industries.map(
											this.renderOtherIndustryRow
										)}
									</tbody>
								</table>
							</Card>
							<div className="toggleButtonWrapper">
								<a
									href="#a"
									className="toggleButton"
									onClick={(e) => {
										e.preventDefault()

										this.setState({
											expandIndustries: !expandIndustries,
										})
									}}
								>
									{expandIndustries ? (
										<Translate id="collapseIndustries" />
									) : (
										<Translate id="expandIndustries" />
									)}
								</a>
							</div>
						</Section>
						<Section>
							<h2>
								<Translate id="salaryPerYear" />
							</h2>
							{/* <ul>
                                <li>Der Durchnschnitt verdient {accounting.formatMoney(data.default.Lohn_50)}</li>
                                <li>Die hälfte aller (Funktion) verdienen zwischen {accounting.formatMoney(data.default.Lohn_25 * 12, { symbol: '',  format: '%v' })} und {accounting.formatMoney(data.default.Lohn_75 * 12)}</li>
                                <li>80% aller (Funktion) verdienen zwischen {accounting.formatMoney(data.default.Lohn_10 * 12, { symbol: '',  format: '%v' })} und {accounting.formatMoney(data.default.Lohn_90 * 12)}</li>
                            </ul> */}
							<Chart
								chartType="AreaChart"
								width="100%"
								height="320px"
								columns={[
									{
										type: 'number',
										label: 'Perzentil',
									},
									{
										type: 'number',
										label: 'Vergütung',
									},
									{
										type: 'string',
										role: 'tooltip',
									},
									{
										type: 'string',
										role: 'style',
									},
								]}
								rows={yearly}
								options={{
									...chartOptions,
									hAxis: {
										...chartOptions.hAxis,
										title: translate('percentile'),
										baseline: 0,
										baselineColor: '#F1F3F5',
										viewWindow: {
											min: 10,
											max: 90,
										},
										ticks: [10, 25, 50, 75, 90],
										// format: 'percent',
									},
									vAxis: {
										...chartOptions.vAxis,
										title:
											translate('salary') +
											` ${currencies.currency.toUpperCase()}`,
									},
								}}
							/>
						</Section>
						{/* <Section>
                            <h2><Translate id="salaryAge" /></h2>
                            <Chart
                                chartType="AreaChart"
                                width="100%"
                                height="320px"
                                columns={[
                                    {
                                        type: 'number',
                                        label: 'Alter',
                                    },
                                    {
                                        type: 'number',
                                        label: 'monatlicher Lohn',
                                    },
                                    {
                                        type: 'string',
                                        role: 'tooltip',
                                    },
                                    {
                                        type: 'number',
                                        label: 'Alter',
                                    },
                                    {
                                        type: 'number',
                                        label: 'monatlicher Lohn',
                                    },
                                    {
                                        type: 'string',
                                        role: 'style',
                                    },
                                    {
                                        type: 'string',
                                        role: 'tooltip',
                                        'p': {
                                            'html': true
                                        },
                                    },
                                ]}
                                rows={[
                                    ...age,
                    				[
                                        (new Date()).getFullYear() - this.props.forms.Form.values.year_of_birth,
                                        null,
                                        null,
                                        null,
                                        format(usersMonthlySalary),
                                        'point { color: #14bf58 }',
                                        `<ul class="google-visualization-tooltip-item-list">
                                            <li class="google-visualization-tooltip-item" style="">
                                                ${translate('yourSalary')}:<br /><b>${accounting.formatMoney(format(usersMonthlySalary))}</b>
                                            </li>
                                        </ul>`
                                    ]
                                ]}
                                options={{
                                    ...chartOptions,
                                    hAxis: {
                                        ...chartOptions.hAxis,
                                        title: translate('age'),
                                    },
                                    vAxis: {
                                        ...chartOptions.vAxis,
                                        title: translate('salary') + ` ${currencies.currency.toUpperCase()}`,
                                    },
                                    tooltip: {
                                        isHtml: true,
                                        textStyle: {
                                            color: '#343A40',
                                            fontSize: 14,
                                            fontName: 'Rubik',
                                            fontWeight: '400',
                                        },
                                    },
                                }}
                            />
                        </Section> */}
						<Section>
							{userCantonDifference > 1 ? (
								<h2>
									<Translate
										id="cantonUserComparison"
										data={{
											win: accounting.formatMoney(
												format(userCantonDifference)
											),
											canton: cantonWithHighestSalaryName,
										}}
									/>
								</h2>
							) : (
								<h2>
									<Translate id="cantonHeadline" />
								</h2>
							)}
							<Chart
								chartEvents={[
									{
										eventName: 'select',
										callback: ({ chartWrapper }) => {
											const chart =
												chartWrapper.getChart()
											const selection =
												chart.getSelection()

											if (selection.length === 0) return

											// const region = data[selection[0].row + 1];
											// console.log("Selected: " + region);
										},
									},
								]}
								chartType="GeoChart"
								width="100%"
								height="100%"
								columns={[
									{
										type: 'string',
										label: 'Kanton',
									},
									{
										type: 'number',
										label: 'monatlicher Lohn',
									},
									{
										type: 'string',
										role: 'tooltip',
									},
								]}
								rows={geochartWorkplaces}
								options={{
									...mapsOptions,
								}}
							/>
						</Section>
						<Section>
							{hasFamilyAllowance && (
								<h3>
									<Translate
										id="withoutAllowanceHeadline"
										options={{ renderInnerHtml: true }}
									/>
								</h3>
							)}
							<Card>
								<table className="resultTable">
									<tbody>
										{workplaces.map(
											this.renderOtherCantonRow
										)}
									</tbody>
								</table>
							</Card>
							<div className="toggleButtonWrapper">
								<a
									href="#a"
									className="toggleButton"
									onClick={(e) => {
										e.preventDefault()

										this.setState({
											expandCantons: !expandCantons,
										})
									}}
								>
									{expandCantons ? (
										<Translate id="collapseCantons" />
									) : (
										<Translate id="expandCantons" />
									)}
								</a>
							</div>
						</Section>
						{hasFamilyAllowance && (
							<Section>
								<h3>
									<Translate
										id="withAllowanceHeadline"
										options={{ renderInnerHtml: true }}
									/>
								</h3>
								<Card>
									<table className="resultTable">
										<tbody>
											{workplaces.map(
												this
													.renderOtherCantonRowWithAllowance
											)}
										</tbody>
									</table>
								</Card>
								<div className="toggleButtonWrapper">
									<a
										href="#a"
										className="toggleButton"
										onClick={(e) => {
											e.preventDefault()

											this.setState({
												expandCantonsWithAllowance:
													!expandCantonsWithAllowance,
											})
										}}
									>
										{expandCantons ? (
											<Translate id="collapseCantons" />
										) : (
											<Translate id="expandCantons" />
										)}
									</a>
								</div>
							</Section>
						)}

						<Section>
							<h2>
								<Translate id="jobSuggestions" />
							</h2>
							<Card>
								<table className="resultTable">
									<tbody>
										{data.jobs.map((job, index) =>
											this.renderJobRow(job, index)
										)}
									</tbody>
								</table>
							</Card>
						</Section>
						{/* <a href={`mailto:?subject=${mailSubject}&body=${mailText}`}>Ergebnis per E-Mail teilen</a> */}
					</article>
				)}
			</Translate>
		)
	}
}

const chartOptions = {
	colors: ['#228AE6'],
	curveType: 'function',
	backgroundColor: 'transparent',
	width: '100%',
	chartArea: {
		width: '75%',
		right: 12,
		top: 12,
	},
	hAxis: {
		gridlines: {
			color: '#F1F3F5',
		},
		titleTextStyle: {
			color: '#ADB5BD',
			fontSize: 14,
			fontName: 'Rubik',
			italic: false,
		},
		textStyle: {
			color: '#ADB5BD',
			fontSize: 14,
			fontName: 'Rubik',
		},
	},
	vAxis: {
		gridlines: {
			color: 'none',
		},
		baselineColor: 'transparent',
		titleTextStyle: {
			color: '#ADB5BD',
			fontSize: 14,
			fontName: 'Rubik',
			italic: false,
		},
		textStyle: {
			color: '#ADB5BD',
			fontSize: 14,
			fontName: 'Rubik',
		},
	},
	tooltip: {
		isHtml: true,
		textStyle: {
			color: '#343A40',
			fontSize: 14,
			fontName: 'Rubik',
		},
	},
	pointSize: 12,
	strokeWidth: 4,
	legend: 'none',
	series: {
		0: {
			color: '228AE6',
		},
	},
}

const mapsOptions = {
	region: 'CH',
	displayMode: 'regions',
	resolution: 'provinces',
	datalessRegionColor: 'transparent',
	backgroundColor: 'transparent',
	colorAxis: {
		colors: ['#e7f5ff', '#0083FF'],
	},
	legend: {
		textStyle: {
			color: '#ADB5BD',
			fontSize: 14,
			fontName: 'Rubik',
		},
	},
	tooltip: {
		isHtml: true,
		showTitle: false,
		textStyle: {
			color: '#343A40',
			fontSize: 14,
			fontName: 'Rubik',
		},
	},
}

const mapStateToProps = (state) => ({
	forms: state.form,
	data: state.salarayCalculation.data,
	currencies: state.currencies,
})

export default withLocalize(connect(mapStateToProps, null)(Result))
