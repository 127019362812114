import React, { Fragment } from 'react'
import createRenderer from '@components/FormField'
import './Number.css'

const Number = createRenderer((input, label, language, rest) => (
	<Fragment>
		{rest.groupText ? (
			<div className="FieldNumberGroup">
				<input
					className="fieldNumber"
					id={input.name}
					type="number"
					placeholder={rest.placeholder[language]}
					{...input}
				/>
				<span className="fieldGroupText">{rest.groupText}</span>
			</div>
		) : (
			<input
				className="fieldNumber"
				type="number"
				placeholder={rest.placeholder[language]}
				{...input}
			/>
		)}
	</Fragment>
))

export default Number
