import React from 'react'
import './FormLabel.css'

const FormLabel = ({ htmlFor, children, className }) => (
	<label className="formLabel" htmlFor={htmlFor}>
		{children}
	</label>
)

export default FormLabel
