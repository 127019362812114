import React, { Component } from 'react'
import styles from './Dropdown.module.css'

class Dropdown extends Component {
	constructor(props) {
		super(props)
		this.dropdownMenu = React.createRef()

		this.state = {
			showDropdown: false,
		}
	}

	toggleDropdown = (event) => {
		event.preventDefault()

		if (this.state.showDropdown) {
			this.closeDropdown()
		} else {
			this.setState({ showDropdown: true }, () => {
				setTimeout(() => {
					document.addEventListener('click', this.closeDropdown)
				}, 100)
			})
		}
	}

	closeDropdown = (event = false) => {
		if (
			!event ||
			(this.dropdownMenu.current &&
				!this.dropdownMenu.current.contains(event.target))
		) {
			this.setState({ showDropdown: false }, () => {
				document.removeEventListener('click', this.closeDropdown)
			})
		}
	}

	renderItems = () => {
		const { onClick, items } = this.props

		return items.map((item) => (
			<li className={styles.dropdownItem} key={item.key}>
				<button
					className={styles.dropdownListButton}
					onClick={() => {
						onClick(item.value)

						this.closeDropdown()
					}}
				>
					{item.img && (
						<img
							className={styles.dropdownItemImage}
							alt={item.key}
							src={item.img}
						/>
					)}
					<span className={styles.dropdownItemLabel}>{item.key}</span>
				</button>
			</li>
		))
	}

	render() {
		const { activeKey } = this.props

		return (
			<div className={styles.dropdown}>
				<button
					className={styles.dropdownButton}
					onClick={this.toggleDropdown}
				>
					{activeKey.img ? (
						<img
							className={styles.dropdownItemImage}
							alt={activeKey.key}
							src={activeKey.img}
						/>
					) : (
						<span className={styles.dropdownLabel}>
							{activeKey.key}
						</span>
					)}
				</button>
				{this.state.showDropdown ? (
					<ul className={styles.dropdownList} ref={this.dropdownMenu}>
						{this.renderItems()}
					</ul>
				) : null}
			</div>
		)
	}
}

export default Dropdown
