import React from 'react'
import Modal from 'react-modal'
import { Button } from '@components'
import { Translate } from 'react-localize-redux'

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		// marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		maxHeight: '80vh',
		overflowY: 'auto',
		display: 'grid',
		width: '600px',
		maxWidth: '90%',
	},
}

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#yourAppElement');

function ModalComponent({ isOpen, onConfirm, onCancel }) {
	function afterOpenModal() {
		// references are now sync'd and can be accessed.
		// subtitle.style.color = '#f00'
	}

	return (
		<Translate>
			{({ translate }) => (
				<div>
					<Modal
						isOpen={isOpen}
						onAfterOpen={afterOpenModal}
						onRequestClose={onCancel}
						style={customStyles}
						contentLabel="Example Modal"
						ariaHideApp={false}
					>
						<button style={{ position: 'absolute', right: '10px', top: '10px' }} onClick={onCancel}>x
						</button>
						<h2>{translate('consentModalHeadline')}</h2>

						<div>
							<p>
								Hiermit willige ich ein, dass die Matto-Group meine zuvor gemachten Angaben in
								pseudonymisierter Form an Werbetechnologie Firmen wie Decentric,
								The Tradedesk, oder Google übermitteln kann, damit wir bzw. Werbetechnologie Firmen wie
								Decentric,
								The Tradedesk, OneID, EUID, Amazon, Facebook oder Google Ihnen als Teil einer bestimmten
								Zielgruppe
								(z. B. Berufs-, Einkommens- und/oder Altersgruppe) personalisierte Angebote online,
								d. h. auf Partner-Webseiten anzeigen können. Ihre Einwilligung können Sie jederzeit mit
								Wirkung für die Zukunft widerrufen (siehe Ziff. 3 der&nbsp;
								<a href='https://lohncheck.ch/de/datenschutz'
								   target='_blank' rel='noopener noreferrer'>Datenschutzerklärung</a>).
							</p>
							<p>
								«Pseudonymisiert» bedeutet, dass keine Rückschlüsse auf Ihre Person möglich sind,
								sofern Sie bei Google, einem Publisher wie der TX, Ringier, oder Audienzz – z. B.
								aufgrund
								eines Logins – nicht bereits bekannt sind. Matto-Group bearbeitet Ihre Daten in
								gemeinsamer bzw. eigener Verantwortlichkeit und nach Massgabe ihrer eigenen
								Datenschutzbestimmungen.
							</p>
							<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
								<Button label={translate('decline')} type='button'
										onClick={onCancel} />
								<Button style={{ marginLeft: '10px' }} label={translate('agree')} type='button'
										onClick={onConfirm} />
							</div>
						</div>
					</Modal>
				</div>
			)}
		</Translate>
	)
}

export default ModalComponent
