import React from 'react'
import { Translate } from 'react-localize-redux'
import MarketingFr from "./FR/MarketingFr"
import MarketingEn from "./EN/MarketingEn"
import MarketingDe from "./DE/MarketingDe"
import TagManager from 'react-gtm-module-custom-domain/dist/TagManager'

TagManager.initialize({
	gtmId: 'GTM-NDG8H5M',
	customURL: 'https://cc.lohncheck.ch/gtm.js',
})

const Marketing = () => (
	<Translate>
		{({activeLanguage}) =>
			activeLanguage?.code === 'en' ? <MarketingEn/> : activeLanguage?.code === 'fr' ? <MarketingFr/> : <MarketingDe/>
		}
	</Translate>
)

export default Marketing
