export const sortByTitle = (options, language) => {
	return options.sort(function (a, b) {
		const titleA = a.title[language].toLowerCase(),
			titleB = b.title[language].toLowerCase()

		return titleA.localeCompare(titleB)
	})
}

export const parseQueryString = (search) => {
	let objURL = {}

	search.replace(
		new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
		function ($0, $1, $2, $3) {
			objURL[$1] = $3
		}
	)

	return objURL
}
