const defaultVersion = 1
const calculateVersion = 2

export const debug = 0

const baseUrl =
	process.env.REACT_APP_STAGE === 'dev'
		? 'http://localhost'
		: process.env.REACT_APP_STAGE === 'staging'
		? 'https://dev.lohncheck.ch'
		: 'https://lohncheck.ch'

export const websiteRoute = baseUrl
export const apiRoute = `${baseUrl}/api/v${calculateVersion}/wage/calculate`
export const searchRoute = `${baseUrl}/api/v${defaultVersion}/search`
export const pingRoute = `${baseUrl}/api/v${defaultVersion}/wage/ping`
export const currenciesRoute = `${baseUrl}/api/v${defaultVersion}/currencies`
export const ipGeolocationRoute = 'https://ipapi.co/json/'
