import React from "react"

export default function MarketingFr() {
  return <>
    <h1>Déclaration de protection des données de Matto-Group AG pour la mise en œuvre du chèque de salaire et le
      marketing direct qui en découle
    </h1>
    <p>
      <ol>
        <li><strong>De quoi parle cette politique de confidentialité?</strong></li>
        <li><strong>Qui est responsable du traitement de vos données?</strong></li>
        <li><strong>Quelles données personnelles traitons-nous?</strong></li>
        <li><strong>À quelles fins et comment traitons-nous vos données personnelles?</strong></li>
        <li><strong>Avec qui partageons-nous vos informations salariales?</strong></li>
        <li><strong>Devons-nous divulguer les données salariales à l’étranger?</strong></li>
        <li><strong>Combien de temps traitons-nous les données salariales?</strong></li>
        <li><strong>Quelles sont les bases juridiques du traitement des données?</strong></li>
        <li><strong>Quels sont vos droits?</strong></li>
        <li><strong>Modifications?</strong></li>
      </ol>
    </p>
    <h1>1. De quoi parle cette politique de confidentialité?</h1>
    <p>
      Matto-Group AG, Général-Guisan-Str. 6, 6300 Zoug, traite les données personnelles vous concernant ou
      concernant d'autres personnes de différentes manières et à différentes fins. Dans la mesure où nous faisons
      référence ci-dessous à «Matto-Group» ou «nous», il s'agit de Matto-Group AG (voir section 2).
    </p>
    <p>
      Les «données personnelles» sont toutes les informations qui peuvent être associées à une personne
      spécifique, et le «traitement»
      désigne toute manipulation de celles-ci, par ex. l'obtenir, l'utiliser et la divulguer.
      Cette déclaration de protection des données explique notre traitement des données personnelles (ci-après
      données) si
    </p>
    <ul>
      <li>
        vous effectuez notre analyse salariale (puis contrôle de salaire) sur
        notre site Internet <a href="https://lohncheck.ch/fr"
                               target="_blank" rel="noopener noreferrer">https://lohncheck.ch/fr</a> ou sur
        d'autres plateformes
      </li>
      <li>
        vous vous inscrivez à notre newsletter,
      </li>
      <li>
        s ou des tiers réalisons des activités de marketing direct sur la base des données que vous saisissez dans
        le cadre du chèque de salaire.
      </li>
    </ul>

    <p>
      Pour faciliter la lecture, cette déclaration de protection des données ne mentionne pas plusieurs genres,
      mais nous entendons nous adresser aux personnes de tous genres.
      Veuillez prendre le temps de lire cette politique de confidentialité pour savoir comment et pourquoi
      Matto-Group
      traite vos données personnelles dans le cadre du chèque de salaire et comment Matto-Group protège vos
      données
      personnelles et quels droits vous avez dans ce contexte.
      Si vous avez des questions ou souhaitez de plus amples informations sur notre traitement des données,
      nous serons heureux de vous aider (section 2).
    </p>
    <p>
      Nous avons aligné cette déclaration de protection des données sur la loi suisse sur la protection des
      données (DSG) et sur le règlement général européen sur la protection des données (RGPD).
      Si des «données personnelles» sont mentionnées dans la déclaration de protection des données,
      cela inclut également les «données personnelles» conformément au RGPD.
      La question de savoir si et dans quelle mesure le RGPD est applicable dépend de chaque cas individuel.
    </p>
    <h1>2. Qui est responsable du traitement de vos données?</h1>
    <p>
      L'entreprise suivante est le «responsable» du traitement des données conformément
      à cette déclaration de protection des données, c'est-à-dire l'organisme principalement responsable de la
      législation sur la protection des données, sauf indication contraire dans des cas individuels
      (par exemple dans d'autres déclarations de protection des données ou sur des formulaires):
    </p>
    <p>
      Matto-Group AG <br/>
      General-Guisan-Str. 6 <br/>
      6300 Zug <br/>
      Switzerland <br/>
    </p>
    <p>
      Si vous avez des questions sur la protection des données, n'hésitez pas à contacter l'adresse suivante
      afin
      que nous puissions traiter votre demande dans les plus brefs délais:
    </p>
    <p>
      <a href="mailto:datenschutz@lohncheck.ch" target="_blank" rel='noopener noreferrer'>datenschutz@lohncheck.ch</a>
    </p>
    <p>
      Si vous avez des questions sur le RGPD, vous pouvez également contacter notre représentant UE conformément
      à l'art. 27 RGPD:
    </p>
    <p>
      DP-Dock GmbH <br/>
      z.Hd. Matto-Group AG <br/>
      Ballindamm 39 <br/>
      20095 Hamburg <br/>
      Germany <br/>
    </p>
    <p>
      Si vous avez des questions sur le RGPD britannique, vous pouvez également contacter notre représentant
      britannique:
    </p>
    <p>
      DP Data Protection Services UK Ltd. <br/>
      Attn: Matto-Group AG <br/>
      16 Great Queen Street <br/>
      Covent Garden <br/>
      London, WC2B 5AH <br/>
      United Kingdom <br/>
    </p>
    <p>
      L'adresse e-mail pour les demandes de renseignements adressées à nos représentants locaux dans l'UE et au
      Royaume-Uni est:&nbsp;
      <a href="mailto:matto‑group@gdpr‑rep.com" target="_blank" rel='noopener noreferrer'>matto‑group@gdpr‑rep.com </a>
    </p>


    <h1>3. Quelles données personnelles traitons-nous? </h1>
    <p>
      Aux fins du chèque de salaire et des mesures publicitaires qui en découlent, nous traitons les données
      personnelles suivantes, cette liste n'étant toutefois pas exhaustive. Nous traitons les données que
      vous saisissez sur notre site Internet pour effectuer le contrôle de salaire. Ces données ne sont
      personnelles
      que si vous fournissez également votre nom ou votre adresse électronique. Vous n'êtes pas obligé de vous
      identifier ni de saisir des données, mais sans les données pertinentes, le contrôle de salaire ne peut pas
      être effectué ou il produira un résultat inférieur. Nous collectons principalement les données suivantes
      (ci-après collectivement: données de contrôle de salaire):
    </p>
    <ul>
      <li> Informations vous concernant (par exemple sexe, année de naissance, nationalité, lieu de résidence,
        état
        civil, année de naissance des enfants)
      </li>
      <li> Coordonnées telles que l'adresse électronique ou l'adresse postale</li>
      <li> Informations sur votre profession et votre formation (par exemple diplômes, établissement
        d'enseignement,
        niveau d'études le plus élevé)
      </li>
      <li> Informations sur votre expérience professionnelle (par exemple niveau de carrière, expérience
        professionnelle, durée de gestion, etc.)
      </li>
      <li>Informations sur votre employeur (par exemple entreprise, secteur d'activité, nombre d'employés,
        orientation, forme juridique, pays)
      </li>
      <li> Informations sur votre emploi, votre salaire et votre situation de revenus (par exemple, revenu brut,
        charge de travail, titre du poste)
      </li>
      <li>D'autres informations que vous nous fournissez, par exemple via le coach de carrière</li>
    </ul>
    <p>
      Nous pouvons également extraire automatiquement et grâce à l’intelligence artificielle des données
      pertinentes
      du CV et d’autres documents que vous nous fournissez afin que nous puissions utiliser ces données pour
      effectuer
      des comparaisons salariales plus précises.
    </p>
    <p>
      Dans la mesure où les données qui nous sont communiquées contiennent des données personnelles
      particulièrement
      sensibles (également «catégories particulières de données personnelles»), telles que des données de santé
      ou des informations sur des activités politiques ou syndicales, nous utilisons ces données aux fins
      spécifiées
      dans la présente protection des données. déclaration. Dans ce cas, nous nous appuyons sur votre consentement
      exprès par le biais de votre divulgation (art. 9, paragraphe 2, lit. a du RGPD).
    </p>
    <p>
      Avec votre consentement séparé, nous pouvons également collecter des données complètes de votre profil
      LinkedIn,
      que LinkedIn met à notre disposition. Cela comprend:
    </p>
    <ul>
      <li>Des informations sur votre compte (par exemple, lorsque vous avez ouvert votre compte et lorsque vous
        vous
        êtes connecté, historique des adresses e-mail associées au compte, numéros de téléphone associés à votre
        compte,
        informations sur les calendriers synchronisés avec LinkedIn)
      </li>
      <li>Informations sur les activités sur LinkedIn (par exemple, sur quelles publicités vous avez cliqué, qui
        ou
        quels hashtags vous suivez, quelles photos, vidéos ou documents vous avez partagés, quoi ou qui vous avez
        recherché et quand, les conclusions que LinkedIn tire de votre profil ou de votre activités sur LinkedIn,
        commentaires effectués, mises à jour appréciées, publications et articles écrits, contacts importés,
        événements
        auxquels vous avez participé et autres achats liés à votre compte, notes et avis sur les produits,
        prestataires
        de services et avis d'apprentissage, groupes auxquels vous participez, invitations que vous avez reçues
        ou
        envoyés, les emplois que vous avez publiés et les postes pour lesquels vous avez postulé, les emplois,
        emplacements, secteurs et entreprises préférés, les vidéos d'apprentissage que vous avez regardées, les
        messages envoyés et reçus, les informations sur l'emploi enregistrées, les alertes d'emploi, les
        recommandations que vous avez reçues ou soumises)
      </li>
      <li>Informations sur votre profil (par exemple publications, projets, activités bénévoles, organisations,
        langues, récompenses, cours, certifications et autres informations de profil, informations biographiques
        et
        compétences, postes dans des entreprises et des sites, écoles et diplômes, confirmations de vos
        compétences par
        d'autres personnes) et
      </li>
      <li> Informations sur vos relations (par exemple nom, fonction, entreprise et date de connexion des
        connexions
        de premier degré). Nous pouvons recevoir des informations personnelles supplémentaires de la part de tiers
        lorsqu'ils effectuent un contrôle de paie pour quelqu'un d'autre.
      </li>
    </ul>
    <p>
      Si vous nous fournissez des informations sur d'autres personnes, nous supposerons que vous êtes autorisé à
      le
      faire et que les informations sont exactes. Veuillez vous assurer que ces tiers ont été informés de cette
      déclaration de protection des données (par exemple par une référence à cette déclaration de protection des
      données).
    </p>

    <h1>4. A quelles fins et comment traitons-nous vos données personnelles?</h1>
    <h2>4.1. Réalisation du chèque de salaire et autres services en ligne </h2>
    <p>
      Nous utilisons les données de contrôle des salaires que nous collectons principalement pour fournir nos
      services
      en ligne, c'est-à-dire en particulier pour effectuer des calculs et des analyses de salaires sur nos sites
      Internet ou plateformes et pour afficher des offres d'emploi pertinentes sur nos pages.
    </p>
    <h2>4.2. Publicité personnalisée personnelle et de tiers </h2>
    <p>
      Sur la base de votre consentement séparé, les données de contrôle de salaire que vous fournissez sur notre
      site
      Web ou notre plateforme peuvent être utilisées à des fins publicitaires et marketing. Nous pouvons
      nous-mêmes
      analyser vos données de contrôle de salaire afin de vous proposer des offres personnalisées sur notre site
      Internet ou sur des sites Internet tiers, en fonction d'un groupe cible spécifique (par exemple revenu,
      profession ou tranche d'âge). Ces offres peuvent concerner des biens ou des services de notre part ou de
      partenaires. Nous pouvons également partager les informations de votre chèque de salaire avec des tiers pour
      qu'ils puissent lancer des campagnes publicitaires associées. En règle générale, cela se fait sans
      divulgation
      de données personnelles - cela signifie que ces tiers ne reçoivent vos données que sous un pseudonyme
      (c'est-à-dire que des conclusions sur votre personne ne sont possibles que si vous êtes déjà connu de ces
      tiers,
      par exemple grâce à une connexion). Nous pouvons également transmettre vos données de chèque de paie à nos
      partenaires à titre personnel, notamment aux éditeurs, qui peuvent utiliser vos données sous leur propre
      responsabilité, notamment à des fins de publicité personnalisée pour eux-mêmes et pour des tiers. De plus
      amples informations peuvent être trouvées dans la section 5.
    </p>
    <p>
      Aux fins de personnalisation des campagnes publicitaires, nous pouvons comparer vos adresses électroniques
      avec
      la base de données d'adresses des destinataires nommés au point 5 afin que les données de votre chèque de
      salaire de ces destinataires soient à leur tour liées à d'autres identifiants dont ils disposent, tels que
      l'UID2, les jetons EUID, ou OneID et peut être utilisé en conséquence. Un jeton UID2 ou EUID est un
      identifiant
      publicitaire numérique persistant et crypté fourni et contrôlé par l'utilisateur du site Web. Le OneID est
      un
      identifiant publicitaire numérique crypté qui est créé à l'aide d'un identifiant tel que votre adresse
      électronique et nous permet de vous reconnaître et de vous adresser spécifiquement sur différents sites Web.
    </p>
    <p>
      Vous pouvez à tout moment vous opposer à notre utilisation de vos données de chèque de salaire à des fins
      publicitaires en envoyant un courriel électronique à: <a
      href="mailto:datenschutz@lohncheck.ch" target="_blank" rel='noopener noreferrer'>datenschutz@lohncheck.ch</a> ou
      en utilisant le
      lien de
      désabonnement dans nos newsletters et communications avec effet pour l'avenir.
    </p>
    <p>
      Il nous est également possible de relier vos données de contrôle de salaire à d'autres informations vous
      concernant à des fins de publicité personnalisée, tant en interne qu'en externe, par exemple avec des
      données
      provenant de sources accessibles au public telles que les registres publics ou d'Internet (site Internet,
      réseaux sociaux, etc.). Pour plus d'informations sur ces catégories de données, veuillez consulter notre
      déclaration générale de protection des données.
    </p>
    <h2>4.3. Bulletin </h2>
    <p>
      Sur la base de votre consentement séparé, nous pouvons vous envoyer des informations sur des produits et
      services
      personnalisés ainsi que des offres d'emploi de nos partenaires publicitaires via une newsletter. Nous
      utilisons
      à cet effet vos données de contrôle de salaire, mais nous pouvons également utiliser vos données de
      comportement
      et de préférences. De plus amples informations sur ces catégories de données personnelles peuvent être
      trouvées
      dans notre déclaration générale de protection des données, disponible <a
      href="https://lohncheck.at/fr/datenschutz" target="_blank" rel='noopener noreferrer'>ici</a>.
      Vous pouvez à tout moment vous opposer à cet envoi en envoyant un courriel électronique à <a
      href="mailto:datenschutz@lohncheck.ch" target="_blank" rel='noopener noreferrer'>datenschutz@lohncheck.ch</a> ou
      en cliquant sur le
      lien
      de désabonnement dans le message correspondant.
    </p>
    <h2>4.4. Autres finalités </h2>
    <p>
      Enfin, nous pouvons également traiter vos données de contrôle de salaire à d'autres fins, telles que
      l'optimisation de notre site Internet, l'optimisation de notre modèle statistique de calcul des salaires
      et
      éviter les redondances dans nos données de formation, faire respecter la loi et empêcher crimes. Des
      informations sur ces finalités supplémentaires peuvent être trouvées dans notre déclaration générale de
      protection des données, disponible <a
      href="https://lohncheck.at/fr/datenschutz" target="_blank" rel='noopener noreferrer'>ici</a>.
      toutefois, les explications correspondantes à d'autres fins s'appliquent également ici.
    </p>
    <h1>5. Avec qui partageons-nous les détails de votre salaire? </h1>
    <h2>5.1. Général </h2>
    <p>
      Dans le cadre de la personnalisation et de l'affichage de campagnes publicitaires, nous pouvons également
      divulguer vos données à des tiers sur la base de votre consentement séparé. Il peut s'agir d'éditeurs (par
      exemple des maisons d'édition en ligne) ou de nos partenaires technologiques publicitaires, qui traitent
      les
      données soit pour nous (dans le cas où nous menons les campagnes publicitaires), soit pour leurs propres
      fins et
      donc sous leur propre responsabilité (dans le cas où qu'ils réalisent eux-mêmes les campagnes
      publicitaires).
    </p>
    <h2>5.2. Traitement par des tiers à leurs propres fins </h2>
    <p>
      Dans les cas où nous transmettons vos données à nos partenaires, tels que des éditeurs, pour un traitement à
      leurs propres fins, ils sont eux-mêmes responsables du traitement ultérieur de vos données. Vous trouverez
      des
      informations sur le traitement de vos données dans la déclaration de protection des données correspondante
      de
      nos partenaires. Nous et nos partenaires pouvons également être conjointement responsables de la collecte
      des
      données. Dans ces cas, nous conclurons un accord correspondant, que vous pourrez consulter à l’adresse
      indiquée
      au point 2.
    </p>
    <h2>5.3. Annonce aux partenaires recruteurs </h2>
    <p>
      Si vous nous donnez votre consentement, nous transmettrons les informations relatives à votre candidature à
      nos
      partenaires de recrutement. Cela inclut toutes les données pertinentes pour votre carrière provenant de
      votre CV
      ou de votre profil LinkedIn, telles que le nom, les coordonnées, la formation, les qualifications, les
      compétences, l'expérience professionnelle et la formation continue.
    </p>
    <h2>5.4. Annonce aux partenaires technologiques publicitaires </h2>
    <p>
      Nous partageons également vos informations avec nos partenaires technologiques publicitaires afin que nous
      ou eux
      puissions vous montrer des offres personnalisées de notre part ou de celles de nos partenaires sur notre
      site Web
      ou sur d'autres sites Web. Ces annonces sont faites notamment aux destinataires suivants. Cependant, la
      liste
      suivante n'est pas exhaustive. Nous pouvons également travailler avec d'autres partenaires technologiques
      publicitaires qui offrent des services identiques ou similaires à ceux répertoriés ci-dessous:
    </p>
    <ul>
      <li> Decentriq ou Infosum: Decentriq et Infosum proposent une solution appelée Data Clean Room, qui permet
        à
        différentes parties d'analyser conjointement des données agrégées sans identifier les personnes derrière
        elles.
        Ce système nous permet d'utiliser des données provenant de diverses sources (généralement des éditeurs
        tels
        que TX/Goldbach, Ringier ou Audienzz) et de lancer des campagnes publicitaires basées sur les informations
        obtenues, sans que nous ou les autres sociétés obtenions un aperçu des données personnelles spécifiques
        des
        autres parties. Au lieu de cela, les données sont analysées dans un environnement crypté afin qu'il ne
        soit
        pas possible de remonter jusqu'à des personnes individuelles.
      </li>

      <li>Meta: Facebook Custom Audiences est une campagne de remarketing dans laquelle l'annonceur crypte les
        adresses électroniques de ses clients collectées et collectées à cet effet à l'aide d'un algorithme et
        envoie
        ces identifiants non personnels (valeurs de hachage) à Facebook. Facebook peut comparer ces données avec
        son
        inventaire d'identifiants d'utilisateurs et annoncer les correspondances de manière ciblée. Après la
        comparaison, les valeurs de hachage transmises sont supprimées. Cela signifie qu'aucune adresse
        électronique
        n'est transmise si elle n'est pas déjà connue de Facebook. En outre, Facebook peut également s'adresser
        à
        des clients similaires ayant des intérêts similaires sur la base des listes d'utilisateurs téléchargées.
        De
        plus amples informations sur l'étendue de la collecte ainsi que sur le traitement et l'utilisation
        ultérieurs
        des données par Facebook Custom Audiences peuvent être trouvées dans les directives de protection des
        données
        de Facebook, qui sont répertoriées, entre autres, sur <a
          href="https://www.facebook.com/privacy/explanation" target="_blank" rel='noopener noreferrer'>
          https://www.facebook.com/privacy/explanation</a>. Les
        termes et conditions des audiences personnalisées peuvent être consultés sur <a
          href="https://www.facebook.com/ads/manage/customaudiences/tos.php"
          target="_blank" rel='noopener noreferrer'>https://www.facebook.com/ads/manage/customaudiences/tos.php</a>
        . Vous pouvez vous opposer à la collecte et à l'utilisation d'informations à des fins de publicité en
        ligne
        destinées à des groupes cibles spécifiques en utilisant le lien suivant: <a
          href="https://www.facebook.com/ads/website_custom_audiences/" target="_blank" rel='noopener noreferrer'>
          https://www.facebook.com/ads/website_custom_audiences</a>.
      </li>
      <li>Google: Google Customer Match fonctionne essentiellement comme les audiences personnalisées de Facebook
        (voir ci-dessus). Contrairement aux audiences personnalisées de Facebook, nous pouvons également fournir
        vos
        données à Google sous forme non cryptée. Dans ce cas, Google se charge pour nous du cryptage (hachage)
        avant
        de comparer les données. Sur la base de la comparaison des données, Google peut faire de la publicité
        ciblée
        auprès des utilisateurs de la recherche Google, de YouTube et de Gmail et optimiser ses campagnes
        publicitaires
        de manière indépendante. Les directives de la fonction «Customer Match» s'appliquent aux données
        fournies
        par l'annonceur: <a href="https://support.google.com/adwordspolicy/answer/6299717?hl=de"
                            target="_blank"
                            rel='noopener noreferrer'>https://support.google.com/adwordspolicy/answer/6299717?hl=de</a>
      </li>
    </ul>
    <p>
      Pour plus d'informations, veuillez consulter la politique de confidentialité de Google à l'adresse: <a
      href="https://www.google.com/intl/de/policies/privacy/"
      target="_blank" rel='noopener noreferrer'>https://www.google.com/intl/de/policies/privacy</a>. Vous pouvez
      modifier vos données
      personnelles
      et vos paramètres de confidentialité sous Google «Mon compte»: <a
      href="https://myaccount.google.com/intro?hl=de" target="_blank"
      rel='noopener noreferrer'>https://myaccount.google.com/intro?hl=de</a>.
    </p>
    <ul>
      <li>The Trade Desk: Avec The Trade Desk nous utilisons une plateforme qui permet de comparer les données
        avec
        des solutions d'identification telles que Unified ID 2.0 ou EUID (encrypted User ID) ou OneID. Lorsque
        vous
        utilisez les solutions d'identification, certains identifiants (comme votre adresse électronique) sont
        attribués à un identifiant crypté. Grâce à Trade Desk, nous pouvons attribuer votre identifiant à des
        groupes
        cibles spécifiques, afficher des publicités personnalisées sur notre site Web et sur des sites Web tiers
        et
        les synchroniser sur tous les appareils, maximisant ainsi la portée des campagnes publicitaires sur
        différents
        types de médias (appareils mobiles, appareils fixes, TV).
      </li>
      <li>OneID: nous pouvons comparer les données de votre salaire avec les données que vous avez stockées avec
        OneID (par exemple, votre adresse électronique) et recevoir ainsi le OneID qui vous est attribué. Nous
        pouvons
        relier cet identifiant à d'autres données de salaire afin de vous reconnaître sur notre site Web et sur
        des
        sites Web tiers et de vous adresser sur tous les appareils en vous montrant des offres personnalisées de
        notre
        part ou de celles de nos partenaires publicitaires.
      </li>
    </ul>
    <h2>5.5.Autres annonces </h2>
    <p>
      Nous fournissons également à d'autres prestataires de services les données de contrôle de salaire
      nécessaires à
      leurs prestations. Cela concerne particulièrement nos prestataires informatiques. Cela inclut également les
      fournisseurs de services d'évaluation et d'analyse de données, notamment OpenAI pour extraire les données
      de
      votre CV et d'autres documents qui nous sont fournis à l'aide de l'intelligence artificielle. Dans la
      mesure
      où ces prestataires de services traitent des données personnelles en tant que sous-traitants, ils sont tenus
      de
      traiter les données personnelles exclusivement conformément à nos instructions et de prendre des mesures
      pour garantir
      la sécurité des données. Les données peuvent également être divulguées à d'autres destinataires, par ex. aux
      tribunaux et autorités dans le cadre de procédures et d'obligations juridiques d'information et de
      coopération,
      aux repreneurs d'entreprises et d'actifs, aux sociétés de financement de titrisations et aux sociétés de
      recouvrement de créances.
    </p>
    <h1>6. Devons-nous divulguer les données des salaires à l’étranger </h1>
    <p>
      Les destinataires des données mentionnés ci-dessus ne se trouvent pas uniquement en Suisse. Ils peuvent
      également
      être situés dans l’Espace économique européen (EEE) (par exemple en Irlande), mais aussi dans d’autres pays
      du
      monde (notamment aux États-Unis). Actuellement, tous ces États ne garantissent pas un niveau de protection
      des
      données correspondant au droit suisse. Nous compensons le niveau de protection inférieur par des contrats
      appropriés, notamment les clauses contractuelles types émises par la Commission européenne et reconnues par
      le
      Préposé suisse à la protection des données et à la transparence (PFPDT). De plus amples détails et une copie
      de
      ces clauses peuvent être trouvés <a
      href="https://www.edoeb.admin.ch/edoeb/de/home/datenschutz/arbeit_wirtschaft/datenuebermittlung_ausland.html"
      target="_blank" rel='noopener noreferrer'>ici</a>.
    </p>
    <p>
      Dans certains cas, nous pouvons transmettre des données conformément aux règles de protection des données
      même
      sans de tels contrats, par ex. si vous avez consenti à la divulgation correspondante ou si la divulgation
      est
      nécessaire à l'exécution du contrat, à la constatation, à l'exercice ou à l'exécution de droits légaux ou
      à des
      intérêts publics prépondérants.
    </p>
    <p>
      Veuillez noter que l'échange de données sur Internet s'effectue souvent via des pays tiers. Dans ce cas,
      vos
      données peuvent donc atterrir à l'étranger même si l'expéditeur et le destinataire se trouvent dans le
      même pays.
    </p>
    <h1>7.Combien de temps traitons-nous les données des chèques de salaire</h1>
    <p>
      Nous stockons et traitons vos données de salaire aussi longtemps que cela est nécessaire à la finalité du
      traitement, tant que vous ne révoquez pas votre consentement ou aussi longtemps que nous avons un intérêt
      légitime à les conserver (par exemple pour faire valoir des droits légaux, pour l'archivage). ou pour
      améliorer
      l'informatique pour assurer la sécurité) et pour autant que les données soient soumises à une obligation
      légale
      de conservation. Sauf obligation légale ou contractuelle contraire, nous détruirons ou anonymiserons vos
      données
      après l'expiration de la période de stockage ou de traitement dans le cadre de nos processus habituels.
    </p>
    <h1>8.Quelles sont les bases juridiques du traitement des données? </h1>
    <p>
      Selon le droit applicable, le traitement des données n'est autorisé que si le droit applicable le permet
      spécifiquement. Ceci ne s'applique pas en vertu de la loi suisse sur la protection des données, mais,
      par exemple, en vertu du règlement général européen sur la protection des données (RGPD), dans la mesure où
      cela
      s'applique. Dans ce cas, nous basons le traitement de vos données de contrôle de salaire sur les bases
      juridiques suivantes:
    </p>
    <ul>
      <li> Article 6, (1) (a) du RGPD pour les traitements que nous effectuons avec votre consentement séparé, en
        particulier l'obtention et le traitement mentionnés aux paragraphes 3 et 4.2 et la divulgation des
        données
        mentionnées au paragraphe 5.3;
      </li>
      <li>Art. 6 (1)(a) RGPD pour les traitements que nous effectuons avec votre consentement séparé, en
        particulier
        les traitements mentionnés à l'article 4.2;
      </li>
      <li>Art. 6 (1)(b) RGPD pour les traitements nécessaires à la préparation et à la mise en œuvre de nos
        services
        en ligne (en particulier la mise en œuvre d'analyses salariales et les autres finalités mentionnées au
        point
        4.1), si vous décidez d'utiliser ces services pour gagner du poids;
      </li>
      <li>Art. 6 (1)(f) RGPD pour le traitement nécessaire à la protection de nos intérêts légitimes ou de ceux de
        tiers, à moins que les libertés et droits fondamentaux ainsi que les intérêts de la personne concernée ne
        l'emportent. Cela s'applique en particulier au respect du droit suisse ou européen, à notre intérêt à
        exercer
        nos activités et activités de manière permanente, conviviale, sûre et fiable et aux finalités selon le
        chiffre
        4.4;
      </li>
      <li> Art. 6 (1)(c) du RGPD pour le traitement nécessaire au respect d'une obligation légale en vertu du
        droit
        d'un État membre de l'Espace économique européen (EEE). L'EEE comprend les États de l'UE ainsi que
        l'Islande, la Norvège et le Liechtenstein.
      </li>
    </ul>
    <h1>9. Quels sont vos droits? </h1>
    <p>
      Vous disposez de certains droits dans le cadre et sous réserve des conditions ou limitations de la loi
      applicable sur la protection des données afin que vous puissiez obtenir de plus amples informations sur
      notre
      traitement des données et influencer notre traitement. Il s'agit notamment des droits suivants:
    </p>
    <ul>
      <li>Droit à l'information: Vous pouvez demander de plus amples informations sur notre traitement des
        données.
        Nous sommes à votre disposition pour cela. Vous pouvez également soumettre une demande d'informations si
        vous
        souhaitez des informations complémentaires et une copie de vos données.
      </li>
      <li>Opposition et suppression: vous pouvez vous opposer à notre traitement de données avec effet pour
        l'avenir et également demander à tout moment que nous supprimions vos données salariales. Vous pouvez
        notamment
        vous opposer à tout moment au traitement des données à des fins de publicité directe et de newsletter. <a
          href="https://lohncheck.ch/de/daten-loeschen" target="_blank"
          rel='noopener noreferrer'>https://lohncheck.ch/de/daten-loeschen</a>
      </li>
      <li>Correction et restriction: Vous pouvez corriger ou compléter des données de chèque de salaire
        incorrectes
        ou incomplètes et faire limiter le traitement de vos données.
      </li>
      <li>Transfert: Vous avez également le droit de recevoir les données du chèque de salaire que vous nous avez
        fournies dans un format structuré, commun et lisible par machine ou de les faire transférer à un tiers, à
        condition que le traitement des données correspondant soit basé sur votre consentement ou est nécessaire à
        l’exécution du contrat.
      </li>
      <li> Révocation: Si nous traitons des données sur la base de votre consentement, vous pouvez révoquer votre
        consentement à tout moment. La révocation ne s'applique que pour l'avenir et nous nous réservons le
        droit de
        continuer à traiter les données en cas de révocation fondée sur un autre motif.
      </li>
    </ul>
    <p>
      Veuillez noter que ces droits sont soumis à des exigences et restrictions légales et ne sont donc pas
      entièrement
      disponibles dans tous les cas. En particulier, nous pouvons être amenés à traiter et à stocker vos données
      personnelles afin de protéger nos propres intérêts légitimes, tels que l'affirmation, l'exercice ou la
      défense
      de droits en justice, ou pour respecter des obligations légales. Dans la mesure où la loi le permet,
      notamment
      pour protéger les droits et libertés des autres personnes concernées et pour sauvegarder des intérêts
      légitimes,
      nous pouvons donc rejeter la demande d'une personne concernée en tout ou en partie (par exemple en masquant
      certains contenus concernant des tiers ou nos secrets d'affaires).
    </p>
    <p>
      Si vous souhaitez exercer vos droits à notre encontre, veuillez nous contacter par écrit. Vous trouverez nos
      coordonnées au point 2. En règle générale, nous devons vérifier votre identité (par exemple au moyen d'une
      copie
      de votre pièce d'identité). Vous êtes également libre de déposer une plainte contre notre traitement de vos
      données auprès de l'autorité de contrôle compétente. L'autorité de surveillance compétente en Suisse est
      le
      Préposé fédéral à la protection des données et à la transparence (PFPDT). Dans le cadre du RGPD, vous pouvez
      contacter les autorités de contrôle nationales compétentes.
    </p>

    <h1>10. Modifications </h1>
    <p>
      Nous pouvons adapter cette déclaration de protection des données à tout moment si nécessaire. La version
      actuelle
      publiée sur notre site Internet s'applique.
    </p>
    <p>
      En date du: 22 mai 2024
    </p>

  </>
}
