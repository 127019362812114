const demoValues = {
	// Step 1
	career_level_id: 3,
	education_id: 4,
	education_name: 'Frontend Developer',
	university: 'ETH Zürich',
	searching_for_other: '0',

	// Step 2
	company: 'Musterfirma GmbH',
	industry_id: 4,
	company_size_id: 2,
	company_orientation_id: 2,
	company_type_id: 5,

	// Step 3
	job_category_id: 1,
	workload_id: 8,
	work_years_function_id: 3,
	work_years_industry_id: 4,
	management_id: 1,
	workplace: 4,
	job_function: {
		label: 'Informatiker',
		value: 66,
		parent: null,
		translations: {
			de: 'Informatiker',
			en: 'Computer scientist',
			es: 'Informático',
			fr: 'Informaticien',
			it: 'Informatica',
		},
	},
	job_title: 'Senior Frontend Developer',
	monthly_salary: 4000,
	yearly_salary: 48000,

	// Step 4
	specified_children: true,
	residence_id: 9,
	children: [
		{
			birthday: '2020-12-31',
		},
		{
			birthday: '2019-11-20',
		},
	],
	gender_id: '1',
	year_of_birth: 1960,
	nationality_id: 216,
	email: 'max@mustermann.ch',
	privacy_policy: true,
	newsletter: false,
}

export default demoValues
