import React, { Fragment, useState } from 'react'
import { Translate } from 'react-localize-redux'
import { Field, FieldArray, reduxForm } from 'redux-form'

import history from '@browser-history'
import formConfig from '@form-config'
import { sortByTitle } from '@utils/helper'
import {
	required,
	requiredSelect,
	requiredYear,
	email,
} from '@utils/validation'
import ModalComponent from '../Step4/Modal'

import {
	Email,
	Date,
	Number,
	Select,
	Check,
	RadioGroup,
	Form,
	Button,
	Steps,
	Card,
	FormLabel as Label,
} from '@components'

import Cross from 'assets/img/cross.svg'

const Children = ({ fields, meta: { error, submitFailed } }) => (
	<Translate>
		{({ translate }) => (
			<>
				{fields.map((child, index) => (
					<div key={index} className="fieldGroup">
						<a
							href="#remove"
							title={translate('remove')}
							onClick={(e) => {
								e.preventDefault()

								fields.remove(index)
							}}
						>
							<img src={Cross} alt={translate('remove')} />
						</a>
						<Field
							name={`${child}.birthday`}
							component={Date}
							label={formConfig.children.childBirthday.label}
							labelPlaceholder={index + 1}
						/>
					</div>
				))}
				<div className="fieldGroup">
					<Button
						label={translate('addChildButton')}
						onClick={() => fields.push({})}
						type="button"
					/>
				</div>
			</>
		)}
	</Translate>
)

const Step4Form = reduxForm({
	form: 'Form',
	destroyOnUnmount: false,
})(({ valid, specifiedChildren, handleSubmit, submitting, error, ...props }) => {
	if (!props.activeLanguage || !props.activeLanguage.code) return null

	const [isOpen, setIsOpen] = useState(false)
	async function handleOpen() {
		if (valid) {
			setIsOpen(true)
		} else {
			await handleSubmit()
		}
	}
	function handleClose() {
		setIsOpen(false)
	}
	async function handleConfirm() {
		await handleSubmit()
		handleClose()
	}
	function handleCancel() {
		handleClose()
	}

	const nationality = sortByTitle(
		formConfig.nationality.options,
		props.activeLanguage.code
	)

	return (
		<Translate>
			{({ translate }) => (
				<Form
					onSubmit={handleOpen}
					actions={
						<Fragment>
							<a
								className={'ButtonBack'}
								href="#a"
								onClick={(e) => {
									e.preventDefault()

									history.push(`/eigene-daten`)
									window.scrollTo(0, 0)
								}}
							>
								{translate('back')}
							</a>
							<Button
								label={
									submitting || props.isFetching ? (
										<span className="isLoading">
											{translate('loading')}
										</span>
									) : (
										translate('calculate')
									)
								}
								onClick={handleOpen}
								disabled={submitting || props.isFetching}
								type="button"
							/>
							<ModalComponent
								isOpen={isOpen}
								onConfirm={handleConfirm}
								onCancel={handleCancel}
							/>
						</Fragment>
					}
					error={error}
				>
					<Steps label={translate('personalData')} count="4" />
					<Card>
						<div className="fieldHeader">
							<Label>{translate('family')}</Label>
						</div>
						<Field
							component={Check}
							name={formConfig.specifiedChildren.id}
							label={formConfig.specifiedChildren.label}
						/>
						{!!specifiedChildren && (
							<>
								<Field
									component={Select}
									name={formConfig.residence.id}
									label={formConfig.residence.label}
									options={formConfig.residence.options}
									validate={[requiredSelect]}
								/>
								<FieldArray
									name="children"
									component={Children}
								/>
							</>
						)}
					</Card>
					<Card>
						<Field
							component={RadioGroup}
							name={formConfig.gender.id}
							label={formConfig.gender.label}
							validate={[required]}
							options={[
								{
									name: formConfig.gender.radios.radio1.id,
									value: formConfig.gender.radios.radio1
										.value,
									label: formConfig.gender.radios.radio1
										.label,
								},
								{
									name: formConfig.gender.radios.radio2.id,
									value: formConfig.gender.radios.radio2
										.value,
									label: formConfig.gender.radios.radio2
										.label,
								},
							]}
						/>
						<Field
							component={Number}
							name={formConfig.yearOfBirth.id}
							label={formConfig.yearOfBirth.label}
							placeholder={formConfig.yearOfBirth.placeholder}
							validate={[requiredYear]}
						/>
						<Field
							component={Select}
							name={formConfig.nationality.id}
							label={formConfig.nationality.label}
							validate={[requiredSelect]}
							options={nationality}
							favorites={formConfig.nationality.favorites}
						/>
						<Field
							component={Email}
							name={formConfig.email.id}
							label={formConfig.email.label}
							validate={[required, email]}
							placeholder={formConfig.email.placeholder}
						/>
					</Card>
					<Card>
						<Field
							component={Check}
							name={formConfig.privacyPolicy.id}
							label={formConfig.privacyPolicy.label}
							validate={[required]}
						/>
						<Field
							component={Check}
							name={formConfig.newsletter.id}
							label={formConfig.newsletter.label}
						/>
					</Card>
				</Form>
			)}
		</Translate>
	)
})

export default Step4Form
