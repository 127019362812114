import React, { Fragment } from 'react'
import createRenderer from '@components/FormField'
import './Email.css'

const Email = createRenderer((input, label, language, rest) => (
	<Fragment>
		{rest.groupText ? (
			<div className="FieldEmailGroup">
				<input
					id={input.name}
					className="fieldEmail"
					type="email"
					placeholder={rest.placeholder[language]}
					{...input}
				/>
				<span className="fieldGroupText">{rest.groupText}</span>
			</div>
		) : (
			<input
				id={input.name}
				className="fieldEmail"
				type="email"
				placeholder={rest.placeholder[language]}
				{...input}
			/>
		)}
	</Fragment>
))

export default Email
