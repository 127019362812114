import React, { Fragment } from 'react'
import { Translate } from 'react-localize-redux'
import { Field, reduxForm } from 'redux-form'

import ApiService from '@api'
import history from '@browser-history'
import formConfig from '@form-config'
import { getStates } from '@data/states'
import { required, requiredSelect, positiveInt } from '@utils/validation'
import { sortByTitle } from '@utils/helper'

import { Form, Select, Number, Text, Button, Steps, Card } from '@components'

let timer = null

const getJobFunctions = (inputValue, callback, lang = 'de') => {
	if (inputValue.length < 3) return false

	clearTimeout(timer)

	timer = setTimeout(() => {
		ApiService.search(lang, { query: inputValue }).then((response) => {
			if (response && response.data) {
				const list = response.data.map((r) => ({
					label: Object.values(r.title)[0],
					value: r.id,
					parent: r.parent,
					translations: r.title,
				}))

				callback(list)
			}
		})
	}, 500)
}

const Step3Form = reduxForm({
	form: 'Form',
	destroyOnUnmount: false,
})(({ handleSubmit, submitting, error, ...props }) => {
	if (!props.activeLanguage || !props.activeLanguage.code) return null

	const { currency } = props

	const showWorkplaceSelect =
		props.forms.values.country_id === 216
		|| props.forms.values.country_id === 84
		|| props.forms.values.country_id === 14

	const workplace = sortByTitle(
		getStates(props.forms.values.country_id),
		props.activeLanguage.code
	)

	return (
		<Translate>
			{({ translate }) => (
				<Form
					onSubmit={handleSubmit}
					actions={
						<Fragment>
							<a
								className={'ButtonBack'}
								href="#a"
								onClick={(e) => {
									e.preventDefault()

									history.push(`/arbeitgeber`)
									window.scrollTo(0, 0)
								}}
							>
								{translate('back')}
							</a>
							<Button
								label={translate('nextStep')}
								onClick={handleSubmit}
								disabled={submitting}
								type="button"
							/>
						</Fragment>
					}
					error={error}
				>
					<Steps label={translate('userData')} count="3" />
					<Card>
						<Field
							component={Select}
							name={formConfig.jobCategory.id}
							label={formConfig.jobCategory.label}
							validate={[requiredSelect]}
							options={formConfig.jobCategory.options}
						/>
						<Field
							component={Select}
							name={formConfig.workload.id}
							label={formConfig.workload.label}
							validate={[requiredSelect]}
							options={formConfig.workload.options}
						/>
						<Field
							component={Select}
							name={formConfig.workYearsFunction.id}
							label={formConfig.workYearsFunction.label}
							validate={[requiredSelect]}
							options={formConfig.workYearsFunction.options}
						/>
						<Field
							component={Select}
							name={formConfig.workYearsIndustry.id}
							label={formConfig.workYearsIndustry.label}
							validate={[requiredSelect]}
							options={formConfig.workYearsIndustry.options}
						/>
						<Field
							component={Select}
							name={formConfig.management.id}
							label={formConfig.management.label}
							validate={[requiredSelect]}
							options={formConfig.management.options}
						/>
						<Field
							component={Select}
							name={formConfig.country.id}
							label={formConfig.country.label}
							validate={[requiredSelect]}
							options={formConfig.country.options}
							favorites={formConfig.country.favorites}
						/>

						{/* If chosen country is not switzerland,
							show custom workplace field */}

						{showWorkplaceSelect ? (
							<Field
								component={Select}
								name={formConfig.workplace.id}
								label={formConfig.workplace.label}
								validate={[requiredSelect]}
								options={workplace}
							/>
						) : (
							<Field
								component={Text}
								name={formConfig.workplace.id}
								label={formConfig.workplace.label}
								validate={[required]}
								placeholder={formConfig.workplace.label}
							/>
						)}
						<Field
							component={Select}
							name={formConfig.jobFunction.id}
							label={formConfig.jobFunction.label}
							validate={[requiredSelect]}
							loadOptions={(inputValue, callback) =>
								getJobFunctions(
									inputValue,
									callback,
									props.activeLanguage.code
								)
							}
						/>
						<Field
							component={Text}
							name={formConfig.jobTitle.id}
							label={formConfig.jobTitle.label}
							validate={
								!formConfig.jobTitle.optional
									? [required]
									: null
							}
							optional={formConfig.jobTitle.optional}
							placeholder={formConfig.jobTitle.placeholder}
						/>
						<Field
							component={Number}
							name={formConfig.monthlySalary.id}
							label={formConfig.monthlySalary.label}
							validate={[required, positiveInt]}
							placeholder={formConfig.monthlySalary.placeholder}
							groupText={currency}
						/>
						<Field
							component={Number}
							name={formConfig.yearlySalary.id}
							label={formConfig.yearlySalary.label}
							validate={[required, positiveInt]}
							placeholder={formConfig.yearlySalary.placeholder}
							groupText={currency}
						/>
					</Card>
				</Form>
			)}
		</Translate>
	)
})

export default Step3Form
