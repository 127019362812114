import React, { Component } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { withLocalize } from 'react-localize-redux'

import history from '@browser-history'
import ApiService from '@api'
import { parseQueryString } from '@utils/helper'

import globalTranslations from './translations.json'

import {
	Header,
	Footer,
	Router,
	// CookieBanner,
	ErrorBoundary,
} from '@components'

import './App.css'

class App extends Component {
	constructor(props) {
		super(props)

		this.props.initialize({
			languages: [
				{ name: 'Deutsch', code: 'de' },
				{ name: 'Français', code: 'fr' },
				{ name: 'English', code: 'en' },
				{ name: 'Italiano', code: 'it' },
				{ name: 'Español', code: 'es' },
			],
			translation: globalTranslations,
			options: { renderToStaticMarkup },
		})

		this.props.addTranslation(globalTranslations)
	}

	UNSAFE_componentWillMount() {
		const { setActiveLanguage } = this.props
		const { search } = history.location

		let language = navigator.language || navigator.userLanguage

		if (search) {
			const params = parseQueryString(search)

			if (params['lang']) {
				language = params['lang']
			}
		}

		switch (language) {
			case 'de':
			case 'fr':
			case 'it':
			case 'en':
			case 'sp':
				setActiveLanguage(language)

				break
			default:
				setActiveLanguage('de')
		}
	}

	componentDidMount() {
		ApiService.ping()
	}

	render() {
		const classNames =
			history.location.pathname === '/ergebnis' ? 'app results' : 'app'

		return (
			<ErrorBoundary>
				{/*<CookieBanner />*/}

				<div className={classNames}>
					<Header />
					<Router />
					<Footer />
				</div>
			</ErrorBoundary>
		)
	}
}

export default withLocalize(App)
