import React, { useState } from 'react'
import { Cookies } from 'react-cookie'

import './CookieBanner.css'

const cookies = new Cookies()

const getSecondsSinceExpiration = (cookieExpiration) => {
	if (typeof cookieExpiration === 'number') {
		return cookieExpiration
	}

	const SECONDS_IN_YEAR = 31536000
	const SECONDS_IN_DAY = 86400
	const SECONDS_IN_HOUR = 3600

	const _cookieExpiration = {
		years: 0,
		days: 0,
		hours: 0,
		...cookieExpiration,
	}

	const { years, days, hours } = _cookieExpiration

	return (
		years * SECONDS_IN_YEAR +
		days * SECONDS_IN_DAY +
		hours * SECONDS_IN_HOUR
	)
}

const hasAcceptedCookies = (cookie = 'accepts-cookies') => cookies.get(cookie)

const CookieBanner = () => {
	const [hidden, setHidden] = useState(hasAcceptedCookies())

	const onAccept = (
		event,
		cookie = 'accepts-cookies',
		cookieExpiration = { years: 1 }
	) => {
		event.preventDefault()

		cookies.set(cookie, true, {
			path: '/',
			domain: 'lohncheck.ch',
			// sameSite: 'none',
			// secure: true,
			expires: new Date(
				Date.now() + getSecondsSinceExpiration(cookieExpiration) * 1000
			),
		})

		if (window.dataLayer) {
			window.dataLayer.push({
				cookiesAccepted: true,
			})
		}

		setHidden(true)
	}

	return hidden ? null : (
		<div className="cookie-banner">
			<span className="cookie-message">
				Lohncheck nutzt Cookies um Werbung zu personalisieren. Damit das
				funktioniert arbeiten wir mit Google und der M Group zusammen.
				Du kannst die Cookies jederzeit bei{' '}
				<a
					href="https://adssettings.google.com/authenticated?hl=de#display_optout"
					target="_blank"
					rel="noopener noreferrer"
				>
					Google
				</a>
				,{' '}
				<a
					href="http://optout.mookie1.com/optout/mig/?action=optout"
					target="_blank"
					rel="noopener noreferrer"
				>
					Group M
				</a>
				,{' '}
				<a
					href="https://privacycenter.xandr.com"
					target="_blank"
					rel="noopener noreferrer"
				>
					Mattogroup
				</a>{' '}
				deaktivieren und die Datenschutzbestimmungen von Lohncheck{' '}
				<a
					href="https://lohncheck.ch/de/datenschutz"
					target="_blank"
					rel="noopener noreferrer"
				>
					hier
				</a>{' '}
				einsehen.
			</span>
			<button className="button-close" onClick={onAccept}>
				Bestätigen
			</button>
		</div>
	)
}

export default CookieBanner
