import React, { Component } from 'react'
import { withLocalize } from 'react-localize-redux'
import { connect } from 'react-redux'
import TagManager from 'react-gtm-module-custom-domain'

import { calculateSalary } from '@redux/salary-calculation'
import history from '@browser-history'
import { apiRoute } from '@config'
import formConfig from '@form-config'
import { handleFormError } from '@utils/form-error-handling'

import Step4Form from './Form'

const toBoolean = (val, int = true) =>
	val >= 1 ? (int ? 1 : true) : int ? 0 : false

const toCamel = (s) =>
	s.replace(/([-_][a-z])/gi, ($1) => {
		return $1.toUpperCase().replace('-', '').replace('_', '')
	})

const idToValue = (d, formKey) => {
	const dL = { ...d }
	const valueKey = toCamel(formKey.replace('_id', ''))

	const value = dL[formKey]
	delete dL[formKey]

	if (formConfig[valueKey].radios) {
		let val = null

		Object.keys(formConfig[valueKey].radios).forEach((key) => {
			if (formConfig[valueKey].radios[key].value === value)
				val = formConfig[valueKey].radios[key].label.de.replace(
					/ /g,
					'_'
				)
		})

		dL[formKey.replace('_id', '')] = val
	} else {
		dL[formKey.replace('_id', '')] = formConfig[valueKey].options
			.find((option) => option.value === value)
			.title.de.replace(/ /g, '_')
	}

	return dL
}

const prepareDataLayer = (d) => {
	let dL = { ...d }

	// Privacy
	delete dL.email

	// Dev
	delete dL.debug
	delete dL.data
	delete dL.error
	delete dL.isFetching

	// ID to value
	dL = idToValue(dL, 'career_level_id')
	dL = idToValue(dL, 'education_id')
	dL = idToValue(dL, 'industry_id')
	dL = idToValue(dL, 'company_size_id')
	dL = idToValue(dL, 'company_orientation_id')
	dL = idToValue(dL, 'company_type_id')
	dL = idToValue(dL, 'job_category_id')
	dL = idToValue(dL, 'workload_id')
	dL = idToValue(dL, 'work_years_function_id')
	dL = idToValue(dL, 'work_years_industry_id')
	dL = idToValue(dL, 'management_id')
	dL = idToValue(dL, 'nationality_id')
	dL = idToValue(dL, 'country_id')
	dL = idToValue(dL, 'residence_id')

	dL = idToValue(dL, 'gender_id', 'gender')

	dL.searching_for_other = toBoolean(dL.searching_for_other, false)

	// Custom transformations
	// If workplace is id, get value
	if (typeof dL.workplace === 'number') {
		dL = idToValue(dL, 'workplace', 'workplace')
	}

	dL.child_count = dL.children.length || 0

	if (dL.child_count) {
		dL.children
			.sort((a, b) => new Date(a.birthday) - new Date(b.birthday))
			.forEach((child, index) => {
				if (child.birthday) {
					dL[`child_${index + 1}`] = child.birthday.replaceAll(
						'-',
						''
					)
					// "2018-12-31" 20200917
				}
			})
	}

	delete dL.children

	return dL
}

const initGTM = (d) => {
	const dataLayer = { ...d }

	// console.log('GTM data', dataLayer)

	// GTM custom data
	dataLayer.event = 'calculationData'
	dataLayer.action = 'Lohnrechner'

	TagManager.initialize({
		gtmId: 'GTM-NDG8H5M',
		customURL: 'https://cc.lohncheck.ch/gtm.js',
		dataLayer,
	})
}

const initDMP = (d, cb) => {
	const dL = { ...d }

	// DMP custom data
	dL.type = 'userdata'

	// console.log('DMP data', dL)

	let dataMarkup = ''

	for (const [key, value] of Object.entries(dL)) {
		dataMarkup += `event.${key} = "${value ?? ''}";\r\n`
	}

	const scriptMarkup = `
var event = new Object();

${dataMarkup}

var opeCustomEvents = [event];
`

	const script1 = document.createElement('script')
	const script2 = document.createElement('script')

	script1.innerHTML = scriptMarkup

	script2.src = 'https://tagger.opecloud.com/lohncheck/v2/tagger.js'
	script2.async = false

	document.body.appendChild(script1)
	document.body.appendChild(script2)

	// Run callback after external script is loaded
	script2.addEventListener('load', cb)

	// In case script can not be loaded
	script2.addEventListener('error', cb)
}

const createAndSubmitForm = (params) => {
	const isDev = process.env.REACT_APP_STAGE === 'dev'
	const inputFieldType = isDev ? 'text' : 'hidden'
	const form = document.createElement('form')

	form.method = 'post'
	form.action = apiRoute

	for (const key in params) {
		if (params.hasOwnProperty(key)) {
			if (params[key] && typeof params[key] === 'object') {
				params[key].forEach((param) => {
					const field = document.createElement('input')
					field.type = inputFieldType

					Object.keys(param).forEach(function eachKey(objectKey) {
						field.name = `${key}[][${objectKey}]`
						field.value = param[objectKey]
					})

					form.appendChild(field)
				})
			} else {
				const field = document.createElement('input')

				field.type = inputFieldType
				field.name = key
				field.value = params[key]

				form.appendChild(field)
			}
		}
	}

	if (isDev) {
		const submitButton = document.createElement('button')

		submitButton.type = 'submit'
		submitButton.textContent = 'Submit'

		form.appendChild(submitButton)

		document.body.appendChild(form)
	} else {
		document.body.appendChild(form)

		form.submit()
	}
}

const setDataLayer = (d, cb) => {
	try {
		const dL = prepareDataLayer(d)

		// GTM
		initGTM(dL)

		// Tagger.js
		initDMP(dL, () => {
			new Promise((r) => setTimeout(r, 2000))

			cb()
		})
	} catch (e) {
		console.warn(e)

		cb()
	}
}

export class Step4 extends Component {
	componentDidMount() {
		const {
			forms: { Form },
		} = this.props

		if (!Form) history.replace(`/${history.location.search}`)
	}

	handleSubmit = () => {
		try {
		const { location, currencies, calculateSalary, forms, activeLanguage } =
			this.props
		const {
			lc: { v },
		} = window

		const countryCode = formConfig.country.options.find(
			(option) => option.value === forms.Form.values.country_id
		).code

		const w = forms.Form.values.workplace
		const useWorkplaceId =
			countryCode === 'CH'
			|| countryCode === 'DE'
			|| countryCode === 'AT'

		const workplaceId = useWorkplaceId ? w : null
		const workplaceCustom = useWorkplaceId ? null : w

		// Separate `job_function`
		const { job_function: a, ...formValues } = forms.Form.values

		formValues.debug = toBoolean(forms.Form.values.debug)

		// Separate `workplace`, `specifiedChildren`, `residence`, `children`
		const {
			workplace,
			specified_children,
			residence_id,
			children,
			...payloadValues
		} = formValues

		// Prepare form payload
		const payload = {
			...payloadValues,

			// Set job function id
			job_function_id: forms.Form.values.job_function.value,

			// Workplace: id or custom
			workplace_id: workplaceId,
			workplace_custom: `${workplaceCustom}`,

			// Custom data: active language, currency
			language: activeLanguage.code,
			currency: currencies.originCurrency.toUpperCase(),

			// Show result on lohncheck.ch website or within app
			return_view: v === '2' ? 1 : 0,

			// Convert form boolean
			searching_for_other: toBoolean(
				forms.Form.values.searching_for_other
			),
			newsletter: toBoolean(forms.Form.values.newsletter),
			privacy_policy: toBoolean(forms.Form.values.privacy_policy),
		}

		const specifiedChildren = children?.filter((c) => !!c?.birthday)

		if (specified_children && specifiedChildren?.length) {
			payload.children = specifiedChildren
			payload.residence_id = residence_id
		}

		// console.log('+++++ Form payload', payload)

		const { originCurrency: origin_currency, ...currencyData } = currencies

		const dataLayer = {
			// Add custom data to form values:
			// 	- selected language
			// 	- path
			// 	- currency informations
			...formValues,
			...currencyData,
			origin_currency,
			active_language: activeLanguage.name.replace(/ /g, '_'),
			path: location.pathname.replace(/ /g, '_'),

			// Set job function name
			job_function:
				forms.Form.values.job_function.translations.de ?
					forms.Form.values.job_function.translations.de.replace(
						/ /g,
						'_'
					) : forms.Form.values.job_function.translations.de_de ?
						forms.Form.values.job_function.translations.de_de.replace(
							/ /g,
							'_'
						) :  forms.Form.values.job_function.translations.value,
		}

		setDataLayer(dataLayer, () => {
			// If V2, create HTML form and submit, return to lohncheck.ch
			if (v === '2') {
				createAndSubmitForm(payload)

				return
			}

			// Show app result
			calculateSalary(payload)
		})
		} catch (e)  {
			console.log(e)
		}
	}

	render() {
		const { specifiedChildren } = this.props

		TagManager.initialize({
			gtmId: 'GTM-NDG8H5M',
			customURL: 'https://cc.lohncheck.ch/gtm.js',
		})

		return (
			<Step4Form
				onSubmit={this.handleSubmit}
				onSubmitFail={handleFormError}
				isFetching={this.props.isFetching}
				activeLanguage={this.props.activeLanguage}
				specifiedChildren={specifiedChildren}
			/>
		)
	}
}

const mapStateToProps = (state) => ({
	forms: state.form,
	specifiedChildren: state.form?.Form?.values?.specified_children,
	currencies: state.currencies,
	isFetching: state.salarayCalculation.isFetching,
})

const mapDispatchToProps = {
	calculateSalary,
}

export default withLocalize(connect(mapStateToProps, mapDispatchToProps)(Step4))
