import React from 'react'
import { withLocalize, Translate } from 'react-localize-redux'
import Label from '@components/FormLabel'
import './RadioGroup.css'

const RadioGroup = ({
	activeLanguage,
	input,
	label,
	meta,
	options,
	language,
}) => {
	if (!(activeLanguage && activeLanguage.code)) return null

	return (
		<Translate>
			{({ translate }) => (
				<div
					className={[
						'radioGroup',
						meta.error && meta.submitFailed && !meta.visited
							? ' hasError'
							: '',
						meta.active ? 'isActive' : '',
					].join(' ')}
				>
					<div className="fieldHeader">
						<Label>{label[activeLanguage.code]}</Label>
					</div>
					{meta.error && meta.submitFailed && !meta.visited && (
						<span className="formError">{meta.error}</span>
					)}
					<div className="fieldGroup">
						{options.map((o) => (
							<div className="fieldRadio" key={o.name}>
								<input
									className="fieldRadioInput"
									type="radio"
									id={o.name}
									{...input}
									value={o.value}
									checked={o.value === input.value}
								/>
								<label
									htmlFor={o.name}
									className="fieldRadioLabel"
								>
									{o.label[activeLanguage.code]}
								</label>
							</div>
						))}
					</div>
				</div>
			)}
		</Translate>
	)
}

export default withLocalize(RadioGroup)
