// Only used for GeoChart / translation
export const industries = [
	{
		title: {
			de: 'Autogewerbe',
			en: 'Automobile industry',
			fr: 'Industrie automobile',
			it: 'Settore automobilistico',
			es: 'Sector del automóvil',
		},
		value: 1,
	},
	{
		title: {
			de: 'Banken',
			en: 'Banking',
			fr: 'Banques',
			it: 'Bancario',
			es: 'Banca',
		},
		value: 2,
	},
	{
		title: {
			de: 'Bau',
			en: 'Construction',
			fr: 'Construction',
			it: 'Edile',
			es: 'Construcción',
		},
		value: 3,
	},
	{
		title: {
			de: 'Beratung/Revision',
			en: 'Consulting/Auditing',
			fr: 'Conseil/Audit',
			it: 'Consulenza/revisione contabile',
			es: 'Asesoría/auditoría',
		},
		value: 4,
	},
	{
		title: {
			de: 'Bund',
			en: 'Federal government',
			fr: 'Confédération',
			it: 'Governativo',
			es: 'Gobierno federal',
		},
		value: 5,
	},
	{
		title: {
			de: 'Chemie',
			en: 'Chemistry',
			fr: 'Chimie',
			it: 'Chimico',
			es: 'Química',
		},
		value: 6,
	},
	{
		title: {
			de: 'Detailhandel',
			en: 'Retail trade',
			fr: 'Commerce de détail',
			it: 'Vendita al dettaglio',
			es: 'Comercio minorista',
		},
		value: 7,
	},
	{
		title: {
			de: 'Druck und Grafik',
			en: 'Printing and graphics',
			fr: 'Imprimé et arts graphiques',
			it: 'Stampa e grafica',
			es: 'Imprenta y gráficos',
		},
		value: 8,
	},
	{
		title: {
			de: 'Elektroindustrie',
			en: 'Electronics industry',
			fr: 'Industrie électronique',
			it: "Settore dell'elettronica",
			es: 'Sector de la electrónica',
		},
		value: 9,
	},
	{
		title: {
			de: 'Gastronomie und Tourismus',
			en: 'Gastronomy and tourism',
			fr: 'Gastronomie et tourisme',
			it: 'Ristorazione e turismo',
			es: 'Gastronomía y turismo',
		},
		value: 10,
	},
	{
		title: {
			de: 'Gesundheitswesen',
			en: 'Health care sector',
			fr: 'Santé',
			it: 'Sanitario',
			es: 'Sector de la salud',
		},
		value: 11,
	},
	{
		title: {
			de: 'Informatik',
			en: 'Computer sciences',
			fr: 'Informatique',
			it: 'Informatica',
			es: 'Informática',
		},
		value: 12,
	},
	{
		title: {
			de: 'Kantone/Gemeinden',
			en: 'Cantons/communities',
			fr: 'Cantons/Communes',
			it: 'Cantoni/comunità',
			es: 'Cantones/comunidades',
		},
		value: 13,
	},
	{
		title: {
			de: 'Maschinen und Metallindustrie',
			en: 'Machinery and metals industry',
			fr: 'Industrie des machines et du métal',
			it: 'Industria meccanica e metallurgica',
			es: 'Sector de la maquinaria y los metales',
		},
		value: 14,
	},
	{
		title: {
			de: 'Medizinaltechnik',
			en: 'Medical technology',
			fr: 'Technologie médicale',
			it: 'Tecnologie mediche',
			es: 'Tecnología médica',
		},
		value: 15,
	},
	{
		title: {
			de: 'Militär',
			en: 'Military',
			fr: 'Armée',
			it: 'Militare',
			es: 'Ejército',
		},
		value: 16,
	},
	{
		title: {
			de: 'Nahrungsmittel',
			en: 'Food products',
			fr: 'Produits alimentaires',
			it: 'Prodotti alimentari',
			es: 'Productos alimenticios',
		},
		value: 17,
	},
	{
		title: {
			de: 'Pharma',
			en: 'Pharmaceuticals',
			fr: 'Industrie pharmaceutique',
			it: 'Farmaceutico',
			es: 'Farmacéutico',
		},
		value: 18,
	},
	{
		title: {
			de: 'Telecom',
			en: 'Telecommunications',
			fr: 'Télécommunications',
			it: 'Telecomunicazioni',
			es: 'Telecomunicaciones',
		},
		value: 19,
	},
	{
		title: {
			de: 'Textil',
			en: 'Textile industry',
			fr: 'Textile',
			it: 'Tessile',
			es: 'Sector textil',
		},
		value: 20,
	},
	{
		title: {
			de: 'Uhren/Feintechnik',
			en: 'Watches/precision technology',
			fr: 'Montres/Appareils de précision',
			it: 'Orologi/tecnologia di precisione',
			es: 'Relojes/tecnología de precisión',
		},
		value: 21,
	},
	{
		title: {
			de: 'Unterrichtswesen',
			en: 'Educational system',
			fr: 'Enseignement',
			it: 'Sistema formativo',
			es: 'Sistema educativo',
		},
		value: 22,
	},
	{
		title: {
			de: 'Verkehrsbetriebe',
			en: 'Transport services',
			fr: 'Transports',
			it: 'Servizi di trasporto',
			es: 'Servicios de transporte',
		},
		value: 23,
	},
	{
		title: {
			de: 'Versicherungen',
			en: 'Insurance industry',
			fr: 'Assurances',
			it: 'Assicurativo',
			es: 'Sector de los seguros',
		},
		value: 24,
	},
	{
		title: {
			de: 'Andere',
			en: 'Other',
			fr: 'Autre',
			it: 'Altro',
			es: 'Otro',
		},
		value: 25,
	},
	{
		title: {
			de: 'keine Angabe',
			en: 'Not specified',
			fr: 'non spécifié',
			it: 'Non specifico',
			es: 'Sin especificar',
		},
		value: 26,
	},
]

export const workplaces = [
	{
		title: {
			de: 'Aargau',
			en: 'Argovia',
			fr: 'Argovie',
			it: 'Argovia',
			es: 'Argovia',
		},
		value: 1,
	},
	// Fix
	{
		title: {
			de: 'Appenzell Ausserrhoden',
			en: 'Appenzell AR',
			fr: 'Appenzell Rhodes-Extérieures',
			it: 'Appenzello Esterno',
			es: 'Appenzell Rodas Exteriores',
		},
		value: 2,
	},
	// Fix
	{
		title: {
			de: 'Appenzell Innerrhoden',
			en: 'Appenzell IR',
			fr: 'Appenzell Rhodes-Intérieures',
			it: 'Appenzello Interno',
			es: 'Appenzell Rodas Interiores',
		},
		value: 3,
	},
	// Fix
	{
		title: {
			de: 'Basel-Landschaft',
			en: 'Suburbs of Basel',
			fr: 'Bâle-Campagne',
			it: 'Sobborghi di Basilea',
			es: 'Suburbios de Basilea',
		},
		value: 4,
	},
	// Fix
	{
		title: {
			de: 'Basel-Stadt',
			en: 'City of Basel',
			fr: 'Bâle-Ville',
			it: 'Città di Basilea',
			es: 'Ciudad de Basilea',
		},
		value: 5,
	},
	{
		title: {
			de: 'Bern',
			en: 'Berne',
			fr: 'Berne',
			it: 'Berna',
			es: 'Berna',
		},
		value: 7,
	},
	{
		title: {
			de: 'Freiburg',
			en: 'Freiburg',
			fr: 'Fribourg',
			it: 'Friburgo',
			es: 'Freiburg',
		},
		value: 8,
	},
	{
		title: {
			de: 'Genf',
			en: 'Geneva',
			fr: 'Genève',
			it: 'Ginevra',
			es: 'Ginebra',
		},
		value: 9,
	},
	{
		title: {
			de: 'Glarus',
			en: 'Glarus',
			fr: 'Glaris',
			it: 'Glarona',
			es: 'Glaris',
		},
		value: 10,
	},
	{
		title: {
			de: 'Graubünden',
			en: 'Grisons',
			fr: 'Grisons',
			it: 'Grigioni',
			es: 'Grisones',
		},
		value: 11,
	},
	{
		title: {
			de: 'Jura',
			en: 'Jura',
			fr: 'Jura',
			it: 'Giura',
			es: 'Jura',
		},
		value: 12,
	},
	{
		title: {
			de: 'Luzern',
			en: 'Lucerne',
			fr: 'Lucerne',
			it: 'Lucerna',
			es: 'Lucerna',
		},
		value: 13,
	},
	{
		title: {
			de: 'Neuenburg',
			en: 'Neuenburg',
			fr: 'Neuchâtel',
			it: 'Neuchâtel',
			es: 'Neuenburg',
		},
		value: 14,
	},
	{
		title: {
			de: 'Nidwalden',
			en: 'Nidwald',
			fr: 'Nidwald',
			it: 'Nidvaldo',
			es: 'Nidwalden',
		},
		value: 15,
	},
	{
		title: {
			de: 'Obwalden',
			en: 'Obwalden',
			fr: 'Obwald',
			it: 'Obvaldo',
			es: 'Obwalden',
		},
		value: 16,
	},
	{
		title: {
			de: 'Schaffhausen',
			en: 'Schaffhausen',
			fr: 'Schaffhouse',
			it: 'Sciaffusa',
			es: 'Schaffhausen',
		},
		value: 17,
	},
	{
		title: {
			de: 'Schwyz',
			en: 'Schwyz',
			fr: 'Schwytz',
			it: 'Svitto',
			es: 'Schwyz',
		},
		value: 18,
	},
	{
		title: {
			de: 'Solothurn',
			en: 'Solothurn',
			fr: 'Soleure',
			it: 'Soletta',
			es: 'Soleura',
		},
		value: 19,
	},
	{
		title: {
			de: 'St. Gallen',
			en: 'St. Gallen',
			fr: 'Saint-Gall',
			it: 'San Gallo',
			es: 'San Galo',
		},
		value: 21,
	},
	{
		title: {
			de: 'Tessin',
			en: 'Ticino',
			fr: 'Tessin',
			it: 'Ticino',
			es: 'Tesino',
		},
		value: 22,
	},
	{
		title: {
			de: 'Thurgau',
			en: 'Thurgau',
			fr: 'Thurgovie',
			it: 'Turgovia',
			es: 'Turgovia',
		},
		value: 23,
	},
	{
		title: {
			de: 'Uri',
			en: 'Uri',
			fr: 'Uri',
			it: 'Uri',
			es: 'Uri',
		},
		value: 24,
	},
	{
		title: {
			de: 'Waadt',
			en: 'Waadt',
			fr: 'Vaud',
			it: 'Vaud',
			es: 'Vaud',
		},
		value: 25,
	},
	{
		title: {
			de: 'Wallis',
			en: 'Valais',
			fr: 'Valais',
			it: 'Vallese',
			es: 'Valais',
		},
		value: 26,
	},
	{
		title: {
			de: 'Zürich',
			en: 'Zurich',
			fr: 'Zurich',
			it: 'Zurigo',
			es: 'Zúrich',
		},
		value: 28,
	},
	{
		title: {
			de: 'Zug',
			en: 'Zug',
			fr: 'Zoug',
			it: 'Zugo',
			es: 'Zug',
		},
		value: 29,
	},
	{
		title: {
			de: 'Liechtenstein',
			en: 'Liechtenstein',
			fr: 'Liechtenstein',
			it: 'Liechtenstein',
			es: 'Liechtenstein',
		},
		value: 30,
	},
]
