import React from 'react'
import { Translate } from 'react-localize-redux'

export const required = (value) =>
	value || value === 0 ? undefined : (
		<Translate id="validation.required"></Translate>
	)

export const requiredSelect = (selection) =>
	selection || selection === 0 || selection === null ? undefined : (
		<Translate id="validation.required"></Translate>
	)

export const email = (value) =>
	value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? (
		<Translate id="validation.email"></Translate>
	) : undefined

export const maxLength = (max) => (value) =>
	value && value.length > max ? (
		<Translate id="validation.maxLength" data={{ max }}></Translate>
	) : undefined

export const minValue = (min) => (value) =>
	value && value < min ? (
		<Translate id="validation.minValue" data={{ min }}></Translate>
	) : undefined

export const positiveInt = (value) =>
	Math.sign(value) !== 1 ? (
		<Translate id="validation.positiveInt"></Translate>
	) : undefined

const year = new Date().getFullYear()
const minYear = year - 65
const maxYear = year - 18

export const requiredYear = (value) =>
	value && value > minYear && value < maxYear ? undefined : (
		<Translate id="validation.birthday"></Translate>
	)
