import React from 'react'
import { Translate } from 'react-localize-redux'
import AsyncSelect from 'react-select/async'
import Select, { components } from 'react-select'
import createRenderer from '@components/FormField'
import './Select.css'

const CustomSelect = createRenderer((input, label, language, rest) => {
	let options = []

	if (rest.options) {
		rest.options.map((option) => {
			return options.push({
				label: option.title[language],
				value: option.value,
			})
		})
	}

	let value = ''

	if (rest.loadOptions && input.value) {
		value = input.value
	}

	if (options && options.length) {
		value = options.find((o) => o.value === input.value)
	}

	if (rest.favorites) {
		let favorites = []

		rest.favorites.map((option) => {
			return favorites.push({
				label: option.title[language],
				value: option.value,
			})
		})

		options = [
			{
				label: 'Favorites',
				options: favorites,
			},
			{
				options,
			},
		]
	}

	const DropdownIndicator = (props) => {
		return (
			components.DropdownIndicator && (
				<components.DropdownIndicator {...props}>
					<svg
						width="13"
						height="7"
						viewBox="0 0 13 7"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M12 1L6.76 5.9a.38.38 0 0 1-.26.1.38.38 0 0 1-.26-.1L1 1"
							stroke="#343A40"
							strokeWidth="1.5"
							fill="none"
							fillRule="evenodd"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</components.DropdownIndicator>
			)
		)
	}

	const customStyles = {
		placeholder: (defaultStyles) => {
			return {
				...defaultStyles,
				color: '#A0B4C5',
			}
		},
		indicatorSeparator: (provided) => ({
			...provided,
			display: 'none',
		}),
		control: (provided, state) => ({
			...provided,
			backgroundColor: state.isFocused ? '#fff' : 'transparent',
			border: 'none',
			borderRadius: 0,
			minHeight: 0,
			padding: '0.5rem 1rem 0.75rem',
			boxShadow: state.isFocused ? '0 2px 0 #0084ff' : '0',
		}),
		valueContainer: (provided) => ({
			...provided,
			padding: 0,
		}),
		dropdownIndicator: (provided) => ({
			...provided,
			padding: 0,
		}),
		menu: (provided) => ({
			...provided,
			margin: '0.5rem 0',
			boxShadow: '0 2px 16px rgba(0,0,0,0.16)',
			border: 0,
			borderRadius: '0.5rem',
		}),
		option: (provided) => ({
			...provided,
			borderRadius: '0.25rem',
			margin: '0 0.25rem',
			boxSizing: 'border-box',
			width: 'auto',
		}),
	}

	const formatGroupLabel = (data) =>
		data.label ? (
			<Translate>
				{({ translate }) => <div>{translate('favorites')}</div>}
			</Translate>
		) : (
			<hr className="fieldSelectDivider" />
		)

	const p = (translate) => ({
		...rest,
		inputId: input.name,
		name: input.name,
		className: 'fieldSelect',
		components: DropdownIndicator,
		searchable: true,
		defaultValue: value || 0,
		options: options && options.length ? options : [],
		onChange: (option) => {
			let value = option ? option.value : null

			if (option && rest.loadOptions) {
				value = option
			}

			input.onChange(value)
		},
		placeholder: translate('choose'),
		loadingMessage: () => translate('loadingMessage'),
		noOptionsMessage: ({ inputValue }) =>
			inputValue.length
				? translate('noOptions')
				: translate('startSearch'),
		styles: customStyles,
		formatGroupLabel: rest.favorites ? formatGroupLabel : null,
		//@TODO: "No options"-placeholder needs to be translated
	})

	return (
		<Translate>
			{({ translate }) =>
				rest.loadOptions ? (
					<AsyncSelect {...p(translate)} />
				) : (
					<Select {...p(translate)} />
				)
			}
		</Translate>
	)
})

export default CustomSelect
