import React from "react"

export default function MarketingEn() {
  return <>
    <h1>Data protection declaration of Matto-Group AG for the implementation of the salary check and the
      direct marketing derived from it
    </h1>
    <p>
      <ol>
        <li><strong>What is this privacy policy about?</strong></li>
        <li><strong>Who is responsible for processing your data?</strong></li>
        <li><strong>What personal data do we process?</strong></li>
        <li><strong>For what purposes and how do we process your personal data?</strong></li>
        <li><strong>With whom do we share your salary information?</strong></li>
        <li><strong>Do we disclose salary data abroad?</strong></li>
        <li><strong>How long do we process salary data?</strong></li>
        <li><strong>What are the legal bases for data processing?</strong></li>
        <li><strong>What rights do you have?</strong></li>
        <li><strong>Changes</strong></li>
      </ol>

    </p>
    <h1>1. What is this privacy policy about?</h1>
    <p>
      Matto-Group AG, General-Guisan-Str. 6, 6300 Zug, processes personal data that concerns you or
      other people in
      different ways and for different purposes. As far as we refer
      to &quot;Matto-Group&quot; or &quot;we&quot; below,
      this refers
      to Matto-Group AG (see section 2).
    </p>
    <p>
      &quot;Personal data&quot; is all information that can be associated with a specific person, and
      &quot;processing&quot; means any
      handling of it, e.g. obtaining, using, and disclosing it.
    </p>
    <p>
      This data protection declaration explains our processing of personal data (hereinafter data) if
    </p>
    <ul>
      <li> you carry out our salary analysis (subsequently salary check) on our website{' '}
        <a href='https://lohncheck.ch/de' target='_blank'
           rel='noopener noreferrer'>https://lohncheck.ch/de</a>{' '}
        or other platforms,
      </li>
      <li> you register for our newsletter,</li>
      <li> we or third parties carry out direct marketing activities based on the data you enter as
        part of the
        salary check.
      </li>
    </ul>
    <p>
      Please also note our general data protection declaration, which contains additional information,
      for example
      for the general use of our website or if you are in business contact with us. The general data
      protection
      declaration is available{' '}
      <a href='https://lohncheck.ch/en/datenschutz' target='_blank' rel='noopener noreferrer'>here</a>.

    </p>
    <p>
      To make it easier to read, this data protection declaration does not mention multiple genders,
      but we do mean
      to address people of all genders.
    </p>
    <p>
      Please take the time to read this privacy policy to learn how and why Matto-Group processes your
      personal data
      in connection with the salary check and how Matto-Group protects your personal data and what
      rights you have in
      this context. If you have any questions or would like further information about our data
      processing, we will be
      happy to help you (Section 2).
    </p>
    <p>
      We have aligned this data protection declaration with both the Swiss Data Protection Act (DSG)
      and the European
      General Data Protection Regulation (GDPR). If &quot;personal data&quot; is mentioned in the data
      protection declaration,
      this also includes &quot;personal data&quot; in accordance with the GDPR. Whether and to what
      extent the
      GDPR is
      applicable depends on the individual case
    </p>
    <h1>2. Who is responsible for processing your data?</h1>
    <p>
      The following company is the &quot;responsible party&quot; for data processing in accordance
      with this
      data protection
      declaration, i.e. the body primarily responsible for data protection law, unless otherwise
      communicated in
      individual cases (e.g. in further data protection declarations or on forms):
    </p>
    <p>
      Matto-Group AG <br/>
      General-Guisan-Str. 6 <br/>
      6300 Zug <br/>
      Switzerland <br/>
    </p>
    <p>
      If you have any questions about data protection, please feel free to contact the following
      address so that we
      can process your request as quickly as possible:
    </p>
    <p>
      <a href='mailto:datenschutz@lohncheck.ch' target='_blank'
         rel='noopener noreferrer'>datenschutz@lohncheck.ch</a>
    </p>
    <p>
      If you have any questions about the GDPR, you can also contact our EU representative in
      accordance with Art.
      27 GDPR:
    </p>
    <p>
      DP-Dock GmbH <br/>
      z.Hd. Matto-Group AG <br/>
      Ballindamm 39 <br/>
      20095 Hamburg <br/>
      Germany <br/>
    </p>
    <p>
      If you have any questions about the UK GDPR, you can also contact our UK representative:
    </p>
    <p>
      DP Data Protection Services UK Ltd. <br/>
      Attn: Matto-Group AG <br/>
      16 Great Queen Street <br/>
      Covent Garden <br/>
      London, WC2B 5AH <br/>
      United Kingdom <br/>
    </p>
    <p>
      The email address for inquiries to our local representatives in the EU and UK is:{' '}
      <a href='mailto:matto‑group@gdpr‑rep.com' target='_blank'
         rel='noopener noreferrer'>matto‑group@gdpr‑rep.com </a>
    </p>
    <h1>3. What personal data do we process? </h1>
    <p>
      For the purposes of the salary check and the advertising measures based on it, we process the
      following personal
      data, although this list is not exhaustive. We process the data you enter on our website to
      carry out the salary
      check. This data is only personal if you also provide your name or email address. You are not
      required to identify
      yourself or enter any data, but without the relevant data the salary check cannot be carried out
      or it will
      produce an inferior result. We primarily collect the following data (hereinafter collectively:
      wage check data):
    </p>
    <ul>
      <li> Information about yourself (e.g. gender, year of birth, nationality, place of residence,
        marital status,
        year of birth of children)
      </li>
      <li> Contact details such as email address or post address</li>
      <li> Information about your profession and education (e.g. diplomas, educational institution,
        highest level of
        education)
      </li>
      <li> Information about your professional experience (e.g. career level, work experience,
        management
        span, etc.)
      </li>
      <li>Information about your employer (e.g. company, industry, number of employees, orientation,
        legal form,
        country)
      </li>
      <li> Information about your job, salary, and income situation (e.g. gross income, workload, job
        title)
      </li>
      <li> Further information that you provide to us, for example, via the career coach
      </li>
    </ul>
    <p>
      We may also automatically and with the use of artificial intelligence extract relevant data from the CV and
      other documents you provide to us so that we can use this data to make more precise salary comparisons.
    </p>
    <p>
      To the extent that the data disclosed to us contains particularly sensitive personal data
      (also "special categories of personal data"), such as health data or information about political or trade
      union activities,
      we use this data for the purposes specified in this data protection declaration.
      In this case, we rely on your express consent through your disclosure (Art. 9 Para. 2 lit. a GDPR).
      With your separate consent, we can also collect comprehensive data from your LinkedIn profile,
      which LinkedIn makes available to us. This includes:
    </p>
    <ul>
      <li>
        Information about your account (e.g. when you opened your account and when you logged in, history of
        email addresses associated with the account, phone numbers associated with your account,
        information about calendars synced with LinkedIn),
      </li>
      <li>
        Information about activities on LinkedIn (e.g. which ads you have clicked on, who or which hashtags you
        follow, which photos, videos or documents you have shared, what or who you searched for and when,
        conclusions that LinkedIn draws from your profile or your activities on LinkedIn, comments made,
        updates liked, posts and articles written, contacts imported, events attended and other purchases
        related to your account, ratings and reviews of products, service providers and learning reviews,
        groups in which you participate, Invitations you have received or sent, jobs you have posted and positions
        you have applied for, preferred jobs, locations, industries and companies, learning videos you have
        watched,
        messages sent and received, job information saved, job alerts,
        recommendations that you have received or submitted)
      </li>
      <li>
        Information about your profile (e.g. publications, projects, volunteer activities, organizations,
        languages, awards, courses, certifications and other profile information,
        biographical information and skills, positions with companies and locations,
        schools and degrees, confirmations of your skills by other people) and
      </li>
      <li>
        Information about your connections (e.g. name, position, company and date of connection of the
        first-degree connections)
      </li>
    </ul>
    <p>
      We may receive additional personal information from third parties when they conduct a payroll check for
      someone else.
    </p>
    <p>
      If you provide us with information about other people, we will assume that you are authorized to do so and
      that the information is accurate. Please ensure that these third parties have been informed about this data
      protection declaration (e.g. through a reference to this data protection declaration).
    </p>
    <h1>4. For what purposes and how do we process your personal data?</h1>
    <h2>4.1. Carrying out the wage check and other online services </h2>
    <p>
      We use the wage check data we collect primarily to provide our online services, i.e. in
      particular to carry out
      wage calculations and analyses on our websites or platforms and to display relevant job
      advertisements on our pages.
      You may then have the option of forwarding your details to an appropriate recruiting partner (Section 5.3).
    </p>
    <h2>4.2. Personalized self- and third-party advertising </h2>
    <p>
      Based on your separate consent, the wage check data you provide on our website or platform may
      be used for
      advertising and marketing purposes. We ourselves may analyze your wage check data to show you
      personalized
      offers on our website or on third-party websites, as part of a specific target group (e.g.
      income, professional
      or age group). These offers may relate to goods or services from us or from partners. We may
      also share your
      wage check information with third parties for them to run related advertising campaigns. As a
      rule, this takes
      place without disclosing any personal data - this means that these third parties only receive
      your data
      pseudonymously (i.e. conclusions about your person are only possible if you are already known to
      these third
      parties, for example due to a login). We can also send your wage check data to our partners on a
      personal basis,
      in particular publishers, who can use your data under their own responsibility, for the purposes
      of personalized
      advertising for themselves and third parties. Further information can be found in section 5.
    </p>
    <p>
      or the purposes of personalizing advertising campaigns, we can compare your email addresses with
      the address
      database of the recipients named under Section 5 so that your wage check data from these
      recipients is in turn
      linked to other identifiers they have, such as UID2, EUID tokens or OneID and can be used
      accordingly. A UID2 or
      EUID token is a persistent, encrypted digital advertising identifier provided and controlled by
      the website user.
      The OneID is an encrypted, digital advertising ID that is created using an identifier such as
      your email address
      and allows us to recognize and specifically address you across different websites.
    </p>
    <p>
      You can object to our use of your wage check data for advertising purposes at any time by
      sending an email to:{' '}
      <a href='mailto:datenschutz@lohncheck.ch' target='_blank'
         rel='noopener noreferrer'>datenschutz@lohncheck.ch</a> or using
      the unsubscribe
      link in our newsletters and communications with effect for the future.
    </p>
    <p>
      It is also possible for us to link your wage check data with further information about you for
      the purposes of
      personalized advertising, both internally and externally, for example with data from publicly
      accessible
      sources such as public registers or from the Internet (website, social media, etc.). For further
      information
      on these data categories, please see our general data protection declaration.
    </p>
    <h2>4.3. Newsletter </h2>
    <p>
      Based on your separate consent, we may send you information about personalized products and
      services as well as
      job offers from our advertising partners via newsletter. We use your wage check data for this,
      but we may also
      use your behavioral and preference data. Further information about these categories of personal
      data can be found
      in our general data protection declaration, available{' '}
      <a href='https://lohncheck.ch/en/datenschutz' target='_blank' rel='noopener noreferrer'>here</a>.
      You can object to this sending at any time by sending an email to{' '}
      <a href='mailto:datenschutz@lohncheck.ch' target='_blank'
         rel='noopener noreferrer'>datenschutz@lohncheck.ch</a> or by
      clicking on
      the unsubscribe link in the respective message.
    </p>
    <h2>4.4. Other purposes </h2>
    <p>
      Finally, we may also process your wage check data for other purposes, such as optimizing our
      website, optimizing
      our statistical model for calculating pay and avoiding redundancies in our training data,
      enforcing the law and
      preventing crimes. Information about these additional purposes can be found in our general data
      protection
      declaration, which is available here; However, the corresponding explanations for further
      purposes also apply{' '}
      <a href='https://lohncheck.ch/en/datenschutz' target='_blank' rel='noopener noreferrer'>here</a>.
      However, the corresponding explanations for further purposes also apply here.
    </p>
    <h1>5. Who do we share your wage check details with? </h1>
    <h2>5.1. General </h2>
    <p>
      In connection with personalization and the display of advertising campaigns, we may also
      disclose your data to
      third parties based on your separate consent. These can be publishers (e.g. online publishing
      houses) or our
      advertising technology partners, who process the data either for us (if we run the advertising
      campaigns) or for
      their own purposes and therefore under their own responsibility (for in the event that they play
      out the
      advertising campaigns themselves).
    </p>
    <h2>5.2. Processing by third parties for their own purposes </h2>
    <p>
      In cases where we pass on your data to our partners, such as publishers, for processing for
      their own purposes,
      they are responsible for the further processing of your data themselves. You can find
      information about your
      data processing in the relevant data protection declaration of our partners. We and our partners
      may also be
      jointly responsible for collecting the data. In these cases, we will make a corresponding
      agreement, which you
      can find out about at the address given in section 2.
    </p>
    <h2>5.3. Announcement to recruiting partners </h2>
    <p>
      If you give us your consent, we will pass on your application information to our recruiting partners.
      This includes all career-relevant data from your resume or LinkedIn profile, such as name,
      contact information, education, qualifications, skills, professional experience and further training.
    </p>

    <h2>5.4.Announcement to advertising technology partners </h2>
    <p>
      We also share your information with our advertising technology partners so that we or they can show you
      personalized offers from us or our partners on our website or on other websites.
      These announcements are made to the following recipients. However, the following list is not exhaustive.
      We may also work with other advertising technology partners who offer the same or similar services to
      those listed below:
    </p>
    <ul>
      <li>
        Decentriq or Infosum: Decentriq and Infosum offer a solution called Data Clean Room,
        which allows different parties to jointly analyze aggregated data without identifying
        the people behind it. This system allows us to use data from various sources (typically publishers such
        as TX/Goldbach, Ringier or Audienzz) and to run advertising campaigns based on the insights gained,
        without us or the other companies gaining insight into the other parties' specific personal data.
        Rather, the data is analyzed in an encrypted environment so that it cannot be traced back to individual
        persons.
      </li>
      <li>
        Meta: Facebook Custom Audiences is a remarketing campaign in which the advertiser encrypts the email
        addresses of its customers collected and collected for this purpose using an algorithm and sends these
        non-personal identifiers (hash values) to Facebook transmitted. Facebook can compare this data with its
        inventory of user IDs and advertise the matches in a targeted manner. After the comparison, the
        transmitted hash values are deleted. This means that no email addresses are transmitted that were not
        already known to Facebook. In addition, Facebook can also address similar customers with similar
        interests based on the uploaded user lists. Further information about the scope of the collection and
        the further processing and use of the data by Facebook Custom Audiences can be found in Facebook's
        data protection guidelines, which are listed, among others, at <a
        href='https://www.facebook.com/privacy/explanation'
        target='_blank' rel='noopener noreferrer'>https://www.facebook.com/privacy/explanation</a>.
        The terms and conditions for Custom Audiences can be found at <a
        href='https://www.facebook.com/ads/manage/customaudiences/tos.php'
        target='_blank' rel='noopener noreferrer'>https://www.facebook.com/ads/manage/customaudiences/tos.php</a>.
        You can object to the collection and use of information for online advertising aimed at specific target
        groups using the following link: <a
        href='https://www.facebook.com/ads/website_custom_audiences/'
        target='_blank' rel='noopener noreferrer'>https://www.facebook.com/ads/website_custom_audiences</a>.
      </li>
      <li>
        Google: Google Customer Match essentially works like Facebook Custom Audiences (see above). Unlike
        Facebook Custom Audiences, we can also provide your data to Google in unencrypted form. In this case,
        Google takes care of the encryption (hashing) on our behalf before it compares the data. Based on the
        data comparison, Google can advertise to users of Google Search, YouTube and Gmail in a targeted manner
        and independently optimize advertising campaigns. The guidelines for the "Customer Match" function
        apply to the data provided by the advertiser: <a
        href='https://support.google.com/adwordspolicy/answer/6299717?hl=de'
        target='_blank' rel='noopener noreferrer'>https://support.google.com/adwordspolicy/answer/6299717?hl=de</a>.
        <br/>
        For further information, please see Google's privacy policy at: <a
        href='https://www.google.com/intl/de/policies/privacy/'
        target='_blank' rel='noopener noreferrer'>https://www.google.com/intl/de/policies/privacy</a>.
        You can edit your personal data and privacy settings under Google "My Account": <a
        href='https://myaccount.google.com/intro?hl=de'
        target='_blank' rel='noopener noreferrer'>https://myaccount.google.com/intro?hl=de</a>.
      </li>
      <li>
        The Trade Desk: With The Trade Desk we use a platform that makes it possible to compare data with ID
        solutions such as Unified ID 2.0 or EUID (encrypted User ID) or OneID. When you use the ID solutions,
        certain identifiers (such as your email address) are assigned to an encrypted ID. Using Trade Desk, we
        can assign your ID to specific target groups, display personalized advertising on our website
        and third-party websites and synchronize them across devices, thereby maximizing the reach of
        advertising campaigns on different media types (mobile devices, stationary devices, TV).
      </li>
      <li>
        OneID: We can compare your wage check data with the data you have stored with OneID (e.g. email address)
        and thereby receive the OneID assigned to you.
        We can link this ID with other wage check data in order to recognize you on our website and third-party
        websites and to address you across devices by showing you personalized offers from us or our
        advertising partners.
      </li>
    </ul>
    <h2>5.5.Other announcements </h2>
    <p>
      We also provide other service providers with the wage check data required for their services.
      This particularly affects our IT service providers. This also includes providers of data evaluation and
      analysis services,
      including OpenAI for extracting data from your CV and other documents provided to us using artificial
      intelligence.
      To the extent that these service providers process personal data as processors,
      they are obliged to process personal data exclusively in accordance with our instructions and to take
      measures to ensure data security.
      Data can also be disclosed to other recipients, e.g.
      to courts and authorities as part of procedures and legal information and cooperation obligations,
      to buyers of companies and assets, to financing companies for securitizations and to debt collection
      companies.
    </p>
    <p>
      Data can also be disclosed to other recipients, e.g. to courts and authorities as part of
      procedures and legal
      information and cooperation obligations, to buyers of companies and assets, to financing
      companies for
      securitizations and to debt collection companies.
    </p>

    <h1>6. Do we disclose wage check data abroad? </h1>
    <p>
      The recipients of data mentioned above are not only located in Switzerland. They may also be in
      the European
      Economic Area (EEA) (for example in Ireland), but also in other countries around the world
      (particularly the
      USA). Not all these states currently guarantee a level of data protection that corresponds to
      Swiss law. We
      compensate for the lower level of protection through appropriate contracts, in particular the
      standard
      contractual clauses issued by the European Commission and recognized by the Swiss Data
      Protection and Information
      Commissioner (FDPIC). Further details and a copy of these clauses can be found&nbsp;
      <a
        href='https://www.edoeb.admin.ch/edoeb/de/home/datenschutz/arbeit_wirtschaft/datenuebermittlung_ausland.html'
        target='_blank' rel='noopener noreferrer'>here</a>.
    </p>
    <p>
      In certain cases, we can transmit data in accordance with data protection regulations even
      without such contracts,
      e.g. if you have consented to the corresponding disclosure or if the disclosure is necessary for
      the execution
      of the contract, for the establishment, exercise, or enforcement of legal claims or for
      overriding public interests.
    </p>
    <p>
      Please note that data exchange over the Internet is often routed via third countries. In this
      case, your data
      can therefore end up abroad even if the sender and recipient are in the same country.
    </p>
    <h1>7. How long do we process wage check data?</h1>
    <p>
      We store and process your wage check data as long as it is necessary for the purpose of
      processing, as long as
      you do not revoke your consent or as long as we have a legitimate interest in storing it (e.g.
      to enforce legal
      claims, for archiving or to improve IT to ensure security) and as long as data is subject to a
      legal retention
      obligation. If there are no legal or contractual obligations to the contrary, we will destroy or
      anonymize your
      data after the storage or processing period has expired as part of our usual processes.
    </p>
    <h1>8. What are the legal bases for data processing? </h1>
    <p>
      Depending on the applicable law, data processing is only permitted if the applicable law
      specifically allows it.
      This does not apply under the Swiss Data Protection Act, but, for example, under the European
      General Data
      Protection Regulation (GDPR), to the extent that this applies. In this case, we base the
      processing of your wage
      check data on the following legal bases:
    </p>
    <ul>
      <li> Article 6, (1) (a) GDPR for processing that we carry out with your separate consent,
        in particular the procurement and processing mentioned in paragraphs 3 and 4.2 and the disclosure of data
        mentioned in paragraph 5.3;
      </li>
      <li>Aricle 6, (1) (b) GDPR for processing that is necessary for the preparation and
        implementation of our online
        services (in particular the implementation of wage analyzes and the other purposes mentioned
        in Section 4.1),
        if you decide to use these services gain weight;
      </li>
      <li>Article 6, (1) (f) GDPR for processing that is necessary to protect the legitimate interests
        of us or third
        parties, unless the fundamental freedoms and rights as well as the interests of the data
        subject outweigh this.
        This applies in particular to compliance with Swiss or European law, our interest in
        carrying out our activities
        and activities in a permanent, user-friendly, safe and reliable manner and the purposes
        according to section
        4.4;
      </li>
      <li> Article 6 (1) (c) GDPR for processing that is necessary to fulfil a legal obligation under
        the law of a
        member state of the European Economic Area (EEA). The EEA includes the EU states and
        Iceland, Norway and
        Liechtenstein.
      </li>
    </ul>
    <h1>9. What rights do you have? </h1>
    <p>
      You have certain rights within the scope and subject to the conditions or limitations of
      applicable data
      protection law so that you can obtain further information about and influence our data
      processing. These are
      the following rights:
    </p>
    <ul>
      <li>Right to information: You can request further information about our data processing. We are
        at your
        disposal for this. You can also submit a so-called information request if you would like
        further information
        and a copy of your data.
      </li>
      <li>Objection and deletion: You can object to our data processing with effect for the future and
        request that
        we delete your wage check data at any time. You can object to the processing of data for the
        purposes of direct
        advertising and the newsletter at any time.&nbsp;
        <a href='https://lohncheck.ch/de/daten-loeschen'
           target='_blank' rel='noopener noreferrer'>https://lohncheck.ch/de/daten-loeschen</a>
      </li>
      <li>Correction and restriction: You can correct or complete incorrect or incomplete wage check
        data and have
        the processing of your data restricted.
      </li>
      <li>Transfer: You also have the right to receive the wage check data that you have provided to
        us in a
        structured, common, and machine-readable format or to have it transferred to a third party,
        provided that the
        corresponding data processing is based on your consent or is necessary to fulfil the
        contract is.
      </li>
      <li>Revocation: If we process data based on your consent, you can revoke your consent at any
        time. The revocation
        only applies for the future, and we reserve the right to continue to process data in the
        event of a revocation
        based on another basis.
      </li>
    </ul>
    <p>
      Please note that these rights are subject to legal requirements and restrictions and are
      therefore not fully
      available in every case. We may need to further process and store your personal data to protect
      our own
      legitimate interests, such as the assertion, exercise, or defense of legal claims, or to comply
      with legal
      obligations. To the extent legally permissible, to protect the rights and freedoms of other data
      subjects and
      to safeguard legitimate interests, we can therefore reject a data subject&apos;s request in
      whole or
      in part (e.g.
      by blacking out certain content that concerns third parties or our business secrets).
    </p>
    <p>
      If you would like to exercise your rights against us, please contact us in writing. You can find
      our contact
      details in section 2. As a rule, we must check your identity (e.g. through a copy of your ID).
      You are also free
      to lodge a complaint against our processing of your data with the responsible supervisory
      authority. The
      responsible supervisory authority in Switzerland is the Federal Data Protection and Information
      Commissioner
      (FDPIC). Within the scope of the GDPR, you can contact the relevant national supervisory
      authorities.
    </p>

    <h1>10. Changes </h1>
    <p>
      We can adapt this data protection declaration at any time if necessary. The current version
      published on our
      website applies.
    </p>
    <p>
      As of: May 22, 2024
    </p>
  </>
}
