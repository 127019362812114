import React from 'react'
import TagManager from 'react-gtm-module-custom-domain/dist/TagManager'

TagManager.initialize({
	gtmId: 'GTM-NDG8H5M',
	customURL: 'https://cc.lohncheck.ch/gtm.js',
})

const Conditions = () => (
	<>
		<h1>AGB der Matto-Group AG</h1>

		<h2>Vertragsgegenstand und Geltungsbereich</h2>
		<p>
			Auf der Webseite Lohncheck.ch (nachfolgend Webseite) der Matto-Group
			AG werden Benutzern Lohnanalysen zugänglich gemacht.{' '}
		</p>

		<p>
			Verwenden Sie unsere Dienste nicht in missbräuchlicher Art und
			Weise. Sie sind beispielsweise nicht berechtigt, in die Dienste
			einzugreifen oder in anderer Weise als über die von Lohncheck
			bereitgestellte Benutzeroberfläche und gemäss unseren Vorgaben auf
			die Dienste zuzugreifen.{' '}
		</p>

		<p>
			Sie dürfen unsere Dienste nur in dem gesetzlich zulässigen Rahmen
			nutzen. Wir können die Bereitstellung unserer Dienste an Sie
			aussetzen oder einstellen, wenn Sie gegen unsere Nutzungsbedingungen
			oder Richtlinien verstossen oder wenn wir ein mutmassliches
			Fehlverhalten feststellen.{' '}
		</p>

		<p>
			Durch die Nutzung unserer Dienste erwerben Sie keinerlei gewerbliche
			Schutzrechte an unseren Diensten oder an den Inhalten, auf die Sie
			zugreifen. Sie dürfen Inhalte aus unseren Diensten nicht für eigene
			Publikationen nutzen, es sei denn, Sie verfügen explizit über die
			Einwilligung des Rechteinhabers. Diese Nutzungsbedingungen gewähren
			Ihnen kein Recht zur Nutzung von Marken, Markenelementen oder Logos,
			die in unseren Diensten verwendet werden. Rechtliche Hinweise, die
			in oder im Zusammenhang mit unseren Diensten angezeigt werden,
			dürfen nicht entfernt, unkenntlich gemacht oder verändert werden.{' '}
		</p>

		<h2>Vom Benutzer generierte Daten</h2>
		<p>
			Wenn Sie eine Lohnabfrage über unsere Dienste tätigen, räumen Sie
			Lohncheck das Recht ein, von Ihnen eingegebenen Inhalte weltweit zu
			verwenden, zu hosten, zu speichern, zu vervielfältigen, zu
			verändern, abgeleitete Werke daraus zu erstellen (einschliesslich
			solcher, die aus Übersetzungen, Anpassungen oder anderen Änderungen
			resultieren, die wir vornehmen, damit Ihre Inhalte besser in unseren
			Diensten funktionieren), zu kommunizieren, zu veröffentlichen,
			öffentlich aufzuführen, öffentlich anzuzeigen und zu verteilen.
			Diese von Ihnen im Rahmen dieser Lizenz gewährten Rechte dienen zur
			Durchführung, Förderung und Verbesserung unserer Dienste sowie zur
			Entwicklung neuer Dienste. Diese Rechtseinräumung bleibt auch dann
			bestehen, wenn Sie unsere Dienste nicht mehr verwenden.{' '}
		</p>

		<p>
			Weiter erlauben Sie Lohncheck mit der Nutzung des Lohnrechners, Sie
			über neue Produkte und Neuerungen am Angebot oder mit mit Inhalten
			zum Thema Karriere und Lohn per Mail zu informieren.{' '}
		</p>

		<h2>Datenschutz</h2>
		<p>
			Den Kunden unserer Produkte (des Lohnrechners), stellen wir einen
			kostenlosen Newsletter zu. Dieser kann zu jedem Zeitpunkt abbestellt
			werden. Jeder Newsletter beinhaltet einen Link zum Abbestellen.
			Weiter kann der Newsletter per Mail (an info@lohncheck.ch )
			abbestellt werden.{' '}
		</p>

		<h2>Datenschutz</h2>
		<p>
			In den <a href="/datenschutz">Datenschutzbestimmungen</a> wird
			erläutert, wie wir personenbezogene Daten speichern und Ihre Daten
			schützen wenn Sie unsere Services nutzen.{' '}
		</p>
	</>
)

export default Conditions
