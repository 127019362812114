import accounting from 'accounting'

import history from '@browser-history'
import ApiService from '@api'

// Types
const CALCULATE = 'CALCULATE'
const CALCULATE_SUCCESS = 'CALCULATE_SUCCESS'
const CALCULATE_FAILURE = 'CALCULATE_FAILURE'

// Actions
export const calculation = () => ({ type: CALCULATE })
export const calculationSuccess = (data) => ({ type: CALCULATE_SUCCESS, data })
export const calculationFailure = (error) => ({
	type: CALCULATE_FAILURE,
	error,
})

export const calculateSalary = (data) => (dispatch) => {
	dispatch(calculation())

	return ApiService.calculate(data)
		.then((data) => {
			dispatch(calculationSuccess(data))

			history.push(`/ergebnis${history.location.search}`)
			window.scrollTo(0, 0)
		})
		.catch((err) => {
			dispatch(calculationFailure(err))
		})
}

// Initial state
const initialState = {
	data: null,
	isFetching: false,
	error: false,
}

accounting.settings = {
	currency: {
		symbol: 'CHF',
		format: '%v %s',
		decimal: ',',
		thousand: '.',
		precision: 0,
	},
	number: {
		precision: 2,
		thousand: '.',
		decimal: ',',
	},
}

const format = (value) =>
	parseInt(accounting.formatMoney(value, '', 0, '', '').toString().trim(), 10)

// Reducers
const reducers = (state = initialState, action) => {
	switch (action.type) {
		case CALCULATE:
			return {
				...state,
				isFetching: true,
			}
		case CALCULATE_SUCCESS: {
			const { data } = action

			const workplaces = [
				[1, data.workplace_1.Lohn_50, data.workplace_1?.children],
				[2, data.workplace_2.Lohn_50, data.workplace_2?.children],
				[3, data.workplace_3.Lohn_50, data.workplace_3?.children],
				[4, data.workplace_4.Lohn_50, data.workplace_4?.children],
				[5, data.workplace_5.Lohn_50, data.workplace_5?.children],
				//
				[7, data.workplace_7.Lohn_50, data.workplace_7?.children], // Bern Land = 6, Bern Stadt = 7
				[8, data.workplace_8.Lohn_50, data.workplace_8?.children],
				[9, data.workplace_9.Lohn_50, data.workplace_9?.children],
				[10, data.workplace_10.Lohn_50, data.workplace_10?.children],
				[11, data.workplace_11.Lohn_50, data.workplace_11?.children],
				[12, data.workplace_12.Lohn_50, data.workplace_12?.children],
				[13, data.workplace_13.Lohn_50, data.workplace_13?.children],
				[14, data.workplace_14.Lohn_50, data.workplace_14?.children],
				[15, data.workplace_15.Lohn_50, data.workplace_15?.children],
				[16, data.workplace_16.Lohn_50, data.workplace_16?.children],
				[17, data.workplace_17.Lohn_50, data.workplace_17?.children],
				[18, data.workplace_18.Lohn_50, data.workplace_18?.children],
				[19, data.workplace_19.Lohn_50, data.workplace_19?.children],
				//
				[21, data.workplace_21.Lohn_50, data.workplace_21?.children], // St. Gallen Land = 20, St. Gallen Stadt = 21
				[22, data.workplace_22.Lohn_50, data.workplace_22?.children],
				[23, data.workplace_23.Lohn_50, data.workplace_23?.children],
				[24, data.workplace_24.Lohn_50, data.workplace_24?.children],
				[25, data.workplace_25.Lohn_50, data.workplace_25?.children],
				[26, data.workplace_26.Lohn_50, data.workplace_26?.children],
				//
				[28, data.workplace_28.Lohn_50, data.workplace_28?.children], // Zürich Land = 27, Zürich Stadt = 28
				[29, data.workplace_29.Lohn_50, data.workplace_29?.children],
				// Liechtenstein = 30
			]

			const industries = [
				[1, data.industry_1.Lohn_50],
				[2, data.industry_2.Lohn_50],
				[3, data.industry_3.Lohn_50],
				[4, data.industry_4.Lohn_50],
				[5, data.industry_5.Lohn_50],
				[6, data.industry_6.Lohn_50],
				[7, data.industry_7.Lohn_50],
				[8, data.industry_8.Lohn_50],
				[9, data.industry_9.Lohn_50],
				[10, data.industry_10.Lohn_50],
				[11, data.industry_11.Lohn_50],
				[12, data.industry_12.Lohn_50],
				[13, data.industry_13.Lohn_50],
				[14, data.industry_14.Lohn_50],
				[15, data.industry_15.Lohn_50],
				[16, data.industry_16.Lohn_50],
				[17, data.industry_17.Lohn_50],
				[18, data.industry_18.Lohn_50],
				[19, data.industry_19.Lohn_50],
				[20, data.industry_20.Lohn_50],
				[21, data.industry_21.Lohn_50],
				[22, data.industry_22.Lohn_50],
				[23, data.industry_23.Lohn_50],
				[24, data.industry_24.Lohn_50],
			]

			const sortedIndustries = industries.sort((a, b) => b[1] - a[1])

			const age = [
				[20, data.age_20.Lohn_50, null, null, null, null],
				[25, data.age_25.Lohn_50, null, null, null, null],
				[30, data.age_30.Lohn_50, null, null, null, null],
				[35, data.age_35.Lohn_50, null, null, null, null],
				[40, data.age_40.Lohn_50, null, null, null, null],
				[45, data.age_45.Lohn_50, null, null, null, null],
				[50, data.age_50.Lohn_50, null, null, null, null],
				[55, data.age_55.Lohn_50, null, null, null, null],
				[60, data.age_60.Lohn_50, null, null, null, null],
				[65, data.age_65.Lohn_50, null, null, null, null],
			]

			const yearly = [
				[
					10,
					data.default.Lohn_10 * 12,
					'area { color: #228ae6; opacity: 0.3 }',
				],
				[
					25,
					data.default.Lohn_25 * 12,
					'area { color: #228ae6; opacity: 0.3 }',
				],
				[
					50,
					data.default.Lohn_50 * 12,
					'area { color: #228ae6; opacity: 0.2 }',
				],
				[
					75,
					data.default.Lohn_75 * 12,
					'area { color: #228ae6; opacity: 0.2 }',
				],
				[
					90,
					data.default.Lohn_90 * 12,
					'area { color: #228ae6; opacity: 0.3 }',
				],
			]

			const workplaceCount = workplaces.length
			let total = 0

			for (var i = 0; i < workplaceCount; i++) {
				total += format(workplaces[i][1][0])
			}

			const average = total / workplaceCount

			return {
				...state,
				data: {
					...action.data,
					workplaces,
					industries: sortedIndustries,
					age,
					yearly,
					average,
				},
				isFetching: false,
			}
		}
		case CALCULATE_FAILURE:
			return {
				...state,
				error: action.error,
				isFetching: false,
			}
		default:
			return state
	}
}

export default reducers
