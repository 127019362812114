import React from "react"

export default function MarketingDe() {
  return <>
    <h1>Datenschutzerklärung der Matto-Group AG zur Durchführung des Lohnchecks und des darauf
      basierenden Direktmarketings
    </h1>
    <p>
      <ol>
        <li><strong>Worum geht es in dieser Datenschutzerklärung?</strong></li>
        <li><strong>Wer ist für die Bearbeitung Ihrer Daten verantwortlich?</strong></li>
        <li><strong>Welche Personendaten bearbeiten wir?</strong></li>
        <li><strong>Zu welchen Zwecken und wie bearbeiten wir Ihre Personendaten?</strong></li>
        <li><strong>Wem geben wir Ihre Lohncheck-Daten bekannt?</strong></li>
        <li><strong>Geben wir Lohncheck-Daten ins Ausland bekannt?</strong></li>
        <li><strong>Wie lange bearbeiten wir Lohncheck-Daten?</strong></li>
        <li><strong>Auf welchen Rechtsgrundlagen beruht die Datenbearbeitung?</strong></li>
        <li><strong>Welche Rechte haben Sie?</strong></li>
        <li><strong>Änderungen</strong></li>
      </ol>

    </p>
    <h1>1. Worum geht es in dieser Datenschutzerklärung?</h1>
    <p>
      Die Matto-Group AG, General-Guisan-Str. 6, 6300 Zug, bearbeitet Personendaten, die Sie oder
      andere Personen
      betreffen, in unterschiedlicher Weise und für unterschiedliche Zwecke. Soweit wir nachstehend
      von
      «<strong>Matto-Group</strong>» oder «<strong>wir</strong>» sprechen, ist damit jeweils die
      Matto-Group AG
      gemeint (siehe dazu Ziffer<strong>2</strong>).
    </p>
    <p>
      «Personendaten» sind alle Informationen, die mit einer bestimmten Person in Verbindung gebracht
      werden können,
      und «bearbeiten» meint jeden Umgang damit, z.B. die Beschaffung, Verwendung und Bekanntgabe.
    </p>
    <p>
      Diese Datenschutzerklärung erläutert unsere Bearbeitung von Personendaten (anschliessend
      <strong>Daten</strong>), wenn
    </p>
    <ul>
      <li> Sie auf unserer Webseite <a href='https://lohncheck.ch/de'
                                       target='_blank' rel='noopener noreferrer'>https://lohncheck.ch/de</a>
        &nbsp;
        oder weiteren Plattformen unsere Lohnanalyse (anschliessend <strong>Lohncheck</strong>)
        durchführen,
      </li>
      <li> Sie sich für unseren Newsletter registrieren</li>
      <li> wir oder Dritte Direktmarketingmassnahmen basierend auf den von Ihnen im Rahmen des
        Lohnchecks
        eingegebenen Daten durchführen.
      </li>
    </ul>
    <p>
      Bitte beachten Sie auch unsere <strong>allgemeine Datenschutzerklärung</strong>, die zum
      Beispiel für die
      allgemeine Nutzung unserer Webseite oder wenn Sie mit uns in geschäftlichen Kontakt stehen oder
      sich bewerben
      ergänzende Hinweise enthält. Die allgemeine Datenschutzerklärung ist&nbsp;
      <a href='https://lohncheck.de/de/datenschutz' target='_blank'
         rel='noopener noreferrer'>hier</a>&nbsp;abrufbar.
    </p>
    <p>
      Zur einfacheren Lesbarkeit wird in dieser Datenschutzerklärung auf die Nennung mehrerer
      Geschlechter verzichtet.
      Wir meinen aber jeweils Personen aller Geschlechter.
    </p>
    <p>
      Bitte nehmen Sie sich Zeit, die vorliegende Datenschutzerklärung zu lesen, um zu erfahren, wie
      und warum
      Matto-Group Ihre Personendaten im Zusammenhang mit dem Lohncheck bearbeitet und wie Matto-Group
      Ihre
      Personendaten schützt und welche Rechte Sie in diesem Zusammenhang haben. Falls Sie Fragen haben
      oder weitere
      Informationen zu unserer Datenbearbeitung wünschen, stehen wir Ihnen gerne zur Verfügung (Ziffer<b>2</b>).

    </p>
    <p>
      Wir haben diese Datenschutzerklärung sowohl am schweizerischen Datenschutzgesetz (<b>DSG</b>)
      als auch an der
      Europäischen Datenschutz-Grundverordnung (<b>DSGVO</b>) ausgerichtet. Wird in der
      Datenschutzerklärung von
      «<b>Personendaten</b>» gesprochen, sind damit auch «<b>personenbezogene Daten</b>» gemäss DSGVO
      mitgemeint. Ob
      und inwieweit die DSGVO anwendbar ist, hängt aber vom Einzelfall ab.
    </p>
    <h1>2. Wer ist für die Bearbeitung Ihrer Daten verantwortlich?</h1>
    <p>
      Für die Datenbearbeitungen nach dieser Datenschutzerklärung ist folgende Gesellschaft die
      «Verantwortliche»,
      d.h. die datenschutzrechtlich in erster Linie zuständige Stelle, soweit im Einzelfall nichts
      Anderes
      kommuniziert wird (z.B. in weiteren Datenschutzerklärungen oder auf Formularen):
    </p>
    <p>
      Matto-Group AG <br/>
      General-Guisan-Str. 6 <br/>
      6300 Zug <br/>
      Schweiz <br/>
    </p>
    <p>
      Wenn Sie Fragen zum Datenschutz haben, können Sie sich gerne an folgende Adresse wenden, damit
      wir Ihr
      Anliegen am raschesten bearbeiten können:
    </p>
    <p>
      <a href='mailto:datenschutz@lohncheck.ch' target='_blank'
         rel='noopener noreferrer'>datenschutz@lohncheck.ch</a>
    </p>
    <p>
      Für Fragen zur DSGVO können Sie sich sodann auch an unseren EU-Vertreter nach Art. 27 DSGVO
      wenden:
    </p>
    <p>
      DP-Dock GmbH <br/>
      z.Hd. Matto-Group AG <br/>
      Ballindamm 39 <br/>
      20095 Hamburg <br/>
      Deutschland <br/>
    </p>
    <p>
      Für Fragen zur UK-DSGVO können Sie sich auch unseren UK-Vertreter wenden:
    </p>
    <p>
      DP Data Protection Services UK Ltd. <br/>
      Attn: Matto-Group AG <br/>
      16 Great Queen Street <br/>
      Covent Garden <br/>
      London, WC2B 5AH <br/>
      United Kingdom <br/>
    </p>
    <p>
      Die E-Mailadresse für Anfragen an unsere lokalen Vertreter in der EU und UK
      lautet jeweils:&nbsp;<a href='mailto:matto‑group@gdpr‑rep.com'
                              target='_blank' rel='noopener noreferrer'>matto‑group@gdpr‑rep.com </a>
    </p>
    <h1>3. Welche Personendaten bearbeiten wir? </h1>
    <p>
      Zu Zwecken des Lohnchecks und den darauf basierenden Werbemassnahmen bearbeiten wir die
      folgenden Personendaten,
      wobei diese Aufzählung nicht abschliessend sein muss. Wir bearbeiten die Daten, die Sie auf
      unserer Webseite
      eingeben, um den Lohncheck durchzuführen. Diese Daten sind nur personenbezogen, wenn Sie dabei
      auch Ihren Namen
      oder Ihre E-Mail-Adresse angeben. Sie sind nicht verpflichtet, sich zu identifizieren oder alle
      Daten einzugeben,
      aber ohne die entsprechenden Daten kann der Lohncheck nicht durchgeführt werden, oder er führt
      zu einem
      schlechteren Ergebnis. Wir erheben vor allem die folgenden Daten (nachfolgend
      insgesamt: <b>Lohncheck-Daten</b>):
    </p>
    <ul>
      <li> Angaben zu Ihrer Person (z.B. Geschlecht, Geburtsjahr, Nationalität, Wohnort, Zivilstand,
        Geburtsjahr
        der Kinder)
      </li>
      <li> Kontaktangaben wie E-Mail-Adresse oder Adresse</li>
      <li> Angaben zu Ihrem Beruf und Ihrer Ausbildung (z.B. Diplome, Bildungsinstitution, höchste
        Ausbildung)
      </li>
      <li> Angaben zu Ihrer Berufserfahrung (z.B. Karrierelevel, Arbeitserfahrung, Führungsspanne
        etc.)
      </li>
      <li> Angaben zu Ihrem Arbeitgeber (z.B. Firma, Branche, Anzahl Mitarbeiter, Ausrichtung,
        Gesellschaftsform,
        Land)
      </li>
      <li> Angaben zu Ihrem Beruf, Gehalt und Ihrer Einkommenssituation (z.B. Bruttoeinkommen, Pensum,
        Stellenbezeichnung)
      </li>
    </ul>
    <p>
      Wir erhalten die Lohncheck-Daten i.d.R. von Ihnen. Wir können aber auch Personendaten von
      Dritten erhalten,
      wenn diese für jemand anderen einen Lohncheck durchführen. Wenn Sie uns Daten über andere
      Personen übermitteln
      bzw. bekanntgeben, gehen wir davon aus, dass Sie befugt dazu sind und dass diese Daten richtig
      sind. Bitte
      stellen Sie sicher, dass diese Dritten über diese Datenschutzerklärung informiert wurden (z.B.
      durch einen
      Hinweis auf diese Datenschutzerklärung).
    </p>
    <h1>4. Zu welchen Zwecken und wie bearbeiten wir Ihre Personendaten?</h1>
    <h2>4.1. Durchführung des Lohnchecks und weitere Online-Dienstleistungen </h2>
    <p>
      Wir verwenden die von uns erhobenen Lohncheck-Daten in erster Linie für die Erbringung unserer
      Online-Dienstleistungen, d.h. insbesondere zur Durchführung von Lohnberechnungen und -analysen
      auf unseren
      Webseiten bzw. Plattformen und zur Anzeige von relevanten Jobinseraten auf unseren Seiten.
    </p>
    <h2>4.2. Personalisierte Eigen- und Fremdwerbung </h2>
    <p>
      Basierend auf Ihrer separaten Einwilligung können die von Ihnen auf unserer Webseite bzw.
      Plattform gemachten
      Lohncheck-Daten zu Werbe- und Marketingzwecken genutzt werden. Wir selbst können Ihre
      Lohncheck-Daten
      analysieren, um Ihnen darauf basierend personalisierte Angebote auf unserer Webseite oder auf
      Webseiten Dritter
      anzuzeigen, als Teil einer bestimmten Zielgruppe (z.B. Einkommens-, Berufs- oder Altersgruppe).
      Diese Angebote
      können sich auf Waren oder Dienstleistungen von uns oder auch von Partnern beziehen. Wir können
      Ihre
      Lohncheck-Daten auch an Dritte weitergeben, damit diese entsprechende Werbekampagnen
      durchführen. In der Regel
      erfolgt dies ohne Bekanntgabe von Personendaten – das heisst, dass diese Dritten Ihre Daten nur
      pseudonymisiert
      erhalten (d.h. Rückschlüsse auf Ihre Person sind nur möglich, wenn Sie diesen Dritten z.B.
      aufgrund eines Logins
      schon bekannt sind). Wir können Ihre Lohncheck-Daten aber auch personenbezogen an unsere
      Partner, insbesondere
      Publisher, weitergeben, die Ihre Daten in eigener Verantwortung, insbesondere zu Zwecken der
      personalisierten
      Eigen- und Fremdwerbung, nutzen können. Weitere Informationen finden Sie in Ziffer<b>5</b>.
    </p>
    <p>
      Zu Zwecken der Personalisierung von Werbekampagnen können wir Ihre E-Mail Adressen mit dem
      Adressbestand der
      unter Ziffer<b>5</b> benannten Empfänger abgleichen, damit Ihre Lohncheck-Daten von diesen
      Empfängern
      ihrerseits mit ihnen vorliegenden, weiteren Identifikatoren wie insbesondere UID2-, EUID-Token
      oder OneID
      verbunden und entsprechend genutzt werden können. Ein UID2 oder EUID-Token ist ein vom
      Webseitennutzer
      bereitgestellter und kontrollierter, dauerhafter, verschlüsselter Identifikator für digitale
      Werbung. Die OneID
      ist eine verschlüsselte, digitale Werbe-ID, die anhand eines Identifikators wie beispielsweise
      Ihrer
      E-Mailadresse erstellt wird und es uns erlaubt, Sie plattformübergreifend auf verschiedenen
      Webseiten
      wiederzuerkennen und gezielt anzusprechen.
    </p>
    <p>
      Sie können der Verwendung Ihrer Lohncheck-Daten durch uns zu Werbezwecken jederzeit per E-Mail
      an&nbsp;
      <a href='mailto:datenschutz@lohncheck.ch' target='_blank'
         rel='noopener noreferrer'>datenschutz@lohncheck.ch</a>&nbsp;bzw.
      mit
      dem Abmelde-Link
      in unseren Newslettern und Mitteilungen mit Wirkung für die Zukunft widersprechen.
      <p>
        <p>
          Es ist auch möglich, dass wir Ihre Lohncheck-Daten zu Zwecken der personalisierten
          Eigen- und Fremdwerbung mit
          weiteren Informationen über Sie verknüpfen, beispielsweise mit Daten aus öffentlich
          zugänglichen Quellen wie
          beispielsweise öffentliche Register oder aus dem Internet (Webseite, Social Media etc.).
          Weitere Informationen zu
          diesen Datenkategorien entnehmen Sie bitte unserer allgemeine Datenschutzerklärung.
        </p>
        <h2>4.3. Newsletter </h2>
        <p>
          Basierend auf Ihrer separaten Einwilligung können wir Ihnen Informationen zu
          personalisierten Produkten und
          Dienstleistungen sowie Stellenangeboten unserer Werbepartner per Newsletter zukommen
          lassen. Dafür nutzen wir
          Ihre Lohncheck-Daten, können aber auch Ihre Verhaltens und Präferenzdaten verwenden.
          Weitere Informationen zu
          diesen Kategorien von Personendaten finden Sie in unserer allgemeinen
          Datenschutzerklärung, abrufbar&nbsp;
          <a href='https://lohncheck.de/de/datenschutz' target='_blank' rel='noopener noreferrer'>hier</a>.
          Sie können diesem Versand jederzeit per E-Mail an&nbsp;
          <a href='mailto:datenschutz@lohncheck.ch'
             target='_blank' rel='noopener noreferrer'>datenschutz@lohncheck.ch</a> bzw. durch Betätigung des
          Abmelde-Links in der jeweiligen Mitteilung widersprechen.
        </p>
        <h2>4.4. Weitere Zwecke </h2>
        <p>
          Schliesslich können wir Ihre Lohncheck-Daten auch zu weiteren Zwecken bearbeiten,
          beispielsweise zur Optimierung
          unserer Webseite, zur Optimierung unseres statistischen Modell zur Lohnberechnung und
          Vermeidung von Redundanzen
          in unseren Trainingsdaten, zur Rechtsdurchsetzung und zu Verhinderung von Straftaten.
          Informationen zu diesen
          weiteren Zwecken finden Sie in unserer allgemeinen Datenschutzerklärung, die&nbsp;
          <a href='https://lohncheck.de/de/datenschutz' target='_blank'
             rel='noopener noreferrer'>hier</a>&nbsp;abrufbar
          ist; die entsprechenden Erläuterungen zu den weiteren Zwecken gelten aber auch
          hier.
        </p>
        <h1>5. Wem geben wir Ihre Lohncheck-Daten bekannt? </h1>
        <h2>5.1. Allgemeines </h2>
        <p>
          Im Zusammenhang mit der Personalisierung und dem Ausspielen von Werbekampagnen können
          wir Ihre Daten, basierend
          auf Ihrer separaten Einwilligung, auch an Dritte bekannt geben. Dabei kann es sich um
          Publisher (z.B.
          Online-Verlagshäuser) oder unseren Werbetechnologie-Partner handeln, welche die Daten
          entweder für uns (für den
          Fall, dass wir die Werbekampagnen ausspielen) oder aber auch zu eigenen Zwecken und
          damit in eigener
          Verantwortung bearbeiten (für den Fall, dass diese die Werbekampagnen selbst
          ausspielen).
        </p>
        <h2>5.2. Bearbeitung durch Dritte zu eigenen Zwecken </h2>
        <p>
          In Fällen, in denen wir Ihre Daten an unsere Partner wie beispielsweise Publisher zur
          Bearbeitung zu eigenen
          Zwecken weitergeben, sind diese für die weitere Bearbeitung Ihrer Daten selbst
          verantwortlich. Informationen
          zu ihrer Datenbearbeitung finden Sie in der entsprechenden Datenschutzerklärung unserer
          Partner. Für die
          Erhebung der Daten können wir und unsere Partner auch gemeinsam verantwortlich sein. In
          diesen Fällen treffen
          wir eine entsprechende Vereinbarung, über die Sie sich bei der unter
          Ziffer<b>2</b> angegebenen Adresse
          informieren können.
        </p>
        <h2>5.3. Bekanntgabe an Werbetechnologie-Partner </h2>
        <p>
          Wir geben Ihre Daten auch unsere Werbetechnologie-Partner bekannt, damit wir oder diese
          Ihnen personalisierte
          Angebote von uns oder unseren Partnern auf unserer Webseite oder auf anderen Webseiten
          anzeigen können. Diese
          Bekanntgaben erfolgen insbesondere an die folgenden Empfänger. Die nachfolgende Liste
          ist aber nicht
          abschliessend. Es ist möglich, dass wir auch mit weiteren Werbetechnologie-Partnern
          zusammenarbeiten, die
          gleiche oder ähnliche Leistungen wie die nachfolgend genannten anbieten:
        </p>
        <ul>
          <li><strong>Decentriq oder Infosum</strong>: Decentriq und Infosum bieten eine Lösung
            namens Data Clean Room,
            die es ermöglicht, dass verschiedene Parteien gemeinsam aggregierte Daten
            analysieren können, ohne die
            dahinterstehenden Personen zu identifizieren. Dieses System ermöglicht es uns, Daten
            aus verschiedenen Quellen
            (typischerweise Publisher wie TX/ Goldbach, Ringier oder Audienzz) zu nutzen und auf
            Grund der gewonnen
            Erkenntnisse Werbekampagnen auszuspielen, ohne dass wir oder die anderen Unternehmen
            Einblick in die konkreten
            Personendaten der anderen Parteien erhalten. Die Daten werden vielmehr in einem
            verschlüsselten Umfeld
            analysiert, sodass sie nicht auf individuelle Personen zurückgeführt werden können.
          </li>

          <li><strong>Meta</strong>: Bei Facebook Custom Audiences handelt es sich um eine
            Remarketing-Kampagne, bei der
            der Werbetreibende die zu diesem Zweck erhobenen und gesammelten E-Mail-Adressen
            seiner Kunden mit Hilfe eines
            Algorithmus verschlüsselt und diese nicht-personenbezogenen Identifikatoren
            (Hashwerte) an Facebook übermittelt.
            Facebook kann diese Daten mit seiner Bestandsliste von User-IDs abgleichen und die
            Übereinstimmungen
            zielgerichtet bewerben. Nach dem Abgleich werden die übermittelten Hashwerte
            gelöscht. Dadurch werden keine
            E-Mail-Adressen übermittelt, die Facebook nicht bereits bekannt waren. Zusätzlich
            kann Facebook auf Basis
            der hochgeladenen Nutzerlisten auch ähnliche Kunden mit ähnlichen Interessen
            ansprechen. Weitere Auskünfte über
            den Umfang der Erhebung und die weitere Verarbeitung und Nutzung der Daten durch
            Facebook Custom Audiences
            können Sie den Datenschutzrichtlinien von Facebook entnehmen, welche u.a.
            unter&nbsp;
            <a href='https://www.facebook.com/privacy/explanation'
               target='_blank'
               rel='noopener noreferrer'>https://www.facebook.com/privacy/explanation</a>&nbsp;aufgeführt
            sind. Die Bedingungen für Custom Audiences finden Sie unter
            &nbsp;<a href='https://www.facebook.com/ads/manage/customaudiences/tos.php'
                     target='_blank'
                     rel='noopener noreferrer'>https://www.facebook.com/ads/manage/customaudiences/tos.php</a>.
            Unter folgendem Link können Sie der Sammlung und Verwendung von Informationen für
            an bestimmte Zielgruppen
            gerichtete Online-Werbung widersprechen:
            &nbsp;<a href='https://www.facebook.com/ads/website_custom_audiences'
                     target='_blank'
                     rel='noopener noreferrer'>https://www.facebook.com/ads/website_custom_audiences</a>.
          </li>
          <li><b>Google</b>: Google Customer Match funktioniert im Wesentlichen wie Facebook
            Custom Audiences (siehe oben).
            Anders als bei Facebook Custom Audiences können wir Ihre Daten aber auch in
            unverschlüsselter Form an Google
            bekanntgeben. Google übernimmt in diesem Fall in unserem Auftrag die Verschlüsselung
            (das «hashing»), bevor es
            den Datenabgleich vornimmt. Basierend auf dem Datenabgleich kann Google Nutzer der
            Google-Suche, YouTube und
            Gmail zielgerichtet bewerben und Werbekampagnen selbstständig optimieren. Für die
            vom Werbenden zur Verfügung gestellten Daten gelten die Richtlinien zur Funktion
            «Kundenabgleich»:&nbsp;
            <a href='https://support.google.com/adwordspolicy/answer/6299717?hl=de'
               target='_blank'
               rel='noopener noreferrer'>https://support.google.com/adwordspolicy/answer/6299717?hl=de</a>
          </li>
        </ul>
        <p>
          Weiterführende Informationen entnehmen Sie bitte der Datenschutzerklärung von Google
          unter:&nbsp;
          <a href='https://www.google.com/intl/de/policies/privacy'
             target='_blank' rel='noopener noreferrer'>https://www.google.com/intl/de/policies/privacy</a>.
          Die
          Einstellungen zu persönlichen Daten
          und Privatsphäre können Sie unter Google «Mein Konto» bearbeiten:&nbsp;
          <a href='https://myaccount.google.com/intro?hl=de'
             target='_blank' rel='noopener noreferrer'>https://myaccount.google.com/intro?hl=de</a>.
        </p>
        <ul>
          <li><strong>The Trade Desk</strong>: Mit The Trade Desk nutzen wir eine Plattform, die
            es ermöglicht, Daten
            mit ID-Lösungen wie Unified ID 2.0 oder EUID (encrypted User ID) oder OneID
            abzugleichen. Wenn Sie die
            ID-Lösungen nutzen, werden bestimmte Identifikatoren (wie beispielsweise Ihre
            E-Mail-Adresse) einer
            verschlüsselten ID zugeordnet. Mittels Trade Desk können wir Ihre ID einer
            bestimmten Zielgruppen zuordnen,
            personalisierte Werbung auf unserer Webseite und Webseiten Dritter ausspielen und
            diese geräteübergreifend
            synchronisieren, wodurch die Reichweite von Werbekampagnen auf verschiedenen
            Mediengattungen (Mobilgerät,
            Stationäre Geräte, TV) maximiert werden kann.
          </li>
          <li><strong>OneID</strong>: Wir können Ihre Lohncheck-Daten mit Ihren bei OneID
            hinterlegten Daten abgleichen
            (z.B. E-Mail Adresse) und dadurch die Ihnen zugeordnete OneID erhalten. Diese ID
            können wir mit weiteren
            Lohncheck-Daten verknüpfen, um Sie auf unserer Webseite und Webseiten Dritter
            wiederzuerkennen und
            geräteübergreifend anzusprechen, indem wir Ihnen personalisierte Angebote von uns
            oder unseren Werbepartnern
            anzeigen.
          </li>
        </ul>
        <h2>5.4. Sonstige Bekanntgaben </h2>
        <p>
          Wir geben auch weiteren Dienstleistern die für ihre Leistungen erforderlichen
          Lohncheck-Daten bekannt. Das
          betrifft insbesondere unsere IT-Dienstleister. Soweit diese Dienstleister Personendaten
          als Auftragsbearbeiter
          bearbeiten, sind sie verpflichtet, Personendaten ausschliesslich nach unseren
          Instruktionen zu bearbeiten und
          Massnahmen zur Datensicherheit zu treffen.
        </p>
        <p>
          Daten können auch an andere Empfänger bekanntgegeben werden, z.B. an Gerichte und
          Behörden im Rahmen von
          Verfahren und gesetzlichen Informations- und Mitwirkungspflichten, an Käufer von
          Unternehmen und
          Vermögenswerten, an Finanzierungsgesellschaften bei Verbriefungen und an
          Inkassounternehmen.
        </p>
        <h1>6. Geben wir Lohncheck-Daten ins Ausland bekannt? </h1>
        <p>
          Die oben genannten Empfänger von Daten befinden sich nicht nur in der Schweiz. Sie
          können sich auch im
          Europäischen Wirtschaftsraum (EWR) befinden (zum Beispiel in Irland), aber auch in
          anderen Ländern weltweit
          (insbesondere in den USA). Nicht alle dieser Staaten gewährleisten derzeit ein dem
          schweizerischen Recht
          entsprechendes Datenschutzniveau. Wir gleichen den niedrigeren Schutz durch
          entsprechende Verträge aus,
          besonders die von der Europäischen Kommission ausgestellten und dem schweizerischen
          Datenschutz- und
          Öffentlichkeitsbeauftragten (EDÖB) anerkannten Standardvertragsklauseln. Weitere Angaben
          dazu und eine Kopie
          dieser Klauseln finden Sie&nbsp;
          <a
            href='https://www.edoeb.admin.ch/edoeb/de/home/datenschutz/arbeit_wirtschaft/datenuebermittlung_ausland.html'
            target='_blank' rel='noopener noreferrer'>hier</a>.
        </p>
        <p>
          In bestimmten Fällen können wir Daten im Einklang mit datenschutzrechtlichen Vorgaben
          auch ohne solche Verträge
          übermitteln, z.B. wenn Sie in die entsprechende Bekanntgabe eingewilligt haben oder wenn
          die Bekanntgabe für die
          Vertragsabwicklung, für die Feststellung, Ausübung oder Durchsetzung von
          Rechtsansprüchen oder für überwiegende
          öffentlichen Interessen erforderlich ist.
        </p>
        <p>
          Beachten Sie, dass der Datenaustausch über das Internet häufig über Drittstaaten
          geleitet wird. Ihre Daten
          können in diesem Fall deshalb auch dann ins Ausland gelangen, wenn sich Absender und
          Empfänger im gleichen Land
          befinden.
        </p>
        <h1>7. Wie lange bearbeiten wir Lohncheck-Daten? </h1>
        <p>
          Wir speichern und bearbeiten Ihre Lohncheck-Daten, solange es für den Zweck der
          Bearbeitung erforderlich ist,
          solange Sie Ihre Einwilligung nicht widerrufen oder solange wir ein berechtigtes
          Interesse an der Speicherung
          haben (z.B. um rechtliche Ansprüche durchzusetzen, zur Archivierung oder dazu, die
          IT-Sicherheit sicherzustellen)
          und solange Daten einer gesetzlichen Aufbewahrungspflicht unterliegen. Stehen keine
          rechtlichen oder vertraglichen
          Pflichten entgegen, vernichten oder anonymisieren wir Ihre Daten nach Ablauf der
          Speicher- oder Bearbeitungsdauer
          im Rahmen unserer üblichen Abläufe.
        </p>
        <h1>8. Auf welchen Rechtsgrundlagen beruht die Datenbearbeitung? </h1>
        <p>
          Je nach anwendbarem Recht ist eine Datenbearbeitung nur erlaubt, wenn das anwendbare
          Recht es spezifisch erlaubt.
          Das gilt nicht nach dem schweizerischen Datenschutzgesetz, aber z.B. nach der
          Europäischen
          Datenschutzgrundverordnung (DSGVO), soweit diese zur Anwendung kommt. In diesem Fall
          stützen wir die Bearbeitung
          Ihrer Lohncheck-Daten auf folgende Rechtsgrundlagen:
        </p>
        <ul>
          <li> Art. 6 Abs. 1 lit. a DSGVO für Bearbeitungen, die wir mit Ihrer separaten
            Einwilligung durchführen,
            insbesondere die unter Ziffer<b>4.2</b> benannten Bearbeitungen;.
          </li>
          <li>Art. 6 Abs. 1 lit. b DSGVO für Bearbeitungen, die zur Vorbereitung und Durchführung
            unserer
            Online-Dienstleistungen (insbesondere Durchführung der Lohnanalysen und der weiteren
            unter Ziffer<b>4.1</b>
            genannten Zwecke) erforderlich ist, soweit sie sich dazu entscheiden, diese
            Dienstleistungen in Anspruch zu
            nehmen;
          </li>
          <li> Art. 6 Abs. 1 lit. f DSGVO für Bearbeitungen, die erforderlich sind, um berechtigte
            Interessen von uns oder
            von Dritten zu wahren, sofern nicht die Grundfreiheiten und Grundrechte sowie
            Interessen der betroffenen Person
            überwiegen. Das betrifft insbesondere die Einhaltung von schweizerischem oder
            europäischem Recht, unser
            Interesse, unsere Aktivitäten und Tätigkeiten dauerhaft, nutzerfreundlich, sicher
            und zuverlässig ausüben und
            die Zwecke nach Ziffer<b>4.4</b>;
          </li>
          <li> Art. 6 Abs. 1 lit. c DSGVO für Bearbeitungen, die zur Erfüllung einer rechtlichen
            Verpflichtung nach dem
            Recht eines Mitgliedstaats des Europäischen Wirtschaftsraums (EWR) erforderlich
            sind. Der EWR umfasst die
            EU-Staaten und Island, Norwegen und Liechtenstein.
          </li>
        </ul>
        <h1>9. Welche Rechte haben Sie? </h1>
        <p>
          Sie haben im Rahmen und unter den Voraussetzungen oder in den Einschränkungen des
          anwendbaren Datenschutzrechts
          bestimmte Rechte, damit Sie weitere Informationen über unsere Datenbearbeitung beziehen
          und auf diese einwirken
          können. Das sind insbesondere die folgenden Rechte:
        </p>
        <ul>
          <li><strong>Auskunftsrecht</strong>: Sie können weitere Angaben über unsere
            Datenbearbeitung verlangen. Dafür
            stehen wir Ihnen gerne zur Verfügung. Sie können auch ein sog. Auskunftsbegehren
            stellen, wenn Sie weitere
            Informationen und eine Kopie Ihrer Daten wünschen.
          </li>
          <li><strong>Widerspruch und Löschung</strong>: Sie können unseren Datenbearbeitungen mit
            Wirkung für die Zukunft
            widersprechen und auch jederzeit verlangen, dass wir Ihre Lohncheck-Daten
            löschen.<strong>&nbsp;Sie können insbesondere
              jederzeit gegen die Bearbeitung von Daten für Zwecke der Direktwerbung und des
              Newsletter Widerspruch einlegen.&nbsp;
              <a href='https://lohncheck.ch/de/daten-loeschen'
                 target='_blank' rel='noopener noreferrer'>https://lohncheck.ch/de/daten-loeschen</a>
            </strong>
          </li>
          <li><strong> Berichtigung und Einschränkung</strong>: Sie können unrichtige oder
            unvollständige Lohncheck-Daten
            berichtigen bzw. vervollständigen und die Bearbeitung Ihrer Daten einschränken
            lassen.
          </li>
          <li><strong>Übertragung</strong>: Sie haben auch das Recht, die Lohncheck-Daten, die Sie
            uns bereitgestellt
            haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten
            oder an einen Dritten
            übertragen zu lassen, soweit die entsprechende Datenbearbeitung auf Ihrer
            Einwilligung beruht oder zur
            Vertragserfüllung erforderlich ist.
          </li>
          <li><strong>Widerruf</strong>: Soweit wir Daten auf Basis Ihrer Einwilligung bearbeiten,
            können Sie die
            Einwilligung jederzeit widerrufen. Der Widerruf gilt nur für die Zukunft, und wir
            behalten uns vor, Daten im
            Fall eines Widerrufs gestützt auf eine andere Grundlage weiterhin zu bearbeiten.
          </li>
        </ul>
        <p>
          <strong>Beachten Sie bitte, dass diese Rechte gesetzlichen Voraussetzungen und
            Einschränkungen unterliegen und
            deshalb nicht in jedem Fall vollumfänglich zur Verfügung stehen</strong>.
          Insbesondere müssen wir Ihre
          Personendaten gegebenenfalls weiterbearbeiten und speichern, um eigene schutzwürdige
          Interessen zu wahren,
          wie etwa die Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen, oder um
          gesetzliche Verpflichtungen
          einzuhalten. Soweit rechtlich zulässig, insbesondere zum Schutz der Rechte und
          Freiheiten anderer betroffener
          Personen sowie zur Wahrung schutzwürdiger Interessen, können wir daher ein
          Betroffenenbegehren auch ganz oder
          teilweise ablehnen (z.B. indem wir bestimmte Inhalte, die Dritte oder unsere
          Geschäftsgeheimnisse betreffen,
          schwärzen).
        </p>
        <p>
          Wenn Sie uns gegenüber Rechte ausüben wollen, wenden Sie sich bitte schriftlich an uns.
          Unsere Kontaktangaben
          finden Sie in Ziffer<b>2</b>. In der Regel müssen wir dabei Ihre Identität prüfen (z.B.
          durch eine Ausweiskopie).
          Es steht Ihnen zudem auch frei, bei der zuständigen Aufsichtsbehörde eine Beschwerde
          gegen unsere Bearbeitung
          Ihrer Daten einzureichen. Zuständige Aufsichtsbehörde in der Schweiz ist der
          Eidgenössische Datenschutz- und
          Öffentlichkeitsbeauftragte (EDÖB). Im Anwendungsbereich der DSGVO können Sie sich an die
          zuständigen nationalen
          Aufsichtsbehörden wenden.
        </p>

        <h1>10. Änderungen </h1>
        <p>
          Wir können diese Datenschutzerklärung bei Bedarf jederzeit anpassen. Es gilt die jeweils
          aktuelle, auf unserer
          Webseite publizierte Fassung.
        </p>
        <p>
          Stand: 22.5.2024
        </p>
      </p>
    </p>
  </>
}
