import React, { Fragment } from 'react'
import { Translate } from 'react-localize-redux'
import createRenderer from '@components/FormField'
import './Text.css'

const Text = createRenderer((input, label, language, rest) => (
	<Translate>
		{({ translate }) => (
			<Fragment>
				{rest.groupText ? (
					<div className="FieldTextGroup">
						<input
							id={input.name}
							className="fieldText"
							type="text"
							placeholder={rest.placeholder[language]}
							{...input}
						/>
						<span className="fieldGroupText">{rest.groupText}</span>
					</div>
				) : (
					<input
						id={input.name}
						className="fieldText"
						type="text"
						placeholder={rest.placeholder[language]}
						{...input}
					/>
				)}
			</Fragment>
		)}
	</Translate>
))

export default Text
