import React from 'react'
import { Translate } from 'react-localize-redux'
import TagManager from 'react-gtm-module-custom-domain/dist/TagManager'

TagManager.initialize({
	gtmId: 'GTM-NDG8H5M',
	customURL: 'https://cc.lohncheck.ch/gtm.js',
})

const Legal = () => (
	<Translate>
		{({ activeLanguage }) =>
			activeLanguage?.code === 'fr' ? (
				<>
					<h1>Mentions légales</h1>
					<p>
						<strong>Matto-Group AG</strong>
						<br />
						General-Guisan-Str. 6
						<br />
						CH – 6300 Zug
					</p>
					<p>
						E-Mail:{' '}
						<a href="mailto:info@lohncheck.ch">info@lohncheck.ch</a>
					</p>
				</>
			) : (
				<>
					<h1>Impressum</h1>
					<p>
						<strong>Matto-Group AG</strong>
						<br />
						General-Guisan-Str. 6
						<br />
						CH – 6300 Zug
					</p>
					<p>
						E-mail:{' '}
						<a href="mailto:info@lohncheck.ch">info@lohncheck.ch</a>
					</p>
				</>
			)
		}
	</Translate>
)

export default Legal
