import React from 'react'
import { Translate } from 'react-localize-redux'
import classNames from 'classnames'
import history from '@browser-history'

import styles from './Steps.module.css'

const Steps = ({ count, label }) => (
	<div className={styles.steps}>
		<span className={styles.count}>
			<Translate id="step" /> {count} <Translate id="of" /> 4
		</span>
		<strong className={styles.description}>{label}</strong>
		<ul className={styles.pagination}>
			<li
				className={classNames(styles.paginationItem, {
					[styles.isActive]: count === '1',
				})}
			>
				{count > 1 && (
					<a
						href="#a"
						className={styles.paginationLink}
						onClick={(e) => {
							e.preventDefault()
							history.push(`/`)
							window.scrollTo(0, 0)
						}}
					>
						<Translate id="step" /> 1
					</a>
				)}
			</li>
			<li
				className={classNames(styles.paginationItem, {
					[styles.isActive]: count === '2',
				})}
			>
				{count > 2 && (
					<a
						href="#a"
						className={styles.paginationLink}
						onClick={(e) => {
							e.preventDefault()
							history.push(`/arbeitgeber`)
							window.scrollTo(0, 0)
						}}
					>
						<Translate id="step" /> 1
					</a>
				)}
			</li>
			<li
				className={classNames(styles.paginationItem, {
					[styles.isActive]: count === '3',
				})}
			>
				{count > 3 && (
					<a
						href="#a"
						className={styles.paginationLink}
						onClick={(e) => {
							e.preventDefault()
							history.push(`/eigene-daten`)
							window.scrollTo(0, 0)
						}}
					>
						<Translate id="step" /> 1
					</a>
				)}
			</li>
			<li
				className={classNames(styles.paginationItem, {
					[styles.isActive]: count === '4',
				})}
			></li>
		</ul>
	</div>
)

export default Steps
