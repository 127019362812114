import React, { Component } from 'react'

import './Button.css'

class Button extends Component {
	render() {
		const { label, ...rest } = this.props

		return (
			<button className="button" {...rest}>
				{label}
			</button>
		)
	}
}

export default Button
