import React from 'react'
import classNames from 'classnames'
import './Card.css'

const Card = (props) => (
	<div className={classNames('Card', { CardHighlight: props.highlight })}>
		{props.children}
	</div>
)

export default Card
