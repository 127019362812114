export const states_ch = [
	{
		title: {
			de: 'Aargau',
			en: 'Argovia',
			fr: 'Argovie',
			it: 'Argovia',
			es: 'Argovia',
		},
		value: 1,
	},
	{
		title: {
			de: 'Appenzell AR',
			en: 'Appenzell AR',
			fr: 'Appenzell Rhodes-Extérieures',
			it: 'Appenzello Esterno',
			es: 'Appenzell Rodas Exteriores',
		},
		value: 2,
	},
	{
		title: {
			de: 'Appenzell IR',
			en: 'Appenzell IR',
			fr: 'Appenzell Rhodes-Intérieures',
			it: 'Appenzello Interno',
			es: 'Appenzell Rodas Interiores',
		},
		value: 3,
	},
	{
		title: {
			de: 'Basel Land',
			en: 'Suburbs of Basel',
			fr: 'Bâle-Campagne',
			it: 'Sobborghi di Basilea',
			es: 'Suburbios de Basilea',
		},
		value: 4,
	},
	{
		title: {
			de: 'Basel Stadt',
			en: 'City of Basel',
			fr: 'Bâle-Ville',
			it: 'Città di Basilea',
			es: 'Ciudad de Basilea',
		},
		value: 5,
	},
	{
		title: {
			de: 'Bern Land',
			en: 'suburbs of Berne',
			fr: 'Berne-Campagne',
			it: 'Sobborghi di Berna',
			es: 'suburbios de Berna',
		},
		value: 6,
	},
	{
		title: {
			de: 'Bern Stadt',
			en: 'City of Berne',
			fr: 'Berne-Ville',
			it: 'Sobborghi di Berna',
			es: 'Ciudad de Berna',
		},
		value: 7,
	},
	{
		title: {
			de: 'Freiburg',
			en: 'Freiburg',
			fr: 'Fribourg',
			it: 'Friburgo',
			es: 'Freiburg',
		},
		value: 8,
	},
	{
		title: {
			de: 'Genf',
			en: 'Geneva',
			fr: 'Genève',
			it: 'Ginevra',
			es: 'Ginebra',
		},
		value: 9,
	},
	{
		title: {
			de: 'Glarus',
			en: 'Glarus',
			fr: 'Glaris',
			it: 'Glarona',
			es: 'Glaris',
		},
		value: 10,
	},
	{
		title: {
			de: 'Graubünden',
			en: 'Grisons',
			fr: 'Grisons',
			it: 'Grigioni',
			es: 'Grisones',
		},
		value: 11,
	},
	{
		title: {
			de: 'Jura',
			en: 'Jura',
			fr: 'Jura',
			it: 'Giura',
			es: 'Jura',
		},
		value: 12,
	},
	{
		title: {
			de: 'Luzern',
			en: 'Lucerne',
			fr: 'Lucerne',
			it: 'Lucerna',
			es: 'Lucerna',
		},
		value: 13,
	},
	{
		title: {
			de: 'Neuenburg',
			en: 'Neuenburg',
			fr: 'Neuchâtel',
			it: 'Neuchâtel',
			es: 'Neuenburg',
		},
		value: 14,
	},
	{
		title: {
			de: 'Nidwalden',
			en: 'Nidwald',
			fr: 'Nidwald',
			it: 'Nidvaldo',
			es: 'Nidwalden',
		},
		value: 15,
	},
	{
		title: {
			de: 'Obwalden',
			en: 'Obwalden',
			fr: 'Obwald',
			it: 'Obvaldo',
			es: 'Obwalden',
		},
		value: 16,
	},
	{
		title: {
			de: 'Schaffhausen',
			en: 'Schaffhausen',
			fr: 'Schaffhouse',
			it: 'Sciaffusa',
			es: 'Schaffhausen',
		},
		value: 17,
	},
	{
		title: {
			de: 'Schwyz',
			en: 'Schwyz',
			fr: 'Schwytz',
			it: 'Svitto',
			es: 'Schwyz',
		},
		value: 18,
	},
	{
		title: {
			de: 'Solothurn',
			en: 'Solothurn',
			fr: 'Soleure',
			it: 'Soletta',
			es: 'Soleura',
		},
		value: 19,
	},
	{
		title: {
			de: 'St. Gallen Land',
			en: 'Suburbs of St. Gallen',
			fr: 'Saint-Gall-Campagne',
			it: 'Sobborghi di San Gallo',
			es: 'Suburbios de San Galo',
		},
		value: 20,
	},
	{
		title: {
			de: 'St. Gallen Stadt',
			en: 'City of St. Gallen',
			fr: 'Saint-Gall-Ville',
			it: 'Città di San Gallo',
			es: 'Ciudad de San Galo',
		},
		value: 21,
	},
	{
		title: {
			de: 'Tessin',
			en: 'Ticino',
			fr: 'Tessin',
			it: 'Ticino',
			es: 'Tesino',
		},
		value: 22,
	},
	{
		title: {
			de: 'Thurgau',
			en: 'Thurgau',
			fr: 'Thurgovie',
			it: 'Turgovia',
			es: 'Turgovia',
		},
		value: 23,
	},
	{
		title: {
			de: 'Uri',
			en: 'Uri',
			fr: 'Uri',
			it: 'Uri',
			es: 'Uri',
		},
		value: 24,
	},
	{
		title: {
			de: 'Waadt',
			en: 'Waadt',
			fr: 'Vaud',
			it: 'Vaud',
			es: 'Vaud',
		},
		value: 25,
	},
	{
		title: {
			de: 'Wallis',
			en: 'Valais',
			fr: 'Valais',
			it: 'Vallese',
			es: 'Valais',
		},
		value: 26,
	},
	{
		title: {
			de: 'Zürich Land',
			en: 'Suburbs of Zurich',
			fr: 'Zurich-Campagne',
			it: 'Sobborghi di Zurigo',
			es: 'Suburbios de Zúrich',
		},
		value: 27,
	},
	{
		title: {
			de: 'Zürich Stadt',
			en: 'City of Zurich',
			fr: 'Zurich-Ville',
			it: 'Città di Zurigo',
			es: 'Ciudad de Zúrich',
		},
		value: 28,
	},
	{
		title: {
			de: 'Zug',
			en: 'Zug',
			fr: 'Zoug',
			it: 'Zugo',
			es: 'Zug',
		},
		value: 29,
	},
	{
		title: {
			de: 'Liechtenstein',
			en: 'Liechtenstein',
			fr: 'Liechtenstein',
			it: 'Liechtenstein',
			es: 'Liechtenstein',
		},
		value: 30,
	},
]

export const states_de = [
	{
		title: {
			de: 'Baden-Württemberg',
			en: 'Baden-Württemberg',
			fr: 'Bade-Wurtemberg',
			it: 'Baden-Württemberg',
			es: 'Baden-Wurtemberg',
		},
		value: 31,
	},
	{
		title: {
			de: 'Bayern',
			en: 'Bavaria',
			fr: 'Bavière',
			it: 'Baviera',
			es: 'Baviera',
		},
		value: 32,
	},
	{
		title: {
			de: 'Berlin',
			en: 'Berlin',
			fr: 'Berlin',
			it: 'Berlino',
			es: 'Berlín',
		},
		value: 33,
	},
	{
		title: {
			de: 'Brandenburg',
			en: 'Brandenburg',
			fr: 'Brandebourg',
			it: 'Brandeburgo',
			es: 'Brandeburgo',
		},
		value: 34,
	},
	{
		title: {
			de: 'Bremen',
			en: 'Bremen',
			fr: 'Brême',
			it: 'Brema',
			es: 'Bremen',
		},
		value: 35,
	},
	{
		title: {
			de: 'Hamburg',
			en: 'Hamburg',
			fr: 'Hambourg',
			it: 'Amburgo',
			es: 'Hamburgo',
		},
		value: 36,
	},
	{
		title: {
			de: 'Hessen',
			en: 'Hesse',
			fr: 'Hesse',
			it: 'Assia',
			es: 'Hesse',
		},
		value: 37,
	},
	{
		title: {
			de: 'Mecklenburg-Vorpommern',
			en: 'Mecklenburg-Vorpommern',
			fr: 'Mecklembourg-Poméranie-Occidentale',
			it: 'Meclemburgo-Pomerania Anteriore',
			es: 'Mecklemburgo-Pomerania Occidental',
		},
		value: 38,
	},
	{
		title: {
			de: 'Niedersachsen',
			en: 'Lower Saxony',
			fr: 'Basse-Saxe',
			it: 'Bassa Sassonia',
			es: 'Baja Sajonia',
		},
		value: 39,
	},
	{
		title: {
			de: 'Nordrhein-Westfalen',
			en: 'North Rhine-Westphalia',
			fr: 'Rhénanie-du-Nord-Westphalie',
			it: 'Renania Settentrionale-Vestfalia',
			es: 'Renania del Norte-Westfalia',
		},
		value: 40,
	},
	{
		title: {
			de: 'Rheinland-Pfalz',
			en: 'Rhineland-Palatinate',
			fr: 'Rhénanie-Palatinat',
			it: 'Renania-Palatinato',
			es: 'Renania-Palatinado',
		},
		value: 41,
	},
	{
		title: {
			de: 'Saarland',
			en: 'Saarland',
			fr: 'Sarre',
			it: 'Saarland',
			es: 'Sarre',
		},
		value: 42,
	},
	{
		title: {
			de: 'Sachsen',
			en: 'Saxony',
			fr: 'Saxe',
			it: 'Sassonia',
			es: 'Sajonia',
		},
		value: 43,
	},
	{
		title: {
			de: 'Sachsen-Anhalt',
			en: 'Saxony-Anhalt',
			fr: 'Saxe-Anhalt',
			it: 'Sassonia-Anhalt',
			es: 'Sajonia-Anhalt',
		},
		value: 44,
	},
	{
		title: {
			de: 'Schleswig-Holstein',
			en: 'Schleswig-Holstein',
			fr: 'Schleswig-Holstein',
			it: 'Schleswig-Holstein',
			es: 'Schleswig-Holstein',
		},
		value: 45,
	},
	{
		title: {
			de: 'Thüringen',
			en: 'Thuringia',
			fr: 'Thuringe',
			it: 'Turingia',
			es: 'Turingia',
		},
		value: 46,
	},
]

export const states_at = [
	{
		title: {
			de: 'Burgenland',
			en: 'Burgenland',
			fr: 'Burgenland',
			it: 'Burgenland',
			es: 'Burgenland',
		},
		value: 47,
	},
	{
		title: {
			de: 'Kärnten',
			en: 'Carinthia',
			fr: 'Carinthie',
			it: 'Carinzia',
			es: 'Carintia',
		},
		value: 48,
	},
	{
		title: {
			de: 'Niederösterreich',
			en: 'Lower Austria',
			fr: 'Basse-Autriche',
			it: 'Bassa Austria',
			es: 'Baja Austria',
		},
		value: 49,
	},
	{
		title: {
			de: 'Oberösterreich',
			en: 'Upper Austria',
			fr: 'Haute-Autriche',
			it: 'Alta Austria',
			es: 'Alta Austria',
		},
		value: 50,
	},
	{
		title: {
			de: 'Salzburg',
			en: 'Salzburg',
			fr: 'Salzbourg',
			it: 'Salisburgo',
			es: 'Salzburgo',
		},
		value: 51,
	},
	{
		title: {
			de: 'Steiermark',
			en: 'Styria',
			fr: 'Styrie',
			it: 'Stiria',
			es: 'Estiria',
		},
		value: 52,
	},
	{
		title: {
			de: 'Tirol',
			en: 'Tyrol',
			fr: 'Tyrol',
			it: 'Tirolo',
			es: 'Tirol',
		},
		value: 53,
	},
	{
		title: {
			de: 'Vorarlberg',
			en: 'Vorarlberg',
			fr: 'Vorarlberg',
			it: 'Vorarlberg',
			es: 'Vorarlberg',
		},
		value: 54,
	},
	{
		title: {
			de: 'Wien',
			en: 'Vienna',
			fr: 'Vienne',
			it: 'Vienna',
			es: 'Viena',
		},
		value: 55,
	},
]

export const getStates = (country_id) => {
	switch (country_id) {
		case 84:
			return states_de
		case 14:
			return states_at
		default:
			return states_ch
	}
}
