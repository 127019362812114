import React from 'react'
import { withLocalize } from 'react-localize-redux'
import Tippy from '@tippy.js/react'

import Label from '@components/FormLabel'
import './FormField.css'

import questionmark from 'assets/img/questionmark.svg'
import 'tippy.js/themes/material.css'

const createRenderer = (render) =>
	withLocalize(
		({
			activeLanguage,
			input,
			meta,
			label,
			labelPlaceholder = '',
			...rest
		}) => {
			if (!(activeLanguage && activeLanguage.code)) return null

			return (
				<div
					className={[
						'fieldGroup',
						meta.error && meta.touched && meta.submitFailed
							? ' hasError'
							: '',
						meta.active ? 'isActive' : '',
						rest.hideBorderOnFocus ? 'hideBorderOnFocus' : '',
					].join(' ')}
				>
					{!rest.hideFormLabel && (
						<div className="fieldHeader">
							{label && (
								<Label htmlFor={input.name}>
									{label[activeLanguage.code]?.replace(
										'{PLACEHOLDER}',
										labelPlaceholder
									)}
								</Label>
							)}
							{rest.tooltipText && (
								<div className="fieldTooltip">
									<Tippy
										content={
											rest.tooltipText[
												activeLanguage.code
											]
										}
										arrow={true}
										size={'small'}
										arrowType={'round'}
									>
										<img
											src={questionmark}
											alt="Fragezeichen"
										/>
									</Tippy>
								</div>
							)}
							{rest.optional && (
								<div className="fieldOptional">optional</div>
							)}
						</div>
					)}
					{meta.error && meta.touched && meta.submitFailed && (
						<span className="formError">{meta.error}</span>
					)}
					{render(
						input,
						label[activeLanguage.code],
						activeLanguage.code,
						rest
					)}
				</div>
			)
		}
	)

export default createRenderer
