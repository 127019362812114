import React from 'react'
import { Link } from 'react-router-dom'
import { Translate } from 'react-localize-redux'
import styles from './Footer.module.css'

const Footer = () => (
	<footer className={styles.footer}>
		<div className={styles.footerInner}>
			<span className={styles.info}>© {new Date().getFullYear()}</span>
			<div className={styles.navigation}>
				<Link className={styles.link} to="/impressum">
					<Translate id="imprint" />
				</Link>
				<Link className={styles.link} to="/datenschutz">
					<Translate id="privacypolicy" />
				</Link>
				<Link className={styles.link} to="/marketing-datenschutz">
					<Translate id="marketingPolicy" />
				</Link>
			</div>
		</div>
	</footer>
)

export default Footer
