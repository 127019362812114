import React from 'react'
import { Translate } from 'react-localize-redux'
import TagManager from 'react-gtm-module-custom-domain/dist/TagManager'

TagManager.initialize({
	gtmId: 'GTM-NDG8H5M',
	customURL: 'https://cc.lohncheck.ch/gtm.js',
})

const Data = () => (
	<Translate>
		{({ activeLanguage }) =>
			activeLanguage?.code === 'en' ? (
				<>
					<h1>
						General data protection declaration of Matto Group AG
					</h1>
					<p>
						<ol>
							<li><strong>What is this privacy policy about?</strong></li>
							<li><strong>Who is responsible for processing your data?</strong></li>
							<li><strong>What personal data do we process?</strong></li>
							<li><strong>For what purposes do we process your personal data?</strong></li>
							<li><strong>What online tracking and online advertising techniques do we use?</strong></li>
							<li><strong>What applies to profiling and automated decisions?</strong></li>
							<li><strong>How do we process data related to social media?</strong></li>
							<li><strong>To whom do we disclose your personal data?</strong></li>
							<li><strong>Do we disclose personal data abroad?</strong></li>
							<li><strong>How long do we process personal data?</strong></li>
							<li><strong>What are the legal bases for data processing?</strong></li>
							<li><strong>How do we protect your data?</strong></li>
							<li><strong>What are your rights?</strong></li>
							<li><strong>Changes</strong></li>
						</ol>

					</p>
					<h1>1. What is this privacy policy about?</h1>
					<p>
						Matto-Group AG, General-Guisan-Str. 6, 6300 Zug, processes personal data that concerns you or
						other people in
						different ways and for different purposes. As far as we refer
						to &quot;Matto-Group&quot;, &quot;we&quot; or &quot;us&quot;
						below, this refers
						to Matto-Group AG. (see section 2).
					</p>
					<p>
						&quot;Personal data&quot; is all information that can be associated with a specific person, and
						&quot;processing&quot; means any
						handling of it, e.g. obtaining, using, and disclosing it. This data protection declaration
						explains our
						processing of personal data (hereinafter data), especially in connection with our websites
						(<a href='https://www.lohncheck.ch' target='_blank'
							rel='noopener noreferrer'>www.lohncheck.ch</a> or <a
						href='https://www.matto-group.com' target='_blank'
						rel='noopener noreferrer'>www.matto-group.com</a>) or in connection
						with your
						application to us or as part of our business activities, if processing is not obvious and the
						applicable data
						protection law requires information. Please note that we provide a separate data protection
						declaration for the
						wage check offered on our website and other platforms under this link, which also applies to all
						data processing
						in connection with wage analysis, registration for our newsletter and direct marketing measures
						in connection
						with this wage check.
					</p>
					<p>
						To make it easier to read, this data protection declaration does not mention multiple genders,
						but we do mean
						to address people of all genders. If you would like further information about our data
						processing, please
						contact us (Section 2). We have aligned this data protection declaration with both the Swiss
						Data Protection
						Act (DSG) and the European General Data Protection Regulation (GDPR). If &quot;personal
						data&quot; is
						mentioned in the
						data protection declaration, this also includes &quot;personal data&quot; in accordance with the
						GDPR.
						Whether and to
						what extent the GDPR is applicable depends on the individual case.
					</p>
					<h1>2. Who is responsible for processing your data?</h1>
					<p>
						The following company is the &quot;responsible party&quot; for data processing in accordance
						with this
						data protection
						declaration, i.e. the body primarily responsible for data protection (also &quot;we&quot;):
					</p>
					<p>
						Matto-Group AG <br />
						General-Guisan-Str. 6 <br />
						6300 Zug <br />
						Switzerland <br />
					</p>
					<p>
						If you have any questions about data protection, please feel free to contact the following
						address:
					</p>
					<p>
						<a href='mailto:datenschutz@lohncheck.ch' target='_blank'
						   rel='noopener noreferrer'>datenschutz@lohncheck.ch</a>
					</p>
					<p>
						If you have any questions about the GDPR, you can also contact our EU representative in
						accordance with Art.
						27 GDPR:
					</p>
					<p>
						DP-Dock GmbH <br />
						Attn: Matto-Group AG <br />
						Ballindamm 39 <br />
						20095 Hamburg <br />
						Germany <br />
					</p>
					<p>
						If you have any questions about the UK GDPR, you can also contact our UK representative:
					</p>
					<p>
						DP Data Protection Services UK Ltd. <br />
						Attn: Matto-Group AG <br />
						16 Great Queen Street <br />
						Covent Garden <br />
						London, WC2B 5AH <br />
						United Kingdom <br />
					</p>
					<p>
						The email address for inquiries to our local representatives in the EU and UK is:{' '}
						<a href='mailto:matto‑group@gdpr‑rep.com' target='_blank'
						   rel='noopener noreferrer'>matto‑group@gdpr‑rep.com</a>
					</p>
					<h1>3. What personal data do we process? </h1>
					<p>
						We process different categories of personal data depending on the occasion and purpose. The most
						important
						categories can be found below, although this list is not exhaustive. In addition to the data
						mentioned below,
						we also process other data in connection with the wage analysis offered on our website or other
						platforms.
						Please see the separate data protection declaration, which is available here, to find out what
						data this is
						(wage check data) and for what purposes we process this data in connection with the wage
						analysis and the direct
						marketing based on it.
					</p>
					<p>
						We process fewer personal data for contractual partners who are companies because the applicable
						data
						protection law generally only records data from natural persons (i.e. people). However, we
						process data of
						the contact persons with whom we are in contact, e.g. name, contact details, professional
						details and
						information from communications, and information about managers, etc. as part of the general
						information about
						companies with which we work. You provide us with many of the data mentioned in this section
						yourself (e.g.
						via forms, as part of communication with us, in connection with contracts, when using the
						website, etc.). You
						are not obliged to do this, subject to individual cases. If you want to conclude contracts with
						us or use
						services, you must also provide us with data, in particular master and contract data, as part of
						your
						contractual obligation in accordance with the relevant contract. However, we may also receive
						certain data
						from other people, e.g. from people who work for your company or from third parties such as our
						contractual
						partners, associations, and address dealers and from publicly accessible sources such as public
						registers
						or the Internet (websites, social media etc.).
					</p>
					<p>
						If you provide us with information about other people, we will assume that you are authorized to
						do so, and
						that the information is accurate. Please ensure that these third parties have been informed
						about this data
						protection declaration (e.g. through a reference to this data protection declaration).
					</p>
					<h2>3.1. Basic data </h2>
					<p>
						We refer to master data as the basic data that we need to process our business relationships or
						for marketing
						and advertising purposes and which relate directly to your person and characteristics. For
						example, we process
						the following master data:
					</p>
					<p>
						<ul>
							<li> Title, surname and first name, gender, and date of birth</li>
							<li> Address, contact details such as email address and telephone and mobile number</li>
							<li> Nationality and residence permit status</li>
							<li> further information from identification documents</li>
							<li> Family data (e.g. marital status)</li>
							<li> Information about language preferences</li>
							<li> Information about your education (e.g. diplomas from educational institution, highest
								level of
								education)
							</li>
							<li> Information about your professional experience (e.g. career level, work experience,
								management span,
								etc.)
							</li>
							<li> Information about your employer (e.g. company, industry, number of employees,
								orientation, legal form,
								country)
							</li>
							<li> Information about your job, salary, and income situation. (e.g. gross income, workload,
								job title)
							</li>
							<li> in the case of company contacts, also relationships with the company you work for</li>
							<li> Customer history</li>
							<li> Signature authorizations and declarations of consent</li>
						</ul>
					</p>
					<h2>3.2. Contract data </h2>
					<p>
						Contract data is information that arises in connection with the conclusion of the contract or
						the execution of
						the contract, e.g. information about contracts and the services to be provided or provided, as
						well as data from
						the run-up to the conclusion of a contract, information about the conclusion of the contract
						itself (e.g. the
						conclusion date and the subject matter of the contract), as well the information required or
						used for
						processing. For example, we process the following contract data:
					</p>
					<p>
						<ul>
							<li> Date, application process, information about the type and duration as well as
								conditions of the contract
								in question, data on termination of the contract
							</li>
							<li> Contact details</li>
							<li> Information about the use of services</li>
							<li> Information on payments and payment methods, invoices, mutual claims, contacts with
								customer service,
								complaints, defects, returns, information on customer satisfaction, complaints,
								feedback, etc.
							</li>
							<li> For services available online, also access data and logins</li>
						</ul>
					</p>
					<p>
						We receive this data from you, but also from partners with whom we work. Again, this data can
						relate to your
						company, in which case it is not &quot;personal data&quot;, but also to you if you work for a
						company or
						if you receive
						services from us yourself.
					</p>
					<h2>3.3. Communication data </h2>
					<p>
						Communication data is data related to our communication with you, e.g. if you contact us via the
						contact form
						or other means of communication. Communication data is e.g.
					</p>
					<p>
						<ul>
							<li> Name and contact details such as postal address, email address and telephone number
							</li>
							<li> Content of the correspondence (e.g. emails, written correspondence, telephone
								conversations, chat messages,
								etc.)
							</li>
							<li> Information on the type, time and possibly location of the communication and other
								peripheral data of the
								communication.
							</li>
						</ul>
					</p>
					<h2>3.4. Technical data </h2>
					<p>
						Technical data is generated in connection with the use of our website. This includes, for
						example, the
						following data:
					</p>
					<p>
						<ul>
							<li> IP address of the end device and device ID</li>
							<li> Information about your device, the operating system of your device or language
								settings
							</li>
							<li> Information about your internet provider</li>
							<li> accessed content or logs in which the use of our systems is recorded</li>
							<li> Date and time of access to the website and your approximate location</li>
							<li> Information about the content and files accessed in the user account</li>
							<li> further information that arises when using the user account, such as sending the access
								code via push
								message for logging into your user account via the website
							</li>
						</ul>
					</p>
					<p>
						We can also assign you or your device an individual code (e.g. through a cookie; see section 5).
						This code is
						stored for a certain period, often only during your visit. We cannot determine who you are from
						technical data.
					</p>
					<h2>3.5. Behavioral data </h2>
					<p>
						To best tailor our offers and services to you or your company, we try to get to know you better.
						To do this,
						we collect and use data about your behavior. Behavioral data is information about your use of
						our website.
						They can also be collected based on technical data. This includes, for example, information
						about your use of
						electronic communications (e.g. whether and when you opened an email or clicked on a link,
						especially when
						sending newsletters). If we show you offers from third parties, we may track how you use the
						corresponding
						links. We can also use your other interactions with us as behavioral data, and we can link
						behavioral data with
						other data (e.g. with anonymous information from statistical offices) and evaluate this data on
						a personal and
						non-personal basis.
					</p>
					<h2>3.6. Preference data </h2>
					<p>
						Preference data gives us information about what needs you probably have and what services might
						be of interest
						to you – or the interest of your company (e.g. when selecting the subject areas for the
						newsletter). We
						therefore also process data about your interests and preferences. To do this, we can link
						behavioral data with
						other data and evaluate this data on a personal and non-personal basis. This allows us to draw
						conclusions about
						characteristics, preferences and expected behavior.
					</p>
					<h2>3.7. Other data </h2>
					<p>
						We may also collect information from you in other situations. In connection with official or
						judicial
						proceedings, for example, data is generated (such as files, evidence, etc.) which can also
						relate to you.
					</p>
					<h1>4. For what purposes do we process your personal data? </h1>
					<p>
						We use the personal data we collect primarily to conclude and execute our contracts. In
						addition, to the extent
						permitted and deemed appropriate to us, we also process your personal data for other purposes in
						which we
						(and sometimes third parties) have a legitimate interest corresponding to the purpose:
					</p>
					<p>
						<ul>
							<li> For communication purposes, that is, to contact you and maintain contact with you. This
								includes answering
								inquiries and contacting you if you have any questions, for example by email. For this
								purpose, we process your
								communication and master data.
							</li>
							<li>For customer care and marketing purposes, to inform you about offers tailored to your
								personal interests
								and preferences, e.g. through the newsletter and personalized advertising. For this
								purpose, we process
								technical data, master, and communication data as well as behavioral data. For
								information on how we process
								your wage check data for advertising purposes, please see the separate privacy policy at
								the following
								<a href='https://lohncheck.ch/en/marketing_datenschutz' target='_blank'
								   rel='noopener noreferrer'>Link</a>. We may
								also combine your
								behavioral and preference data with your wage check data to optimize the advertising and
								job offers on our
								website and better tailor them to your needs.
							</li>
							<li>To optimize our services: We use your personal data for analytical purposes to improve
								the quality of our
								services (our statistical model for wage calculation) as well as the user-friendliness
								and functionality of our
								website.
							</li>
							<li> To ensure IT security and prevention: We process personal data to monitor the
								performance of our operations,
								particularly the IT, our website, applications, and other platforms, for security
								purposes, to ensure IT
								security, to prevent theft, fraud, and abuse prevention and for evidence purposes. This
								includes, for example,
								the evaluation of system-side records of the use of our systems (log data), the
								prevention, defense and
								investigation of cyberattacks and malware attacks, analyses and tests of our networks
								and IT infrastructures,
								system and error checks.
							</li>
							<li> To protect house rules and other measures for IT, building and system security and to
								protect our employees
								and other people and assets belonging to or entrusted to us (such as access controls,
								visitor lists, network
								and email scanners, telephone records).
							</li>
							<li> To protect the law: We may also process personal data to enforce claims in court, in
								court or out of court
								and before authorities at home and abroad, or to defend ourselves against claims. Master
								and communication data
								can be edited for this purpose.
							</li>
							<li> To comply with legal requirements: This includes, for example, the processing of
								complaints and other
								reports, compliance with orders from a court or authority, measures to detect and
								clarify abuses and general
								measures that we are required to take in accordance with applicable law, self-regulation
								or industry standards
								are obliged. For this purpose, we can process your master and communication data.
							</li>
							<li> For administration and support: To make our internal processes efficient, we process
								data as necessary for
								IT management, accounting, or data archiving. For this purpose, we can process
								communication and behavioral
								data as well as technical data.
							</li>
							<li> We may also process data for other purposes. This includes corporate management
								including operational
								organization and corporate development, other internal processes and administrative
								purposes (e.g. management
								of master data, accounting and archiving), training and education purposes and the
								preparation and processing
								of the purchase and sale of business areas, companies or parts of companies and other
								corporate law matters,
								transactions and the associated transfer of personal data, as well as measures for
								business management and the
								protection of other legitimate interests. If we ask you for your consent for certain
								processing, we will inform
								you separately about the corresponding purposes of the processing. You can revoke your
								consent at any time by
								notifying us in writing.
							</li>
						</ul>
					</p>
					<h1>5. What online tracking and online advertising techniques do we use? </h1>
					<p>
						We use various techniques on our website with which we and third parties can recognize and
						engage you when you
						use it and, in some cases, track you over several visits. The use of such techniques is
						specifically regulated.
						In this section we will inform you about it.
					</p>
					<h2>5.1. How and why do we use cookies and similar technologies? </h2>
					<p>
						We use third-party services for our website to measure and improve the user-friendliness of the
						website and
						online advertising campaigns. To do this, we can integrate third-party components on our website
						and use cookies
						and other technologies. The core of this is that we can distinguish your access (via your
						system) from access
						by other users so that we can ensure the functionality of the website and carry out statistical
						evaluations.
						We do not wish to infer your identity. The technologies used are designed in such a way that you
						are recognized
						as an individual visitor each time you visit the page, for example by our server (or the servers
						of third
						parties) assigning you or your browser a specific identification number
						(so-called &quot;cookie&quot;).
					</p>

					<p>
						Cookies are files that your browser automatically saves on your device when you visit our
						website. Cookies
						contain a unique identification number (an ID) that allows us to distinguish individual visitors
						from others,
						but usually without identifying them. Depending on the purpose, cookies contain further
						information, e.g. about
						pages accessed and the duration of the visit to a page. On the one hand, we use session cookies,
						which are
						deleted when the browser is closed, and on the other hand, permanent cookies, which remain
						stored for a certain
						period after the browser is closed and are used to recognize visitors on a later visit.
					</p>
					<p>
						We use the following types of cookies and similar technologies:
					</p>
					<ul>
						<li> Necessary cookies: Necessary cookies are necessary for the functionality of the websites,
							e.g. so that you
							can switch between pages without losing information entered in a form.
						</li>
						<li> Performance cookies: These cookies collect information about the use of a website and
							enable analysis, e.g.
							which pages are most popular. This allows you to simplify visiting a website and improve
							user-friendliness.
						</li>
						<li> Functional cookies: Functional cookies enable extended functions and can display
							personalized content.
						</li>
						<li> [Marketing Technologies: Marketing technologies help us and our advertising partners to
							target you on our
							websites and on third-party websites with advertisements for products or services that may
							be of interest to
							you, or after you visit our websites as you continue to use the Internet, our advertisements
							to display.]
						</li>
					</ul>

					<p>
						We use cookies and other technologies for the following purposes:
					</p>
					<ul>
						<li> Personalization of content</li>
						<li> Displaying personalized advertisements and offers</li>
						<li> Displaying advertisements on third-party websites and measuring success, i.e. whether you
							respond to these
							advertisements (remarketing)
						</li>
						<li> Saving settings between your visits</li>
						<li> Determining whether and how we can improve our website</li>
						<li> Collection of statistical data on the number of users and their usage habits and to improve
							the speed and
							performance of the website
						</li>
					</ul>
					<p>
						We only use cookies and similar technologies for analysis and marketing purposes if you actively
						confirm the
						corresponding settings in our cookie banner.
					</p>
					<h2>5.2. How can cookies and similar technologies be deactivated?</h2>
					<p>
						When you visit our website, you have the option to activate or deactivate certain categories of
						cookies. You
						can configure your browser settings to block certain cookies or similar technologies or to
						delete existing
						cookies and other data stored in the browser. You can also expand your browser with software
						(so-called
						&quot;plug-ins&quot;) that blocks tracking by certain third parties. You can find out more about
						this in
						the help pages
						of your browser (usually under the keyword &quot;data protection&quot;). Please note that if you
						block
						cookies and
						similar technologies, our website may no longer function fully.
					</p>
					<h2>5.3. Cookies and technologies from partners and third parties on our website</h2>
					<p>
						We use third-party services to measure and improve the user-friendliness of the website and to
						run online
						advertising campaigns. Third-party providers may also be located outside of Switzerland and the
						EU/EEA,
						provided that the protection of your personal data is adequately ensured. For example, we use
						analysis services
						so that we can optimize and personalize our website. The relevant third-party providers can
						record the use of
						the website and the behavior of the users and provide us with statistical analyses on this
						basis. The providers
						can also use this information for their own purposes, e.g. for personalized advertising on their
						own website or
						other websites or to improve their own services. If a user is registered with the provider, the
						provider can
						assign the usage data to the person concerned.
					</p>
					<p>
						Our main third-party provider is Google. You can find further information about this below.
						Other third parties
						generally process personal and other data in a similar manner.
					</p>

					<ul>
						<li> Google Analytics, an analysis service provided by Google LLC (1600 Amphitheater Parkway,
							Mountain View,
							CA, USA) and Google Ireland Ltd. (Google Building Gordon House, Barrow St, Dublin 4,
							Ireland; both together
							&quot;Google&quot;, whereby Google Ireland Ltd. is responsible for the processing of
							personal data).
							Google uses cookies
							and similar technologies to collect certain information about the behavior of individual
							users on or in the
							relevant website and the device used (tablet, PC, smartphone, etc.). Google collects
							information about the
							behavior of users on the website and the device used and provides us with evaluations on
							this basis, but also
							processes certain data for its own purposes. Google Analytics anonymizes the IP addresses of
							visitors before
							forwarding them to the USA. Information on data protection with Google Analytics can be
							found
							<a href='https://policies.google.com/privacy?hl=de' target='_blank'
							   rel='noopener noreferrer'> here</a>. You can
							deactivate Google
							Analytics by
							<a href='https://tools.google.com/dlpage/gaoptout?hl=de' target='_blank'
							   rel='noopener noreferrer'> installing an
								appropriate browser
								add-on</a>.
						</li>
						<li>Google Protected Audience is a Google advertising and ad technology service. With the help
							of this service,
							users of a website are assigned to specific interest groups based on their user behavior.
							The information about
							membership in interest groups is stored in the browser used and the website operator can
							enrich it with other
							information available to him. Using this stored information, the user can be addressed with
							targeted advertising
							on other websites. Data is not transmitted across websites. The advertising is displayed
							using a so-called
							&quot;Fenced Frame&quot;. This means that the website on which advertising is displayed does
							not
							receive any information
							about which interest group the user belongs to, and which advertisements are displayed.
							However, the website
							operators of the participating websites can receive aggregated reports about the
							advertisements played. You
							can deactivate the use of Google Protected Audience in your browser settings. Information
							can be found {' '}
							<a href='https://developers.google.com/privacy-sandbox/relevance/protected-audience-api/opt-out?hl=de'
							   target='_blank' rel='noopener noreferrer'> here</a>.
						</li>
					</ul>
					<h1>6. What applies to profiling and automated decisions? </h1>
					<p>
						We can process and evaluate your data automatically in accordance with Section 3. This also
						includes so-called
						profiling, i.e. automated evaluations of data for analysis and forecasting purposes, to
						determine preference
						data (Section 3.6), but also to identify misuse and security risks. The most important examples
						are profiling
						for marketing purposes, customer care, fraud prevention, credit checks and risk management.
					</p>
					<h1>7. How do we process data related to social media? </h1>
					<p>
						If you communicate with us via social media and our profiles there (e.g. on Instagram, LinkedIn,
						Facebook) or
						comment on or distribute content, we collect information that we use primarily to communicate
						with you, for
						marketing purposes and for statistical evaluations. Please note that when you visit our social
						media sites,
						the platform provider also collects and uses data (e.g. about user behavior), possibly together
						with other
						data known to them (e.g. for marketing purposes or to personalize the platform content). Further
						information
						on data processing by social network providers can be found in the data protection declarations
						of the relevant
						social networks.
					</p>
					<h1>8. To whom do we disclose your personal data? </h1>
					<p>
						In connection with our processing, we also disclose your personal data to other recipients in
						addition to the
						recipients named in Section <strong> 5.3. </strong>
					</p>
					<p>
						We provide service providers with the personal data required for their services. This applies to
						IT service
						providers, but also consulting companies, analysis service providers, debt collection service
						providers, credit
						reporting agencies, marketing service providers, etc. If service providers process personal data
						as processors,
						they are obliged to process personal data exclusively in accordance with our instructions and to
						take measures
						to ensure data security. Data can also be disclosed to other recipients, e.g. to courts and
						authorities as part
						of procedures and legal information and cooperation obligations, to buyers of companies and
						assets, to financing
						companies for securitizations and to debt collection companies.
					</p>
					<p>
						In individual cases, it is possible that we pass on personal data to other third parties for
						their own purposes,
						e.g. if you have given us your consent to do so or if we are legally obliged or entitled to pass
						it on.
						For information related to wage check data, please refer to the relevant data protection
						declaration,{' '}
						<a href='https://lohncheck.ch/en/marketing_datenschutz' target='_blank'
						   rel='noopener noreferrer'> here</a>.
					</p>
					<h1>9. Do we disclose personal data abroad? </h1>
					<p>
						Recipients of data are not only in Switzerland. This particularly affects certain service
						providers. These can
						also be located within the European Economic Area (EEA) (for example in Ireland) but also in
						other countries
						around the world (particularly in the USA). For example, we may transfer data to authorities and
						other persons
						abroad if we are legally obliged to do so or, for example, as part of a company sale or legal
						proceedings. Not
						all these states currently guarantee a level of data protection that corresponds to Swiss law.
						We compensate
						for the lower level of protection through appropriate contracts, in particular the standard
						contractual clauses
						issued by the European Commission and recognized by the Swiss Data Protection and Information
						Commissioner
						(FDPIC). Further details and a copy of these clauses can be found
						<a href='https://www.edoeb.admin.ch/edoeb/de/home/datenschutz/arbeit_wirtschaft/datenuebermittlung_ausland.html'
						   target='_blank' rel='noopener noreferrer'> here</a>.
					</p>
					<p>
						In certain cases, we can transmit data in accordance with data protection regulations even
						without such
						contracts, e.g. if you have consented to the corresponding disclosure or if the disclosure is
						necessary for
						the execution of the contract, for the establishment, exercise, or enforcement of legal claims
						or for overriding
						public interests.
					</p>
					<p>
						Please note that data exchange over the Internet is often routed via third countries. In this
						case, your data
						can therefore end up abroad even if the sender and recipient are in the same country.
					</p>
					<h1>10. How long do we process personal data?</h1>
					<p>
						We store and process your personal data for as long as it is necessary for the purpose of
						processing (in the
						case of contracts, usually for the duration of the contractual relationship), as long as we have
						a legitimate
						interest in storing it (e.g. to enforce legal claims, for archiving and/or to ensure IT
						security) and as long
						as data is subject to a legal retention obligation (e.g. a ten-year retention period applies to
						certain data).
						If there are no legal or contractual obligations to the contrary, we will destroy or anonymize
						your data after
						the storage or processing period has expired as part of our usual processes.
					</p>
					<p>
						As part of our statutory retention period, we generally store master data for 15 years from the
						last exchange
						with you, but at least from the end of the contract. This period may be longer if this is
						necessary for
						evidentiary reasons or to comply with legal or contractual requirements or for technical
						reasons.
					</p>
					<p>
						We generally store contract data for 15 years from the last contract activity, but at least from
						the end of the
						contract. This period may be longer if this is necessary for evidentiary reasons or to comply
						with legal or
						contractual requirements or for technical reasons.
					</p>
					<p>
						We anonymize or delete your behavioral and preference data when it is no longer relevant for the
						purposes
						pursued, which may be the case after approximately 26 months, depending on the type of data.
						This period may be
						longer if this is necessary for evidentiary reasons or to comply with legal or contractual
						requirements or for
						technical reasons.
					</p>
					<p>
						Regarding cookies, you can find information about the retention period under the &quot;Cookie
						settings&quot; link.
					</p>
					<h1>11.What are the legal bases for data processing? </h1>
					<p>
						Depending on the applicable law, data processing is only permitted if the applicable law
						specifically allows
						it. This does not apply under the Swiss Data Protection Act, but, for example, under the
						European General Data
						Protection Regulation (GDPR), to the extent that this applies. In this case, we base the
						processing of your
						personal data on the following legal bases:
					</p>
					<ul>
						<li>Article 6 (1) (b) GDPR for processing that is necessary to fulfil a contract with the data
							subject and
							to carry out pre-contractual measures.
						</li>
						<li> Art. 6 Para. 1 lit. f GDPR for processing that is necessary to protect the legitimate
							interests of us
							or third parties, unless the fundamental freedoms and rights as well as the interests of the
							data subject
							outweigh this. This applies in particular to compliance with Swiss law, our interest in
							carrying out our
							activities and activities in a permanent, user-friendly, safe and reliable manner;
						</li>
						<li> Article 6 (1) (c) GDPR for processing that is necessary to fulfil a legal obligation under
							the law of a
							member state of the European Economic Area (EEA). The EEA includes the EU states and
							Iceland, Norway and
							Liechtenstein;
						</li>
						<li> Art. 6 Para. 1 lit. a GDPR for processing that we carry out with your separate consent;
						</li>
					</ul>
					<h1>12. How do we protect your data? </h1>
					<p>
						We take appropriate security measures to maintain the confidentiality, integrity, and
						availability of your
						personal data, to protect it against unauthorized or unlawful processing and to counteract the
						risks of loss,
						accidental alteration, unwanted disclosure, or unauthorized access. However, security risks
						cannot generally
						be completely ruled out; Residual risks are unavoidable.
					</p>
					<h1>13. What are your rights? </h1>
					<p>
						Under the conditions and within the scope of applicable data protection law, you have certain
						rights to request
						a copy of your personal data or to influence our processing of this data:
					</p>
					<ul>
						<li> Information: You can request information as to whether we process personal data about you,
							and if so,
							which ones, and further information about our data processing.
						</li>
						<li>Correction and restriction: You can correct incorrect personal data and complete incomplete
							data and have
							the processing of your data restricted
						</li>
						<li>Deletion and objection: You can have personal data deleted and object to the processing of
							your data with
							effect for the future. For reasons arising from your situation, you can object at any time
							to the processing
							of data for our legitimate interest and to our processing for direct advertising purposes.
							{' '} <a href='https://lohncheck.ch/de/daten-loeschen'
									 target='_blank'
									 rel='noopener noreferrer'> https://lohncheck.ch/de/daten-loeschen</a>
						</li>
						<li>Transfer: You can receive the personal data that you have provided to us in a structured,
							common, and
							machine-readable format, provided that the corresponding data processing is based on your
							consent or is
							necessary to fulfil the contract.
						</li>
						<li> Revocation: If we process data based on your consent, you can revoke your consent at any
							time. The
							revocation only applies for the future, and we reserve the right to continue to process data
							in the event of
							a revocation based on another basis.
						</li>
					</ul>
					<p>
						If you would like to exercise such a right, please contact us (Section 2). As a rule, we must
						check your
						identity.
					</p>
					<p>
						You are also free to lodge a complaint against our processing of your data with the responsible
						supervisory
						authority, in Switzerland with the Federal Data Protection and Information Commissioner (FDPIC).
						To the extent
						that the GDPR is applicable, you also have the right to lodge a complaint with a responsible
						European data
						protection supervisory authority.
					</p>
					<h1>14. Changes </h1>
					<p>
						We may adjust this privacy policy at any time without prior notice if necessary. The current
						version published
						on our website applies.
					</p>
					<p>
						Status of the data protection declaration: May 22, 2024
					</p>
				</>
			) : (
				<>
					<h1>Allgemeine Datenschutzerklärung der Matto Group A</h1>
					<p>
						<ol >
							<li><strong>Worum geht es in dieser Datenschutzerklärung?</strong></li>
							<li><strong>Wer ist für die Bearbeitung Ihrer Daten verantwortlich?</strong></li>
							<li><strong>Welche Personendaten bearbeiten wir?</strong></li>
							<li><strong>Zu welchen Zwecken bearbeiten wir Ihre Personendaten?</strong></li>
							<li><strong>Welche Online-Tracking und Online-Werbetechniken verwenden wir?</strong></li>
							<li><strong>Was gilt bei Profilierungen und automatisierten Entscheidungen?</strong></li>
							<li><strong>Wie bearbeiten wir Daten im Zusammenhang mit Social Media?</strong></li>
							<li><strong>Wem geben wir Ihre Personendaten bekannt?</strong></li>
							<li><strong>Geben wir Personendaten ins Ausland bekannt?</strong></li>
							<li><strong>Wie lange bearbeiten wir Personendaten?</strong></li>
							<li><strong>Auf welchen Rechtsgrundlagen beruht die Datenbearbeitung?</strong></li>
							<li><strong>Wie schützen wir Ihre Daten?</strong></li>
							<li><strong>Was sind Ihre Rechte?</strong></li>
							<li><strong>Änderungen</strong></li>
						</ol>

					</p>
					<h1>1. Worum geht es in dieser Datenschutzerklärung?</h1>
					<p>
						Die Matto-Group AG, General-Guisan-Str. 6, 6300 Zug, bearbeitet Personendaten,
						die Sie oder andere Personen betreffen, in unterschiedlicher Weise und für unterschiedliche
						Zwecke.Soweit wir nachstehend von «Matto-Group», «wir» oder «uns» sprechen, ist damit jeweils
						die Matto-Group
						AG gemeint.(siehe dazu Ziffer 2).
					</p>
					<p>
						«Personendaten» sind alle Informationen, die mit einer bestimmten Person in
						Verbindung gebracht werden können, und «bearbeiten» meint jeden Umgang damit, z.B. die
						Beschaffung,
						Verwendung und Bekanntgabe.
					</p>
					<p>
						Diese Datenschutzerklärung erläutert unsere Bearbeitung von Personendaten (anschliessend Daten),
						vor allem im Zusammenhang mit unseren Webseiten
						(<a href='https://www.lohncheck.ch' target='_blank'
							rel='noopener noreferrer'>www.lohncheck.ch</a>{' '}
						oder{' '}
						<a href='https://www.matto-group.com' target='_blank'
						   rel='noopener noreferrer'>www.matto-group.com</a>) oder
						im Zusammenhang mit Ihrer Bewerbung bei uns oder im Rahmen unserer Geschäftstätigkeit,
						soweit eine Bearbeitung nicht offensichtlich ist und das anwendbare Datenschutzrecht eine
						Information verlangt.
					</p>
					<p>
						Bitte beachten Sie, dass wir für den auf unserer Webseite und auf weiteren Plattformen
						angebotenen Lohncheck
						eine <strong> separate Datenschutzerklärung </strong> unter diesem{' '}
						<a href='https://lohncheck.ch/de/marketing_datenschutz' target='_blank'
						   rel='noopener noreferrer'>Link</a> bereitstellen,
						die ergänzend für alle Datenbearbeitungen gilt, die im Zusammenhang mit der Lohnanalyse,
						der Registrierung zu unserem Newsletter und Direktmarketingmassnahmen im Zusammenhang mit dem
						Lohncheck stehen
					</p>
					<p>
						Zur einfacheren Lesbarkeit wird in dieser Datenschutzerklärung auf die Nennung mehrerer
						Geschlechter verzichtet.
						Wir meinen aber jeweils Personen aller Geschlechter.
					</p>
					<p>
						Falls Sie weitere Informationen zu unserer Datenbearbeitung wünschen, wenden Sie sich gerne an
						uns (Ziffer 2).
					</p>
					<p>
						Wir haben diese Datenschutzerklärung sowohl am schweizerischen Datenschutzgesetz
						(<strong>DSG</strong>) als
						auch an der Europäischen Datenschutz-Grundverordnung (<strong>DSGVO</strong>) ausgerichtet. Wird
						in der
						Datenschutzerklärung von <strong> «Personendaten» </strong> gesprochen, sind damit auch
						<strong> «personenbezogene Daten» </strong> gemäss DSGVO mitgemeint. Ob und inwieweit die DSGVO
						anwendbar ist,
						hängt aber vom Einzelfall ab.

					</p>
					<h1>2. Wer ist für die Bearbeitung Ihrer Daten verantwortlich?</h1>
					<p>
						Für die Datenbearbeitungen nach dieser Datenschutzerklärung ist folgende Gesellschaft der
						«Verantwortliche»,
						d.h. die datenschutzrechtlich in erster Linie zuständige Stelle (auch «wir»):
					</p>
					<p>
						Matto-Group AG <br />
						General-Guisan-Str. 6 <br />
						6300 Zug <br />
						Schweiz <br />
					</p>
					<p>
						Wenn Sie Fragen zum Datenschutz haben, können Sie sich gerne an folgende Adresse wenden:
					</p>
					<p>
						<a href='mailto:datenschutz@lohncheck.ch' target='_blank'
						   rel='noopener noreferrer'>datenschutz@lohncheck.ch</a>
					</p>
					<p>
						Für Fragen zur DSGVO können Sie sich sodann auch an unseren EU-Vertreter nach Art. 27 DSGVO
						wenden:
					</p>
					<p>
						DP-Dock GmbH <br />
						z.Hd. Matto-Group AG <br />
						Ballindamm 39 <br />
						20095 Hamburg <br />
						Deutschland <br />
					</p>
					<p>
						Für Fragen zur UK-DSGVO können Sie sich auch unseren UK-Vertreter wenden:
					</p>
					<p>
						DP Data Protection Services UK Ltd. <br />
						Attn: Matto-Group AG <br />
						16 Great Queen Street <br />
						Covent Garden <br />
						London, WC2B 5AH <br />
						United Kingdom <br />
					</p>
					<p>
						Die E-Mailadresse für Anfragen an unsere lokalen Vertreter in der EU und UK
						lautet jeweils:{' '}<a href='mailto:matto‑group@gdpr‑rep.com' target='_blank'
										  rel='noopener noreferrer'>matto‑group@gdpr‑rep.com</a>
					</p>
					<h1>3. Welche Personendaten bearbeiten wir? </h1>
					<p>
						Wir bearbeiten je nach Anlass und Zweck verschiedene Kategorien von Personendaten.
						Die wichtigsten Kategorien finden Sie nachstehend, wobei diese Aufzählung nicht abschliessend
						sein muss.
					</p>
					<p>
						Neben den nachfolgend genannten Daten bearbeiten wir auch weitere Daten im Zusammenhang mit der
						auf
						unserer Webseite oder anderen Plattformen angebotenen Lohnanalyse. Um welche Daten es sich dabei
						handelt
						(<strong>Lohncheck-Daten</strong>) und zu welchen Zwecken wir diese Daten im Zusammenhang mit
						der Lohnanalyse
						und dem darauf basierenden Direktmarketing bearbeiten, entnehmen Sie bitte der separaten
						Datenschutzerklärung,
						die <a href='https://lohncheck.ch/de/marketing_datenschutz' target='_blank'
							   rel='noopener noreferrer'>hier</a>{' '}abrufbar ist.
					</p>
					<p>
						<strong> Bei Vertragspartnern, die Unternehmen sind, bearbeiten wir weniger
							Personendaten</strong>, weil das
						anwendbare Datenschutzrecht grundsätzlich nur Daten natürlicher Personen (d.h. von Menschen)
						erfasst. Wir
						bearbeiten aber Daten der Kontaktpersonen, mit denen wir in Kontakt stehen, z.B. Name,
						Kontaktangaben,
						Berufsangaben und Angaben aus der Kommunikation, und Angaben über Führungspersonen usw. als Teil
						der
						allgemeinen Informationen über Unternehmen, mit denen wir zusammenarbeiten.
					</p>
					<p>
						Viele der in dieser Ziffer genannten Daten geben Sie uns selbst bekannt (z.B. über Formulare, im
						Rahmen der
						Kommunikation mit uns, im Zusammenhang mit Verträgen, bei der Verwendung der Webseite etc.). Sie
						sind dazu
						nicht verpflichtet, unter Vorbehalt von Einzelfällen. Wenn Sie mit uns Verträge schliessen oder
						Leistungen
						beanspruchen wollen, müssen Sie uns zudem im Rahmen Ihrer vertraglichen Verpflichtung gemäss dem
						einschlägigen
						Vertrag Daten bereitstellen, insbesondere Stamm- und Vertragsdaten. Wir können bestimmte Daten
						aber u.U.
						aber auch von anderen Personen erhalten, z.B. von Personen, die für Ihr Unternehmen tätig sind
						oder von Dritten
						wie z.B. unseren Vertragspartnern, Verbänden und Adresshändlern und aus öffentlich zugänglichen
						Quellen wie
						z.B. öffentlichen Registern oder dem Internet (Webseiten, Social Media etc.).
					</p>
					<p>
						Wenn Sie uns Daten über andere Personen übermitteln bzw. bekanntgeben, gehen wir davon aus, dass
						Sie befugt
						dazu sind und dass diese Daten richtig sind. Bitte stellen Sie sicher, dass diese Dritten über
						diese
						Datenschutzerklärung informiert wurden (z.B. durch einen Hinweis auf diese
						Datenschutzerklärung).
					</p>
					<h2>3.1. Stammdaten </h2>
					<p>
						Als Stammdaten bezeichnen wir die Grunddaten, die wir für die Abwicklung unserer geschäftlichen
						Beziehungen
						oder für Marketing- und Werbezwecke benötigen und die sich direkt auf Ihre Person und
						Eigenschaften beziehen.
						Wir bearbeiten z.B. die folgenden Stammdaten:
					</p>
					<p>
						<ul >
							<li> Anrede, Name und Vorname, Geschlecht und Geburtsdatum</li>
							<li> Adresse, Kontaktangaben wie E-Mail-Adresse und Telefon- und Mobile-Nummer</li>
							<li> Staatsangehörigkeit und Status der Aufenthaltsbewilligung</li>
							<li> weitere Informationen aus Identifizierungsdokumenten</li>
							<li> Familiendaten (z.B. Zivilstand)</li>
							<li> Angaben zu Sprachpräferenzen</li>
							<li> Angaben zu Ihrer Ausbildung (z.B. Diplome Bildungsinstitution, höchste Ausbildung)</li>
							<li> Angaben zu Ihrer Berufserfahrung (z.B. Karrierelevel, Arbeitserfahrung, Führungsspanne
								etc.)
							</li>
							<li> Angaben zu Ihrem Arbeitgeber (z.B. Firma, Branche, Anzahl Mitarbeiter, Ausrichtung,
								Gesellschaftsform,
								Land)
							</li>
							<li> Angaben zu Ihrem Beruf, Gehalt und Ihrer Einkommenssituation. (z.B. Bruttoeinkommen,
								Pensum,
								Stellenbezeichnung)
							</li>
							<li> bei Kontaktpersonen von Unternehmen auch Beziehungen zum Unternehmen, für das Sie tätig
								sind
							</li>
							<li> Kundenhistorie</li>
							<li> Unterschriftsberechtigungen und Einwilligungserklärungen</li>
						</ul>
					</p>
					<h2>3.2. Vertragsdaten </h2>
					<p>
						Vertragsdaten sind Angaben, die im Zusammenhang mit dem Vertragsschluss bzw. der
						Vertragsabwicklung anfallen,
						z.B. Angaben über Verträge und die zu erbringenden oder erbrachten Leistungen, sowie Daten aus
						dem Vorfeld
						eines Vertragsabschlusses, Angaben zum Vertragsschluss selbst (z.B. zum Abschlussdatum und zum
						Vertragsgegenstand), sowie die zur Abwicklung erforderlichen oder verwendeten Angaben. Wir
						bearbeiten
						z.B. folgende Vertragsdaten:
					</p>
					<p>
						<ul >
							<li> Datum, Antragsprozess, Angaben über Art und Dauer sowie Konditionen des betreffenden
								Vertrags, Daten zur
								Beendigung des Vertrags
							</li>
							<li> Kontaktangaben</li>
							<li> Angaben über die Verwendung von Dienstleistungen</li>
							<li> Angaben zu Zahlungen und Zahlungsmodalitäten, Rechnungen, gegenseitigen Forderungen,
								Kontakten mit dem
								Kundendienst, Beanstandungen, Mängeln, Retouren, Angaben über Kundenzufriedenheit,
								Beschwerden,
								Rückmeldungen usw.
							</li>
							<li> Bei online verfügbaren Leistungen auch Zugangsdaten und Logins</li>
						</ul>
					</p>
					<p>
						Diese Daten erhalten wir von Ihnen, aber auch von Partnern, mit denen wir zusammenarbeiten.
						Wiederum können
						sich diese Daten auf Ihr Unternehmen beziehen, dann sind es keine «Personendaten», aber auch auf
						Sie, wenn
						Sie für ein Unternehmen tätig sind oder wenn Sie selbst Leistungen von uns beziehen.
					</p>
					<h2>3.3. Kommunikationsdaten </h2>
					<p>
						Kommunikationsdaten sind Daten im Zusammenhang mit unserer Kommunikation mit Ihnen, z.B. wenn
						Sie uns über
						das Kontaktformular oder über sonstige Kommunikationsmittel kontaktieren. Kommunikationsdaten
						sind z.B.
					</p>
					<p>
						<ul >
							<li> Name und Kontaktangaben wie z.B. Postadresse, E-Mail-Adresse und Telefonnummer</li>
							<li> Inhalt der Korrespondenz (z.B. von E-Mails, schriftlicher Korrespondenz,
								Telefongesprächen,
								Chatnachrichten etc.)
							</li>
							<li> Angaben zu Art, Zeit und u.U. Ort der Kommunikation und weitere Randdaten der
								Kommunikation.
							</li>
						</ul>
					</p>
					<h2>3.4. Technische Daten </h2>
					<p>
						Im Zusammenhang mit der Verwendung unserer Webseite fallen technische Daten an. Dazu gehören
						z.B. folgende
						Daten:
					</p>
					<p>
						<ul >
							<li> IP-Adresse des Endgeräts und Geräte-ID</li>
							<li> Angaben über Ihr Gerät, das Betriebssystem Ihres Endgeräts oder Spracheinstellungen
							</li>
							<li> Angaben über Ihren Internetprovider.</li>
							<li> aufgerufene Inhalte bzw. Protokolle, in denen die Nutzung unserer Systeme aufgezeichnet
								wird
							</li>
							<li> Datum und Zeit des Zugriffs auf die Webseite sowie Ihr ungefährer Standort</li>
							<li> Angaben zu den aufgerufenen Inhalten und Dateien im Benutzerkonto</li>
							<li> weitere Angaben, die bei der Nutzung des Benutzerkontos anfallen, wie z.B. das Zusenden
								des Zugang-Codes
								mittels Push-Nachricht für das Login in Ihr Benutzerkonto via Webseite
							</li>
						</ul>
					</p>
					<p>
						Wir können Ihnen oder Ihrem Endgerät auch einen individuellen Code zuweisen (z.B. durch ein
						Cookie; siehe dazu
						Ziffer 5). Dieser Code wird während einer bestimmten Dauer gespeichert, oft nur während Ihres
						Besuchs. Wir
						können aus technischen Daten nicht ableiten, wer Sie sind.
					</p>
					<h2>3.5. Verhaltensdaten </h2>
					<p>
						Um unsere Angebote und Leistungen bestmöglich auf Sie bzw. Ihr Unternehmen auszurichten,
						versuchen wir,
						Sie besser kennenzulernen. Dazu erheben und nutzen wir Daten zu Ihrem Verhalten. Verhaltensdaten
						sind
						insbesondere Angaben über Ihre Nutzung unserer Webseite. Sie können auch auf Basis von
						technischen Daten
						erhoben werden. Dazu gehören z.B. Informationen über Ihre Verwendung elektronischer Mitteilungen
						(z.B. ob
						und wann Sie eine E-Mail geöffnet oder einen Link angeklickt haben, insbesondere beim Versand
						von Newslettern).
						Falls wir Ihnen Angebote Dritter anzeigen können wir nachverfolgen, wie Sie die entsprechenden
						Links verwenden.
						Auch Ihre sonstigen Interaktionen mit uns können wir als Verhaltensdaten verwenden, und wir
						können
						Verhaltensdaten mit anderen Daten verknüpfen (z.B. mit anonymen Angaben von statistischen
						Ämtern) und diese
						Daten personenbezogen und nicht personenbezogen auswerten.
					</p>
					<h2>3.6. Präferenzdaten </h2>
					<p>
						Präferenzdaten geben uns Aufschluss darüber, welche Bedürfnisse Sie wahrscheinlich haben und
						welche Leistungen
						auf Ihr Interesse – bzw. das Interesse Ihres Unternehmens – stossen könnten (z.B. bei der Auswahl
						der
						Themenbereiche für den Newsletter). Wir bearbeiten deshalb auch Daten zu Ihren Interessen und
						Präferenzen.
						Hierzu können wir Verhaltensdaten mit anderen Daten verknüpfen und diese Daten personenbezogen
						und
						nicht personenbezogen auswerten. So können wir Schlüsse auf Eigenschaften, Präferenzen und
						voraussichtliches
						Verhalten ziehen.
					</p>
					<h2>3.7. Sonstige Daten </h2>
					<p>
						Wir können auch in anderen Situationen Daten von Ihnen erheben. Im Zusammenhang mit behördlichen
						oder
						gerichtlichen Verfahren etwa fallen Daten an (wie Akten, Beweismittel etc.), die sich auch auf
						Sie
						beziehen können.
					</p>
					<h1>4. Zu welchen Zwecken bearbeiten wir Ihre Personendaten? </h1>
					<p>
						Wir verwenden die von uns erhobenen Personendaten in erster Linie für den Abschluss und die
						Durchführung
						unserer Verträge. Darüber hinaus bearbeiten wir Personendaten von Ihnen, soweit erlaubt und es
						uns als
						angezeigt erscheint, auch für weitere Zwecke, an denen wir (und zuweilen auch Dritte) ein dem
						Zweck
						entsprechendes berechtigtes Interesse haben:
					</p>
					<p>
						<ul >
							<li> Für <strong> Kommunikationszwecke</strong>, das heisst, um mit Ihnen Kontakt
								aufzunehmen und den Kontakt
								mit Ihnen zu pflegen. Dies umfasst die Beantwortung von Anfragen und die Kontaktaufnahme
								mit Ihnen bei
								Rückfragen, bspw. per E-Mail. Hierzu bearbeiten wir insbesondere Ihre Kommunikations-
								und Stammdaten.
							</li>
							<li> Für die<strong> Kundenpflege und für Marketingzwecke</strong>, um Sie entsprechend
								Ihren persönlichen
								Interessen und Vorlieben gezielt über Angebote zu informieren, z.B. durch den Newsletter
								und personalisierte
								Werbung. Hierfür bearbeiten wir insbesondere technische Daten, Stamm- und
								Kommunikationsdaten sowie
								Verhaltensdaten. Für Informationen dazu, wie wir Ihre Lohncheck-Daten für Werbezweck
								bearbeiten, sehen Sie
								bitte ergänzend die separate Datenschutzerklärung unter folgendem{' '}
								<a href='https://lohncheck.ch/de/marketing_datenschutz' target='_blank'
								   rel='noopener noreferrer'>Link</a>. Wir können auch Ihre Verhaltensdaten und
								Präferenzdaten mit Ihren Lohncheck-Daten verknüpfen, um die Werbeanzeigen und
								Jobangebote auf unserer Webseite
								zu optimieren und noch besser an Ihre Bedürfnisse anzupassen.
							</li>
							<li><strong> Zur Optimierung unserer Leistungen</strong>: Wir verwenden Ihre Personendaten
								zu analytischen
								Zwecken, um die Qualität unserer Dienstleistungen (insbesondere unser statistisches
								Modell zur
								Lohnberechnung) sowie die Nutzerfreundlichkeit und Funktionalitäten unserer Webseite zu
								verbessern.
							</li>
							<li><strong> Zur Gewährleistung der IT-Sicherheit und zur Prävention</strong>: Wir
								bearbeiten Personendaten
								zur Überwachung der Leistungsfähigkeit unseres Betriebs, insbesondere der IT, unserer
								Webseite, Applikationen
								und weiteren Plattformen, zu Sicherheitszwecken, zur Gewährleistung der IT-Sicherheit,
								zur Diebstahls-,
								Betrugs- und Missbrauchsprävention und zu Beweiszwecken. Dies umfasst beispielsweise die
								Auswertung von
								systemseitigen Aufzeichnungen der Nutzung unserer Systeme (Log-Daten), das Vorbeugen,
								Abwehren und Aufklären
								von Cyberangriffen und Malware-Attacken, Analysen und Tests unserer Netzwerke und
								IT-Infrastrukturen,
								System- und Fehlerprüfungen.
							</li>
							<li><strong> Zur Wahrung des Hausrechts und sonstige Massnahmen zur IT-, Gebäude- und
								Anlagesicherheit</strong>
								: und Schutz unserer Mitarbeiter und weiteren Personen und uns gehörenden oder
								anvertrauten Werte (wie z.B.
								Zutrittskontrollen, Besucherlisten, Netzwerk- und Mailscanner, Telefonaufzeichnungen).
							</li>
							<li><strong> Zur Rechtswahrung</strong>: Wir bearbeiten Personendaten unter Umständen auch,
								um Ansprüche
								gerichtlich, vor- oder aussergerichtlich und vor Behörden im In- und Ausland
								durchzusetzen oder uns
								gegen Ansprüche zu verteidigen. Für diesen Zweck können Stamm- und Kommunikationsdaten
								bearbeitet werden.
							</li>
							<li><strong> Zur Einhaltung rechtlicher Anforderungen</strong>: Dazu gehören z.B. die
								Bearbeitung von
								Beschwerden und anderen Meldungen, die Einhaltung von Anordnungen eines Gerichts oder
								einer Behörde,
								Massnahmen zur Aufdeckung und Abklärung von Missbräuchen sowie generell Massnahmen, zu
								denen wir nach dem
								anwendbaren Recht, nach Selbstregulierungen oder Branchenstandards verpflichtet sind.
								Hierfür können wir
								insbesondere Ihre Stamm- und Kommunikationsdaten bearbeiten.
							</li>
							<li><strong> Zur Administration und Unterstützung</strong>: Um unsere internen Abläufe
								effizient zu gestalten,
								bearbeiten wir Daten soweit erforderlich zur Verwaltung der IT, für die Buchhaltung oder
								für die Archivierung
								von Daten. Hierfür können wir insbesondere Kommunikations- und Verhaltensdaten sowie
								technische Daten
								bearbeiten.
							</li>
							<li> Wir können Daten auch zu <strong> weiteren Zwecken </strong>bearbeiten. Dazu gehören
								die
								Unternehmensführung einschliesslich Betriebsorganisation und Unternehmensentwicklung,
								weitere interne Abläufe
								und administrative Zwecke (z.B. Verwaltung von Stammdaten, Buchhaltung und
								Archivierung), Schulungs- und
								Ausbildungszwecke und die Vorbereitung und Abwicklung von Kauf und Verkauf von
								Geschäftsbereichen,
								Gesellschaften oder Teilen von Gesellschaften und andere gesellschaftsrechtliche
								Transaktionen und damit
								verbunden die Übertragung von Personendaten, sowie Massnahmen zur Geschäftssteuerung und
								die Wahrung weiterer
								berechtigter Interessen. Soweit wir Sie für bestimmte Bearbeitungen um Ihre Einwilligung
								bitten, informieren
								wir Sie gesondert über die entsprechenden Zwecke der Bearbeitung. Einwilligungen können
								Sie jederzeit durch
								schriftliche Mitteilung an uns widerrufen.
							</li>
						</ul>
					</p>
					<h1>5. Welche Online-Tracking und Online-Werbetechniken verwenden wir? </h1>
					<p>
						Auf unserer Webseite setzen wir verschiedene Techniken ein, mit denen wir und von uns
						beigezogenen Dritte Sie
						bei Ihrer Nutzung wiedererkennen und zum Teil auch über mehrere Besuche hinweg verfolgen können.
						Der Einsatz solcher Techniken ist speziell reguliert. In diesem Abschnitt informieren wir Sie
						darüber.
					</p>
					<h2>5.1. Wie und wozu setzen wir Cookies und ähnliche Technologien ein? </h2>
					<p>
						Für unsere Webseite nehmen wir Dienstleistungen von Dritten in Anspruch, um
						die <strong> Nutzerfreundlichkeit
						der Webseite und Online-Werbekampagnen </strong> messen und verbessern zu können. Dazu können
						wir
						Drittkomponenten auf unserer Webseite einbinden und Cookies und andere Technologien einsetzen.
						Dabei geht es
						im Kern darum, dass wir die Zugriffe von Ihnen (über Ihr System) von Zugriffen anderer Benutzer
						unterscheiden
						können, damit wir die Funktionalität der Webseite sicherstellen und statistische Auswertungen
						vornehmen können.
						Wir wollen dabei nicht auf Ihre Identität schliessen. Die eingesetzten Techniken sind so
						ausgestaltet, dass
						Sie bei jedem Seitenaufruf als individueller Besucher erkannt werden, etwa indem unser Server
						(oder die Server
						der Dritten) Ihnen bzw. Ihrem Browser eine bestimmte Erkennungsnummer zuweist (sog. «Cookie»).
					</p>

					<p>
						Cookies sind Dateien, die Ihr Browser automatisch auf Ihrem Endgerät speichert, wenn Sie unsere
						Webseite
						besuchen. Cookies enthalten eine eindeutige Kennnummer (eine ID), durch die wir einzelne
						Besucher von anderen
						unterscheiden können, in der Regel aber ohne sie zu identifizieren. Je nach Verwendungszweck
						enthalten Cookies
						weitere Informationen, z.B. über aufgerufene Seiten und die Dauer des Besuchs einer Seite. Wir
						verwenden
						einerseits Session-Cookies, die beim Schliessen des Browsers wieder gelöscht werden, und
						andererseits
						dauerhafte Cookies, die nach dem Schliessen des Browsers für eine bestimmte Dauer gespeichert
						bleiben und dazu
						dienen, Besucher bei einem späteren Besuch wiederzuerkennen.
					</p>
					<p>
						Wir verwenden folgende Arten von Cookies und ähnlichen Technologien:
					</p>
					<ul >
						<li> Notwendige Cookies: Notwendige Cookies sind für die Funktionalität der Webseiten
							erforderlich, z.B.
							damit Sie zwischen Seiten wechseln können, ohne dass in einem Formular eingegebene Angaben
							verlorengehen.
						</li>
						<li> Leistungs-Cookies: Diese Cookies erheben Informationen über die Nutzung einer Webseite und
							ermöglichen
							Analysen, z.B. welche Seiten am beliebtesten sind. Sie können dadurch den Besuch einer
							Webseite vereinfachen
							und die Nutzerfreundlichkeit verbessern.
						</li>
						<li> Funktionale Cookies: Funktionale Cookies ermöglichen erweiterte Funktionen und
							können personalisierte Inhalte anzeigen.
						</li>
						<li> [Marketing-Technologien: Marketing-Technologien helfen uns und unseren Werbepartnern, Sie
							auf unseren
							Webseiten und auf Webseiten Dritter mit Werbung für Produkte oder Dienstleistungen
							anzusprechen, die für Sie
							interessant sein könnten, oder nach einem Besuch unserer Webseiten bei Ihrer weiteren
							Internetnutzung unsere
							Werbeanzeigen anzuzeigen.]
						</li>
					</ul>

					<p>
						Wir nutzen Cookies und andere Technologien insbesondere für die folgenden Zwecke
					</p>
					<ul >
						<li> Personalisierung von Inhalten</li>
						<li> Ausspielen von personalisierten Werbeanzeigen und Angeboten</li>
						<li> Ausspielen von Werbeanzeigen auf Webseiten Dritter und Messung des Erfolgs, d.h. ob Sie auf
							diese
							Anzeigen reagieren (Remarketing)
						</li>
						<li> Speichern von Einstellungen zwischen Ihren Besuchen</li>
						<li> Feststellung, ob und wie wir unsere Webseite verbessern können</li>
						<li> Erhebung statistischer Daten zur Anzahl der Benutzer und ihrer Nutzungsgewohnheiten sowie
							zur Verbesserung
							der Geschwindigkeit und Leistung der Webseite
						</li>
					</ul>
					<p>
						Wir setzen Cookies und ähnliche Technologien zu Analyse- und Marketingzwecken nur ein, wenn Sie
						entsprechende
						Einstellungen in unserem Cookie-Banner aktiv bestätigen.
					</p>
					<h2>5.2. Wie können Cookies und ähnliche Technologien deaktiviert werden?</h2>
					<p>
						Sie haben beim Aufruf unserer Webseite die Möglichkeit, bestimmte Kategorien von Cookies zu
						aktivieren oder zu
						deaktivieren. Sie können Ihren Browser in den Einstellungen so konfigurieren, dass er bestimmte
						Cookies oder
						ähnliche Technologien blockiert oder bestehende Cookies und andere im Browser gespeicherte Daten
						löscht. Sie
						können Ihren Browser auch mit Software (sog. «Plug-Ins») erweitern, die ein Tracking durch
						bestimmte Dritte
						sperrt. Mehr dazu finden Sie in den Hilfeseiten Ihres Browsers (meist unter dem Stichwort
						«Datenschutz»). Bitte
						beachten Sie, dass unsere Webseite allenfalls nicht mehr in vollem Umfang funktioniert, wenn Sie
						Cookies und
						ähnliche Technologien blockieren.
					</p>
					<h2>5.3. Cookies und Technologien von Partnern und Dritten auf unserer Webseite</h2>
					<p>
						Wir nehmen Dienstleistungen von Dritten in Anspruch, um die Nutzerfreundlichkeit der Webseite
						messen und
						verbessern zu können und um Online-Werbekampagnen auszuspielen. Drittanbieter können sich auch
						ausserhalb der
						Schweiz und der EU/dem EWR befinden, sofern der Schutz Ihrer Personendaten in angemessener Weise
						sichergestellt
						ist. Zum Beispiel setzen wir Analysedienste ein, damit wir unsere Webseite optimieren und
						personalisieren
						können. Die entsprechenden Drittanbieter können dafür die Nutzung der Webseite und das Verhalten
						der Nutzer
						aufzeichnen und uns auf dieser Basis statistische Auswertungen zur Verfügung stellen. Diese
						Angaben können die
						Anbieter auch für eigene Zwecke verwenden, z.B. für personalisierte Werbung auf der eigenen
						Webseite oder
						anderen Webseiten oder zur Verbesserung ihrer eigenen Dienstleistungen. Falls ein Nutzer beim
						Anbieter
						registriert ist, kann dieser die Nutzungsdaten der betreffenden Person zuordnen.
					</p>
					<p>
						Unser wichtigster Drittanbieter ist Google. Zu diesem finden Sie nachstehend weitere Angaben.
						Andere Drittanbieter
						bearbeiten Personen- und andere Daten in der Regel in ähnlicher Weise.
					</p>

					<ul >
						<li><strong>Google Analytics</strong>, ein Analyse-Dienst von Google LLC (1600 Amphitheatre
							Parkway, Mountain
							View, CA, USA) und Google Ireland Ltd. (Google Building Gordon House, Barrow St, Dublin 4,
							Irland; beide
							zusammen «Google», wobei Google Ireland Ltd. für die Bearbeitung von Personendaten
							verantwortlich ist). Google
							verwendet Cookies und ähnliche Technologien, um bestimmte Informationen über das Verhalten
							einzelner Nutzer auf
							bzw. in der betreffenden Webseite und das dazu verwendete Endgerät (Tablet, PC, Smartphone
							etc.) zu erfassen.
							Google erfasst Informationen über das Verhalten der Nutzer auf der Webseite und das
							verwendete Endgerät und
							stellt uns auf dieser Basis Auswertungen zur Verfügung, bearbeitet bestimmte Daten aber auch
							für eigene Zwecke.
							Google Analytics anonymisiert die IP-Adressen der Besucher vor der Weiterleitung in die USA.
							Angaben zum
							Datenschutz bei Google Analytics finden Sie {' '}
							<a href='https://policies.google.com/privacy?hl=de' target='_blank'
							   rel='noopener noreferrer'>hier</a>. Sie können Google Analytics
							deaktivieren, indem Sie ein entsprechendes{' '}
							<a href='https://tools.google.com/dlpage/gaoptout?hl=de' target='_blank'
							   rel='noopener noreferrer'>Browser-Add-On installieren</a>.
						</li>
						<li><strong> Google Protected Audience</strong> ist ein Dienst von Google im Bereich der Werbe-
							und
							Anzeigentechnologie. Mit Hilfe dieses Dienstes werden die Nutzer einer Webseite anhand ihres
							Nutzerverhaltens
							bestimmten Interessengruppen zugeordnet. Die Information über die Zugehörigkeit zu
							Interessengruppen wird im
							verwendeten Browser gespeichert, und der Webseitenbetreiber kann sie mit weiteren ihm
							vorliegenden
							Informationen anreichern. Anhand dieser hinterlegten Informationen kann der Nutzer auf
							anderen Webseiten mit
							zielgerichteter Werbung angesprochen werden. Dabei werden Daten nicht webseitenübergreifend
							übermittelt.
							Die Werbung wird mittels einem sogenannten «Fenced Frame» ausgespielt. Das bedeutet, dass
							die Webseite, auf
							der Werbung angezeigt wird, keine Information erhält, welcher Interessengruppe der Nutzer
							zugeordnet ist und
							welche Anzeigen angezeigt werden. Die Webseitenbetreiber der beteiligten Webseiten können
							aber aggregierte
							Berichte über die ausgespielten Werbeanzeigen erhalten. Sie können die Verwendung von Google
							Protected Audience
							in Ihren Browser-Einstellungen deaktivieren. Informationen dazu finden Sie{' '}
							<a href='https://developers.google.com/privacy-sandbox/relevance/protected-audience-api/opt-out?hl=de'
							   target='_blank' rel='noopener noreferrer'>hier</a>.
						</li>

					</ul>
					<h1>6. Was gilt bei Profilierungen und automatisierten Entscheidungen? </h1>
					<p>
						Wir können Ihre Daten nach Ziffer 3 automatisiert bearbeiten und auswerten. Dazu gehören auch
						sog.
						Profilierungen, d.h. automatisierte Auswertungen von Daten zu Analyse- und Prognosezwecken, zur
						Ermittlung von
						Präferenzdaten (Ziffer 3.6), aber auch um Missbrauchs- und Sicherheitsrisiken festzustellen. Die
						wichtigsten
						Beispiele sind Profilierungen für Marketingzwecke, zur Kundenpflege, zur Betrugsprävention, zur
						Bonitätsprüfung
						und zum Risikomanagement.
					</p>
					<h1>7. Wie bearbeiten wir Daten im Zusammenhang mit Social Media? </h1>
					<p>
						Wenn Sie via Social Media und unsere dortigen Profile (z.B. auf Instagram, LinkedIn, Facebook)
						mit uns
						kommunizieren oder Inhalte kommentieren oder weiterverbreiten, erheben wir dazu Angaben, die wir
						vor allem zur
						Kommunikation mit Ihnen, für Marketingzwecke und für statistische Auswertungen verwenden.
						Beachten Sie bitte,
						dass der Anbieter der Plattform beim Besuch unserer Social-Media-Auftritte auch selbst Daten
						(z.B. zum
						Nutzerverhalten) erhebt und verwendet, ggf. zusammen mit anderen ihm bekannten Daten (z.B. für
						Marketingzwecke
						oder zur Personalisierung der Plattforminhalte). Weitere Informationen zur Datenbearbeitung
						durch die Anbieter
						von sozialen Netzwerken finden Sie in den Datenschutzerklärungen der entsprechenden sozialen
						Netzwerke.
					</p>
					<h1>8. Wem geben wir Ihre Personendaten bekannt? </h1>
					<p>
						Im Zusammenhang mit unseren Bearbeitungen geben wir Ihre Personendaten auch an andere Empfänger,
						neben den unter Ziffer <strong> 5.3. </strong> benannten Empfängern bekannt.
					</p>
					<p>
						Wir geben <strong> Dienstleistern </strong> die für ihre Leistungen erforderlichen Personendaten
						bekannt.
						Das betrifft insbesondere IT-Dienstleister, aber auch Beratungsunternehmen,
						Analysedienstleister,
						Inkassodienstleister, Wirtschaftsauskunfteien, Marketingdienstleister usw. Soweit Dienstleister
						Personendaten
						als Auftragsbearbeiter bearbeiten, sind sie verpflichtet, Personendaten ausschliesslich nach
						unseren
						Instruktionen zu bearbeiten und Massnahmen zur Datensicherheit zu treffen.
					</p>
					<p>
						Daten können auch an <strong>andere Empfänger</strong> bekanntgegeben werden, z.B. an Gerichte
						und Behörden
						im Rahmen von Verfahren und gesetzlichen Informations- und Mitwirkungspflichten, an Käufer von
						Unternehmen
						und Vermögenswerten, an Finanzierungsgesellschaften bei Verbriefungen und an Inkassounternehmen.
					</p>
					<p>
						In Einzelfällen ist es möglich, dass wir Personendaten an andere Dritte auch zu deren eigenen
						Zwecken
						weitergeben, z.B. wenn Sie uns dazu Ihre Einwilligung gegeben haben oder wenn wir zu einer
						Weitergabe
						gesetzlich verpflichtet oder berechtigt sind. Bekanntgaben im Zusammenhang mit den
						Lohncheck-Daten entnehmen
						Sie bitte zusätzlich der entsprechenden Datenschutzerklärung,{' '}
						<a href='https://lohncheck.ch/de/marketing_datenschutz' target='_blank'
						   rel='noopener noreferrer'>hier</a>.
					</p>
					<h1>9. Geben wir Personendaten ins Ausland bekannt? </h1>
					<p>
						Empfänger von Daten befinden sich nicht nur in der Schweiz. Das betrifft besonders bestimmte
						Dienstleister.
						Diese können sich auch innerhalb des Europäischen Wirtschaftsraumes (EWR) befinden (zum Beispiel
						in Irland)
						aber auch in anderen Ländern weltweit (insbesondere in den USA). Wir können z.B. Behörden und
						anderen Personen
						im Ausland Daten übermitteln, wenn wir rechtlich dazu verpflichtet sind oder z.B. im Rahmen
						eines
						Unternehmensverkaufs oder eines Gerichtsverfahrens. Nicht alle dieser Staaten gewährleisten
						derzeit ein dem
						schweizerischen Recht entsprechendes Datenschutzniveau. Wir gleichen den niedrigeren Schutz
						durch entsprechende
						Verträge aus, besonders die von der Europäischen Kommission ausgestellten und dem
						schweizerischen Datenschutz-
						und Öffentlichkeitsbeauftragten (EDÖB) anerkannten Standardvertragsklauseln. Weitere Angaben
						dazu und eine
						Kopie dieser Klauseln finden Sie{' '}
						<a href='https://www.edoeb.admin.ch/edoeb/de/home/datenschutz/arbeit_wirtschaft/datenuebermittlung_ausland.html'
						   target='_blank' rel='noopener noreferrer'>hier</a>.
					</p>
					<p>
						In bestimmten Fällen können wir Daten im Einklang mit datenschutzrechtlichen Vorgaben auch ohne
						solche Verträge
						übermitteln, z.B. wenn Sie in die entsprechende Bekanntgabe eingewilligt haben oder wenn die
						Bekanntgabe für
						die Vertragsabwicklung, für die Feststellung, Ausübung oder Durchsetzung von Rechtsansprüchen
						oder für
						überwiegende öffentlichen Interessen erforderlich ist.
					</p>
					<p>
						Beachten Sie, dass der Datenaustausch über das Internet häufig über Drittstaaten geleitet wird.
						Ihre Daten
						können in diesem Fall deshalb auch dann ins Ausland gelangen, wenn sich Absender und Empfänger
						im gleichen
						Land befinden.
					</p>
					<h1>10. Wie lange bearbeiten wir Personendaten? </h1>
					<p>
						Wir speichern und bearbeiten Ihre Personendaten, solange es für den Zweck der Bearbeitung
						erforderlich ist
						(bei Verträgen in der Regel für die Dauer der Vertragsbeziehung), solange wir ein berechtigtes
						Interesse an der
						Speicherung haben (z.B. um rechtliche Ansprüche durchzusetzen, zur Archivierung und oder dazu,
						die
						IT-Sicherheit sicherzustellen) und solange Daten einer gesetzlichen Aufbewahrungspflicht
						unterliegen
						(für bestimmte Daten gilt z.B. eine zehnjährige Aufbewahrungsfrist). Stehen keine rechtlichen
						oder
						vertraglichen Pflichten entgegen, vernichten oder anonymisieren wir Ihre Daten nach Ablauf der
						Speicher- oder
						Bearbeitungsdauer im Rahmen unserer üblichen Abläufe.
					</p>
					<p>
						<strong>Stammdaten</strong> bewahren wir im Rahmen unserer gesetzlichen Aufbewahrungsfrist in
						der Regel während
						15 Jahren ab dem letzten Austausch mit Ihnen auf, mindestens aber ab Vertragsende. Diese Frist
						kann länger sein,
						soweit dies aus Beweisgründen oder zur Einhaltung gesetzlicher oder vertraglichen Vorgaben
						erforderlich oder
						technisch bedingt ist.
					</p>
					<p>
						<strong>Vertragsdaten </strong> bewahren wir in der Regel während 15 Jahren ab der letzten
						Vertragsaktivität
						auf, mindestens aber ab Vertragsende. Diese Frist kann länger sein, soweit dies aus
						Beweisgründen oder zur
						Einhaltung gesetzlicher oder vertraglicher Vorgaben erforderlich oder technisch bedingt ist.
					</p>
					<p>
						Wir anonymisieren oder löschen Ihre <strong> Verhaltens- und Präferenzdaten</strong>, wenn sie
						für die
						verfolgten Zwecke nicht mehr aussagekräftig sind, was je nach der Art der Daten nach ca. 26
						Monaten der Fall
						sein kann. Diese Frist kann länger sein, soweit dies aus Beweisgründen oder zur Einhaltung
						gesetzlicher oder
						vertraglichen Vorgaben erforderlich oder technisch bedingt ist.
					</p>
					<p>
						In Bezug auf Cookies finden Sie Informationen zur Dauer der Aufbewahrung unter dem Link
						«Cookie-Einstellungen».
					</p>
					<h1>11. Auf welchen Rechtsgrundlagen beruht die Datenbearbeitung? </h1>
					<p>
						Je nach anwendbarem Recht ist eine Datenbearbeitung nur erlaubt, wenn das anwendbare Recht es
						spezifisch
						erlaubt. Das gilt nicht nach dem schweizerischen Datenschutzgesetz, aber z.B. nach der
						Europäischen
						Datenschutzgrundverordnung (DSGVO), soweit diese zur Anwendung kommt. In diesem Fall stützen wir
						die
						Bearbeitung Ihrer Personendaten auf folgende Rechtsgrundlagen:
					</p>
					<ul >
						<li> Art. 6 Abs. 1 lit. b DSGVO für Bearbeitungen, die zur Erfüllung eines Vertrages mit der
							betroffenen
							Person sowie zur Durchführung vorvertraglicher Massnahmen erforderlich ist;
						</li>
						<li> Art. 6 Abs. 1 lit. f DSGVO für Bearbeitungen, die erforderlich sind, um berechtigte
							Interessen von uns
							oder von Dritten zu wahren, sofern nicht die Grundfreiheiten und Grundrechte sowie
							Interessen der
							betroffenen Person überwiegen. Das betrifft insbesondere die Einhaltung von schweizerischem
							Recht,
							unser Interesse, unsere Aktivitäten und Tätigkeiten dauerhaft, nutzerfreundlich, sicher und
							zuverlässig
							ausüben;
						</li>
						<li> Art. 6 Abs. 1 lit. c DSGVO für Bearbeitungen, die zur Erfüllung einer rechtlichen
							Verpflichtung nach
							dem Recht eines Mitgliedstaats des Europäischen Wirtschaftsraums (EWR) erforderlich sind.
							Der EWR umfasst
							die EU-Staaten und Island, Norwegen und Liechtenstein;
						</li>
						<li> Art. 6 Abs. 1 lit. a DSGVO für Bearbeitungen, die wir mit Ihrer separaten Einwilligung
							durchführen;
						</li>
					</ul>
					<h1>12. Wie schützen wir Ihre Daten? </h1>
					<p>
						Wir treffen angemessene Sicherheitsmassnahmen, um die Vertraulichkeit, Integrität und
						Verfügbarkeit Ihrer
						Personendaten zu wahren, um sie gegen unberechtigte oder unrechtmässige Bearbeitungen zu
						schützen und den
						Gefahren des Verlusts, einer unbeabsichtigten Veränderung, einer ungewollten Offenlegung oder
						eines
						unberechtigten Zugriffs entgegenzuwirken. Sicherheitsrisiken lassen sich allerdings generell
						nicht ganz
						ausschliessen; Restrisiken sind unvermeidbar.
					</p>
					<h1>13. Was sind Ihre Rechte? </h1>
					<p>
						Sie haben unter den Voraussetzungen und im Rahmen des anwendbaren Datenschutzrechts bestimmte
						Rechte, eine
						Kopie Ihrer Personendaten anzufragen bzw. auf unsere Bearbeitung dieser Daten Einfluss zu
						nehmen:
					</p>
					<ul >
						<li><strong>Auskunft</strong>: Sie können Auskunft verlangen, ob wir Personendaten über Sie
							bearbeiten,
							und falls ja welche, und weitere Angaben über unsere Datenbearbeitung.
						</li>
						<li><strong>Berichtigung und Einschränkung</strong>: Sie können unrichtige Personendaten
							berichtigen und
							unvollständige Daten vervollständigen und die Bearbeitung ihrer Daten einschränken lassen
						</li>
						<li><strong>Löschung und Widerspruch</strong>: Sie können Personendaten löschen lassen und der
							Bearbeitung
							ihrer Daten mit Wirkung für die Zukunft widersprechen. <strong>Sie können insbesondere aus
								Gründen, die sich
								aus ihrer besonderen Situation ergeben, jederzeit gegen die Bearbeitung von Daten für
								unser berechtigtes
								Interesse Widerspruch einzulegen, und gegen unsere Bearbeitung für Zwecke der
								Direktwerbung.{' '}
								<a href='https://lohncheck.ch/de/daten-loeschen' target='_blank'
								   rel='noopener noreferrer'> https://lohncheck.ch/de/daten-loeschen</a>
							</strong></li>
						<li><strong>Übertragung</strong>: Sie können die Personendaten, die Sie uns bereitgestellt
							haben, in einem
							strukturierten, gängigen und maschinenlesbaren Format zu erhalten, soweit die entsprechende
							Datenbearbeitung
							auf Ihrer Einwilligung beruht oder zur Vertragserfüllung erforderlich ist.
						</li>
						<li><strong>Widerruf</strong>: Soweit wir Daten auf Basis Ihrer Einwilligung bearbeiten, können
							Sie die
							Einwilligung jederzeit widerrufen. Der Widerruf gilt nur für die Zukunft, und wir behalten
							uns vor, Daten
							im Fall eines Widerrufs gestützt auf eine andere Grundlage weiterhin zu bearbeiten.
						</li>
					</ul>
					<p>
						Wenn Sie ein solches Recht in Anspruch nehmen wollen, wenden Sie sich gerne an uns (Ziffer 2).
						In der Regel
						müssen wir dabei Ihre Identität prüfen.
					</p>
					<p>
						Es steht Ihnen zudem auch frei, bei der zuständigen Aufsichtsbehörde eine Beschwerde gegen
						unsere Bearbeitung
						Ihrer Daten einzureichen, in der Schweiz beim Eidgenössischen Datenschutz- und
						Öffentlichkeitsbeauftragten
						(EDÖB). Sie haben – soweit die DSGVO anwendbar ist – auch das Recht, Beschwerde bei einer
						zuständigen
						europäischen Datenschutz-Aufsichtsbehörde zu erheben.
					</p>
					<h1>14. Änderungen </h1>
					<p>
						Wir können diese Datenschutzerklärung bei Bedarf jederzeit ohne Vorankündigung anpassen. Es gilt
						die jeweils
						aktuelle, auf unserer Webseite publizierte Fassung.
					</p>
					<p>
						Stand der Datenschutzerklärung: 22.5.2024
					</p>
				</>
			)
		}
	</Translate>
)

export default Data
