import React, { Component } from 'react'
import { connect } from 'react-redux'
import TagManager from 'react-gtm-module-custom-domain'

import { withLocalize } from 'react-localize-redux'

import { setCurrency, fetchCurrencies } from '@redux/currencies'
import history from '@browser-history'
import ApiService from '@api'
import { debug } from '@config'
import demoValues from '@demo-values'
import { handleFormError } from '@utils/form-error-handling'
import { parseQueryString } from '@utils/helper'

import Step1Form from './Form'

let debugLoc = false

const { search } = history.location

// Check debug param
if (search) {
	const params = parseQueryString(search)

	if (params['debug']) {
		debugLoc = true
	}
}

const handleSubmit = () => {
	history.push(`/arbeitgeber${history.location.search}`)
	window.scrollTo(0, 0)
}

class Step1 extends Component {
	state = {
		jobFunction: undefined,
	}

	componentDidMount() {
		const { fetchCurrencies } = this.props
		const { lc } = window
		const lang = lc && lc.lang ? lc.lang : 'de'

		fetchCurrencies()
		this.getJobFunction(lang)
	}

	// Retrieve job function suggestions
	getJobFunction = (lang) => {
		const { lc } = window

		if (lc && lc?.jobFunction) {
			ApiService.search(lang, {
				query: lc.jobFunction,
			}).then((response) => {
				if (response && response.data && response.data.length) {
					this.setState({
						jobFunction: {
							label: response.data[0].title[lang],
							value: response.data[0].id,
							parent: response.data[0].parent,
							translations: response.data[0].title,
						},
					})
				} else {
					lc.jobFunction = null

					this.setState({ jobFunction: false })
				}
			})
		}
	}

	render() {
		const { setCurrency } = this.props
		const { lc } = window

		TagManager.initialize({
			gtmId: 'GTM-NDG8H5M',
			customURL: 'https://cc.lohncheck.ch/gtm.js',
		})

		if (lc && lc?.jobFunction && this.state.jobFunction === undefined)
			return null

		const initialValues =
			debug || debugLoc
				? {
						...demoValues,
						country_id: '216',
						debug: true,

						job_function:
							this.state.jobFunction || demoValues.job_function,
						monthly_salary:
							lc && lc.yearlySalary
								? Math.ceil(+lc.yearlySalary / 12)
								: demoValues.monthly_salary,
						yearly_salary:
							lc && lc.yearlySalary
								? lc.yearlySalary
								: demoValues.yearly_salary,
				  }
				: {
						newsletter: true,
						country_id: '216',
						debug: false,

						job_function: this.state.jobFunction,
						monthly_salary:
							lc && lc.yearlySalary
								? Math.ceil(+lc.yearlySalary / 12)
								: null,
						yearly_salary:
							lc && lc.yearlySalary ? lc.yearlySalary : null,
						children: [{}],
				  }

		return (
			<Step1Form
				initialValues={initialValues}
				onSubmit={handleSubmit}
				onSubmitFail={handleFormError}
				setCurrency={setCurrency}
			/>
		)
	}
}

const mapDispatchToProps = {
	fetchCurrencies,
	setCurrency,
}

export default withLocalize(connect(null, mapDispatchToProps)(Step1))
