import ApiService from '@api'

// Types
const FETCH = 'FETCH'
const FETCH_SUCCESS = 'FETCH_SUCCESS'
const FETCH_FAILURE = 'FETCH_FAILURE'
const SET_CURRENCY = 'SET_CURRENCY'
const SET_ORIGIN_CURRENCY = 'SET_ORIGIN_CURRENCY'

// Actions
export const fetch = () => ({ type: FETCH })
export const fetchSuccess = (data) => ({ type: FETCH_SUCCESS, data })
export const fetchFailure = (error) => ({ type: FETCH_FAILURE, error })
export const setCurrency = (data) => ({ type: SET_CURRENCY, data })
export const setOriginCurrency = (data) => ({ type: SET_ORIGIN_CURRENCY, data })

export const fetchCurrencies = () => (dispatch) => {
	dispatch(fetch())

	return ApiService.fetchCurrencies()
		.then((data) => {
			dispatch(fetchSuccess(data.data))
		})
		.catch((err) => {
			dispatch(fetchFailure(err))
		})
}

// Initial state
const initialState = {
	data: null,
	isFetching: false,
	error: null,
	currency: 'chf',
	originCurrency: 'chf',
}

// Reducers
const reducers = (state = initialState, action) => {
	switch (action.type) {
		case FETCH:
			return {
				...state,
				isFetching: true,
			}
		case FETCH_SUCCESS: {
			return {
				...state,
				isFetching: false,
				error: null,
				data: {
					...action.data,
				},
			}
		}
		case FETCH_FAILURE:
			return {
				...state,
				error: action.error,
				isFetching: false,
			}
		case SET_CURRENCY: {
			let c

			switch (action.data.toLowerCase()) {
				case 'usd':
					c = 'usd'

					break
				case 'eur':
					c = 'eur'

					break
				default:
					c = 'chf'
			}

			return {
				...state,
				currency: c,
			}
		}
		case SET_ORIGIN_CURRENCY:
			return {
				...state,
				originCurrency: state.currency,
			}
		default:
			return state
	}
}

export default reducers
